import { insertIf } from '@fastre/core/src/helperFunctions/array'
import { ChatGroupSchema } from '@fastre/core/src/schemas/chat'
import {
    Add,
    AttachMoneyRounded,
    CalendarMonthRounded,
    ChatRounded,
    CloseRounded,
    LinkRounded,
    PlaylistAddCheckRounded,
} from '@mui/icons-material'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import { default as HomeRounded } from '@mui/icons-material/HomeRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { Badge, IconButton, Tooltip } from '@mui/joy'
import Box from '@mui/joy/Box'
import Divider from '@mui/joy/Divider'
import GlobalStyles from '@mui/joy/GlobalStyles'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Sheet from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'
import { useApi } from 'api'
import { useChatConfigApi, useChatGroupsApi, useFindUserFromId } from 'apiProviders'
import { useUserData } from 'auth'
import { useOrgId } from 'helpers'
import { sum } from 'ramda'
import { Dispatch, ReactNode, SetStateAction, useRef, useState } from 'react'
import { useMatch, useNavigate } from 'react-router'
import { closeSidebar } from '../utils'
import NewChatModal from './newChatModal'
import Profile from './profile'
import SidebarList from './sidebarList'

export function Toggler({
    defaultExpanded = false,
    renderToggle,
    children,
}: {
    defaultExpanded?: boolean
    children: ReactNode
    renderToggle: (params: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => ReactNode
}) {
    const [open, setOpen] = useState(defaultExpanded)
    return (
        <>
            {renderToggle({ open, setOpen })}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </>
    )
}

const ChatSideItem = ({ group }: { group: ChatGroupSchema }) => {
    const api = useApi()
    const { user } = useUserData()
    const chatConfig = useChatConfigApi()
    const findUserFromId = useFindUserFromId()
    const navigate = useNavigate()
    const orgId = useOrgId()
    const textRef = useRef<any>()

    const currentChatGroup = useMatch('/:orgId/chat/:chatGroupId')?.params?.chatGroupId
    const newMessages = chatConfig.data!.newMessages[group.id] ?? 0

    const hasOverflow = textRef.current && textRef.current.scrollWidth > textRef.current.clientWidth

    const chatName =
        group.groupType == 'dm' ? (
            group.groupMembers
                .filter(id => id != user.userId)
                .map(findUserFromId)
                .map(maybeUser =>
                    maybeUser.map(user => `${user.firstName} ${user.lastName}`).orSome('Loading...'),
                )
                .join(', ')
        ) : (
            <b># {group.groupName}</b>
        )

    return (
        <Tooltip
            title={hasOverflow ? chatName : undefined}
            placement="right"
        >
            <ListItem
                key={group.id}
                sx={{
                    mt: 0.5,
                    position: 'relative',
                }}
                onClick={() => {
                    navigate(`/${orgId}/chat/${group.id}`)
                    closeSidebar()
                }}
            >
                <ListItemButton selected={group.id == currentChatGroup}>
                    <Badge
                        badgeContent={newMessages}
                        color="danger"
                        size="sm"
                    >
                        <Typography
                            ref={textRef}
                            level="body-sm"
                            sx={{
                                pr: 1,
                                maxWidth: '135px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {chatName}
                        </Typography>
                    </Badge>
                    {group.groupType == 'dm' && (
                        <IconButton
                            size="sm"
                            sx={{ ml: 'auto' }}
                            onClick={e => {
                                e.stopPropagation()
                                api.post(`/chatgroups/${group.id}/hide`)
                            }}
                        >
                            <CloseRounded />
                        </IconButton>
                    )}
                </ListItemButton>
            </ListItem>
        </Tooltip>
    )
}

const ChatSection = ({}) => {
    const { user } = useUserData()
    const chatGroupsApi = useChatGroupsApi()
    const chatConfig = useChatConfigApi()

    const [showNewChatModal, setShowNewChatModal] = useState<'dm' | 'channel' | undefined>()

    if (chatConfig.data == undefined || chatGroupsApi.data == undefined) {
        return null
    }

    return (
        <>
            <ListItem nested>
                <Toggler
                    //defaultExpanded
                    renderToggle={({ open, setOpen }) => (
                        <ListItemButton onClick={() => setOpen(!open)}>
                            <ChatRounded />
                            <ListItemContent>
                                <Badge
                                    badgeContent={sum(Object.values(chatConfig.data!.newMessages))}
                                    color="danger"
                                    size="sm"
                                >
                                    <Typography
                                        level="title-sm"
                                        sx={{
                                            pr: 1,
                                        }}
                                    >
                                        Chats
                                    </Typography>
                                </Badge>
                            </ListItemContent>
                            <KeyboardArrowDownIcon
                                sx={{
                                    transform: open ? 'rotate(180deg)' : 'none',
                                }}
                            />
                        </ListItemButton>
                    )}
                >
                    <List sx={{ gap: 0.5 }}>
                        {chatGroupsApi.maybeData
                            .orSome([])
                            .filter(
                                group =>
                                    group.groupType == 'channel' ||
                                    chatConfig.data!.activeChatGroups.includes(group.id) ||
                                    chatConfig.data!.newMessages[group.id] > 0,
                            )
                            .sort((a, b) => {
                                if (a.groupType == 'dm' && b.groupType == 'channel') {
                                    return 1
                                }
                                if (a.groupType == 'channel' && b.groupType == 'dm') {
                                    return -1
                                }
                                return 0
                            })
                            .map((group, i) => (
                                <ChatSideItem
                                    key={group.id}
                                    group={group}
                                />
                            ))}
                        <ListItem sx={{ mt: 2 }}>
                            <ListItemButton onClick={() => setShowNewChatModal('dm')}>
                                <Add />
                                <Typography level="title-sm">New chat</Typography>
                            </ListItemButton>
                        </ListItem>
                        {user.permissions.includes('chat.manage') && (
                            <ListItem>
                                <ListItemButton onClick={() => setShowNewChatModal('channel')}>
                                    <Add />
                                    <Typography level="title-sm">Create new channel</Typography>
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Toggler>
            </ListItem>
            <NewChatModal
                groupType={showNewChatModal}
                close={() => setShowNewChatModal(undefined)}
            />
        </>
    )
}

export default function Sidebar() {
    const { user } = useUserData()
    const navigate = useNavigate()

    const { currentPage } = useMatch('/:orgId/:currentPage/*')?.params ?? {
        currentPage: '',
    }

    const navigateAndClose = (url: string) => () => {
        navigate(url)
        closeSidebar()
    }

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {
                    xs: 'fixed',
                    md: 'sticky',
                },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 1299,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={theme => ({
                    ':root': {
                        '--Sidebar-width': '260px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '290px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 1208,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                {user.orgLogo ? (
                    <img
                        src={user.orgLogo}
                        alt="logo"
                        style={{ width: '100%', height: 'auto' }}
                    />
                ) : (
                    <Typography level="title-lg">{user.orgName}</Typography>
                )}
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': theme => theme.vars.radius.sm,
                    }}
                >
                    {user.permissions.includes('listings.view') && (
                        <>
                            <ListItem>
                                <ListItemButton
                                    selected={currentPage == 'dashboard'}
                                    onClick={navigateAndClose(`/${user.orgId}/dashboard`)}
                                >
                                    <DashboardRoundedIcon />
                                    <ListItemContent>
                                        <Typography level="title-sm">Dashboard</Typography>
                                    </ListItemContent>
                                </ListItemButton>
                            </ListItem>

                            <ListItem>
                                <ListItemButton
                                    role="menuitem"
                                    component="a"
                                    onClick={navigateAndClose(`/${user.orgId}/listings/sale`)}
                                    selected={currentPage == 'listings'}
                                >
                                    <HomeRounded />
                                    <ListItemContent>
                                        <Typography level="title-sm">Listings</Typography>
                                    </ListItemContent>
                                </ListItemButton>
                            </ListItem>
                        </>
                    )}
                    {user.permissions.includes('salesBoard.view') && (
                        <ListItem>
                            <ListItemButton
                                role="menuitem"
                                component="a"
                                onClick={navigateAndClose(`/${user.orgId}/salesboard`)}
                                selected={currentPage == 'salesboard'}
                            >
                                <AttachMoneyRounded />
                                <ListItemContent>
                                    <Typography level="title-sm">Sales Board</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    )}

                    {import.meta.env.DEV && (
                        <ListItem>
                            <ListItemButton
                                role="menuitem"
                                component="a"
                                onClick={navigateAndClose(`/${user.orgId}/tschat`)}
                                selected={currentPage == 'tschat'}
                            >
                                <AttachMoneyRounded />
                                <ListItemContent>
                                    <Typography level="title-sm">TS CHAT</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    )}
                    {user.permissions.includes('calendar.view') && (
                        <ListItem>
                            <ListItemButton
                                role="menuitem"
                                component="a"
                                onClick={navigateAndClose(`/${user.orgId}/calendar`)}
                                selected={currentPage == 'calendar'}
                            >
                                <CalendarMonthRounded />
                                <ListItemContent>
                                    <Typography level="title-sm">Calendar</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    )}
                    {user.permissions.includes('links.view') && (
                        <ListItem>
                            <ListItemButton
                                selected={currentPage == 'links'}
                                onClick={navigateAndClose(`/${user.orgId}/links`)}
                            >
                                <LinkRounded />
                                <ListItemContent>
                                    <Typography level="title-sm">Links</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    )}
                    <ListItem>
                        <ListItemButton
                            role="menuitem"
                            component="a"
                            onClick={navigateAndClose(`/${user.orgId}/todo`)}
                            selected={currentPage == 'todo'}
                        >
                            <PlaylistAddCheckRounded />
                            <ListItemContent>
                                <Typography level="title-sm">To Do</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem nested>
                        <Toggler
                            //defaultExpanded
                            renderToggle={({ open, setOpen }) => (
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <GroupRoundedIcon />
                                    <ListItemContent>
                                        <Typography level="title-sm">Users</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon
                                        sx={{
                                            transform: open ? 'rotate(180deg)' : 'none',
                                        }}
                                    />
                                </ListItemButton>
                            )}
                        >
                            <SidebarList
                                baseUrl={`/${user.orgId}`}
                                items={[
                                    { label: 'All users', pageUrl: 'users' },
                                    ...insertIf(user.permissions.includes('users.edit'), {
                                        label: 'Create a new user',
                                        pageUrl: 'adduser',
                                    }),
                                    ...insertIf(user.permissions.includes('roles.edit'), {
                                        label: 'Roles',
                                        pageUrl: 'roles',
                                    }),
                                    {
                                        label: 'Conjunctional Agencies',
                                        pageUrl: 'conjunctionalagencies',
                                    },
                                    //{ label: 'Groups', pageUrl: 'groups' },
                                    //{ label: 'My profile', pageUrl: 'profile' },
                                ]}
                            />
                        </Toggler>
                    </ListItem>
                    <ChatSection />
                </List>
                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': theme => theme.vars.radius.sm,
                        '--List-gap': '8px',
                        mb: 2,
                    }}
                >
                    {/*<ListItem>
                        <ListItemButton>
                            <SupportRoundedIcon />
                            Support
                        </ListItemButton>
                    </ListItem>*/}
                    {user.permissions.includes('settings.view') && (
                        <ListItem>
                            <ListItemButton
                                selected={currentPage == 'settings'}
                                onClick={navigateAndClose(`/${user.orgId}/settings`)}
                            >
                                <SettingsRoundedIcon />
                                Settings
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </Box>
            <Divider />
            <Profile />
        </Sheet>
    )
}
