import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { Box, useTheme } from '@mui/joy'
import GlobalStyles from '@mui/joy/GlobalStyles'
import IconButton from '@mui/joy/IconButton'
import Sheet from '@mui/joy/Sheet'
import { useNotificationsApi } from 'apiProviders'
import { useUserData } from 'auth'
import NotificationsButton from 'notificationsButton'
import ColorSchemeToggle from './colorSchemeToggle'
import { toggleSidebar } from './utils'

export default function Header() {
    const theme = useTheme()
    const notificationsApi = useNotificationsApi()
    const { user } = useUserData()

    return (
        <>
            <Sheet
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'fixed',
                    top: 0,
                    width: '100vw',
                    height: 'var(--Header-height)',
                    zIndex: 1298,
                    p: 2,
                    gap: 1,
                    borderBottom: '1px solid',
                    borderColor: 'background.level1',
                    boxShadow: 'sm',
                }}
            >
                <GlobalStyles
                    styles={theme => ({
                        ':root': {
                            '--Header-height': '60px',
                            [theme.breakpoints.up('md')]: {
                                '--Header-height': '0px',
                            },
                        },
                    })}
                />
                <IconButton
                    onClick={() => toggleSidebar()}
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <MenuRoundedIcon />
                </IconButton>
                {user.orgLogo && (
                    <img
                        src={user.orgLogo}
                        alt="Organization Logo"
                        style={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                        }}
                    />
                )}
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                    }}
                >
                    <ColorSchemeToggle size="sm" />
                    <NotificationsButton
                        size="sm"
                        notificationsApi={notificationsApi}
                    />
                </Box>
            </Sheet>
            <Box
                sx={{
                    position: 'absolute',
                    top: theme.spacing(3),
                    right: theme.spacing(6),
                    zIndex: 9999,
                    [theme.breakpoints.down('md')]: {
                        display: 'none',
                    },
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2,
                }}
            >
                <ColorSchemeToggle />
                <NotificationsButton notificationsApi={notificationsApi} />
            </Box>
        </>
    )
}
