import { Active, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { ContentCopy, EmailRounded, InsertDriveFileRounded } from '@mui/icons-material'
import {
    Box,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    Modal,
    ModalClose,
    ModalDialog,
    Sheet,
    Typography,
    useTheme,
} from '@mui/joy'
import { useApi } from 'api'
import { useListingFilesApi, useListingType } from 'apiProviders'
import { useUserData } from 'auth'
import axios from 'axios'
import Loading from 'components/Loading'
import FileUploadButton from 'components/fileUploadButton'
import PdfModal from 'components/pdfModal'
import { useShowSnack } from 'components/snackbar'
import copy from 'copy-to-clipboard'
import { useMaybeState } from 'helperFunctions/react'
import { evolve, pick, prop, T } from 'ramda'
import { useMemo, useState } from 'react'
import { useListingContext } from '../listingProvider'
import { UploadFilesContext } from './context'
import EmailModal from './emailModal'
import FolderRender from './folderRender'

const { VITE_APP_STAGE } = import.meta.env

const FilesList = ({ setFile, children, formDisabled }) => (
    <List>
        <FolderRender
            file={{
                Key: '',
                name: '',
                Size: 0,
                loading: false,
                isFolder: true,
            }}
            setFile={setFile}
            index={0}
            parentDragging={false}
            formDisabled={formDisabled}
        />
        {children}
    </List>
)

const Files = () => {
    const api = useApi()
    const listingType = useListingType()
    const { listing } = useListingContext()
    const showSnack = useShowSnack()
    const theme = useTheme()
    const filesApi = useListingFilesApi()
    const { user } = useUserData()

    const [loading, setLoading] = useState(false)
    const [maybeFile, setFile] = useMaybeState<{ name: string; url: string }>()
    //const [showAddFolder, setShowAddFolder] = useState(false)

    const [active, setActive] = useState<Active | null>(null)
    const activeItem = useMemo(
        () => (active ? filesApi.maybeData.orSome([]).find(file => file.Key === active?.id) : undefined),
        [active],
    )

    const formDisabled =
        !user.permissions.includes('listings.fullControl') && listing.agentUserId != user.userId

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        /*useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),*/
    )

    const uploadFiles = async (files: File[], _folder?: string[]) => {
        const folder = _folder?.filter(x => x != '') ?? []

        console.log('uploading files', files, folder)

        setLoading(true)

        const backupFiles = filesApi.maybeData.orSome([])

        const fullFilenames = files.map(file => ({
            ...file,
            name: (folder.length > 0 ? folder.join('/') + '/' : '') + file.name,
        }))

        const newFiles = [
            ...filesApi.maybeData.orSome([]),
            ...fullFilenames.map(file => ({
                Key: file.name,
                Size: file.size ?? 0,
                loading: true,
            })),
        ]

        filesApi.setVals(newFiles)

        try {
            const { presignedUrls } = await api
                .post(`listing/${listingType}/${listing.listingId}/file/getuploadurls`, {
                    folder: folder ?? [],
                    files: files.map(pick(['name', 'type'])),
                })
                .then(prop('data'))
            await Promise.all(
                files.map(async file => {
                    console.log('FILE TYPE', file.type)

                    return axios.put(presignedUrls[file.name], file, {
                        headers: {
                            'Content-Type': file.type,
                        },
                    })
                }),
            )
            showSnack('File(s) uploaded', 'success')
            filesApi.setVals(
                newFiles.map(file => ({
                    ...file,
                    loading: fullFilenames.some(f => f.name === file.Key) ? false : file.loading,
                })),
            )
            //await filesApi.refresh()
        } catch (e) {
            showSnack('Error uploading file(s)', 'danger')
            filesApi.setVals(backupFiles)
        } finally {
            setLoading(false)
        }
    }

    const fileEmail = `${listing.storageName}@storage${VITE_APP_STAGE == 'prod' ? '' : '.' + VITE_APP_STAGE}.fastre.com.au`

    return (
        <UploadFilesContext.Provider value={uploadFiles}>
            <Box
                sx={{
                    height: '100%',
                    borderRadius: 'md',
                    m: -2,
                    p: 2,
                    position: 'relative',
                    ':hover': {
                        '> #deleteButton': {
                            opacity: 1,
                        },
                    },
                }}
            >
                <Sheet
                    variant="soft"
                    color="primary"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        display: 'none',
                        p: 2,
                        borderRadius: 'sm',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Typography>Upload Files</Typography>
                </Sheet>
                {filesApi.maybeData
                    .map(files => (
                        <Box>
                            {!formDisabled && (
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <Typography>Attach emails by forwarding to {fileEmail}</Typography>
                                    <IconButton
                                        sx={{ ml: 1 }}
                                        onClick={() => copy(fileEmail)}
                                        size="sm"
                                    >
                                        <ContentCopy fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                            <DndContext
                                sensors={sensors}
                                onDragStart={({ active }) => {
                                    setActive(active)
                                }}
                                onDragEnd={async ({ active, over }) => {
                                    if (!over || !active || !activeItem) {
                                        return setActive(null)
                                    }

                                    //console.log({ active, over })

                                    const destinationFolder = (over.id as string).replace(/\/$/, '') // remove trailing slash

                                    const sourceParentFolder = activeItem.name
                                        .split('/')
                                        .slice(0, -1)
                                        .join('/')

                                    /*console.log({
                                        source: active.id,
                                        sourceParentFolder,
                                        destinationFolder,
                                    })*/

                                    if (
                                        destinationFolder !== undefined &&
                                        sourceParentFolder !== destinationFolder &&
                                        active.id != over?.id &&
                                        !(
                                            activeItem!.isFolder &&
                                            destinationFolder.startsWith(activeItem!.name)
                                        )
                                    ) {
                                        const sources = activeItem!.isFolder
                                            ? files
                                                  .filter(file =>
                                                      file.name.startsWith(
                                                          (active.id as string).replace('//', ''),
                                                      ),
                                                  )
                                                  .map(prop('Key'))
                                            : [active.id]

                                        const backupFiles = filesApi.maybeData.orSome([])

                                        const moveRenamerFunction = key =>
                                            key.replace(
                                                sourceParentFolder
                                                    ? sourceParentFolder + '/'
                                                    : sourceParentFolder,
                                                destinationFolder == '' ? '' : destinationFolder + '/',
                                            )

                                        const newFiles = files.map(file => {
                                            return sources.includes(file.Key)
                                                ? evolve(
                                                      {
                                                          Key: moveRenamerFunction,
                                                          loading: T,
                                                      },
                                                      file,
                                                  )
                                                : file
                                        })

                                        filesApi.setVals(newFiles)

                                        try {
                                            await api.post(
                                                `/listing/${listingType}/${listing.listingId}/file/move`,
                                                {
                                                    sources,
                                                    destinationFolder,
                                                },
                                            )
                                            filesApi.setVals(
                                                newFiles.map(file => ({
                                                    ...file,
                                                    loading: sources
                                                        .map(moveRenamerFunction)
                                                        .includes(file.Key)
                                                        ? false
                                                        : file.loading,
                                                })),
                                            )
                                        } catch (e) {
                                            console.log('error moving files', e)
                                            showSnack('Error moving files', 'danger')
                                            filesApi.setVals(backupFiles)
                                        }
                                        //await filesApi.refresh()
                                    }
                                    setActive(null)
                                }}
                                onDragCancel={() => {
                                    setActive(null)
                                }}
                            >
                                <FilesList
                                    setFile={setFile}
                                    formDisabled={formDisabled}
                                >
                                    <DragOverlay>
                                        {activeItem && (
                                            <Box
                                                sx={{
                                                    height: '36px',
                                                    width: '100%',
                                                    backgroundColor: theme.palette.background.level2,
                                                    py: 1,
                                                    px: 2,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                }}
                                            >
                                                {activeItem.isFolder ? (
                                                    <InsertDriveFileRounded />
                                                ) : activeItem.isEmail ? (
                                                    <EmailRounded />
                                                ) : (
                                                    <InsertDriveFileRounded />
                                                )}
                                                <Typography level="title-sm">
                                                    {activeItem.Key.split('/').pop()}
                                                </Typography>
                                            </Box>
                                        )}
                                    </DragOverlay>
                                </FilesList>
                            </DndContext>
                            {!formDisabled && (
                                <FileUploadButton
                                    sx={{ mt: 4 }}
                                    loading={loading}
                                    onChange={uploadFiles}
                                >
                                    Upload File
                                </FileUploadButton>
                            )}
                        </Box>
                    ))
                    .orSome(<Loading />)}
                <Modal
                    open={maybeFile.filter(({ name }) => !name.endsWith('.pdf')).isSome()}
                    onClose={() => setFile(undefined)}
                >
                    <ModalDialog sx={{ width: '100%' }}>
                        <ModalClose />
                        <DialogTitle>{maybeFile.map(prop('name')).orUndefined()}</DialogTitle>
                        <DialogContent>
                            {maybeFile
                                .filter(({ name }) => !name.endsWith('.pdf'))
                                .map(({ name, url }) =>
                                    name.endsWith('.eml') || name.endsWith('.msg') ? (
                                        <EmailModal {...{ name, url }} />
                                    ) : (
                                        <img
                                            src={url}
                                            alt={name}
                                            style={{ maxWidth: '100%' }}
                                        />
                                    ),
                                )
                                .orSome(<></>)}
                        </DialogContent>
                    </ModalDialog>
                </Modal>
                {/*<AddFolderModal
                    open={showAddFolder}
                    onClose={() => setShowAddFolder(false)}
                />*/}
            </Box>
            <PdfModal
                title={maybeFile.map(({ name }) => name).orSome('')}
                pdf={maybeFile
                    .filter(({ name }) => name.endsWith('.pdf'))
                    .map(prop('url'))
                    .orUndefined()}
                onClose={() => setFile(undefined)}
                fileName={maybeFile.map(prop('name')).orSome('')}
            />
        </UploadFilesContext.Provider>
    )
}

export default Files
