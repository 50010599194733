import { Button, DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy'
import { useApi } from 'api'
import { useChatConfigApi, useChatGroupsApi, useFindUserFromId, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import { Autocomplete } from 'components/autocomplete'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useOrgId } from 'helpers'
import { prop } from 'ramda'
import { useState } from 'react'
import { useNavigate } from 'react-router'

export default ({ groupType, close }) => {
    const api = useApi()
    const usersApi = useUsersApi()
    const { user } = useUserData()
    const findUserFromId = useFindUserFromId()
    const navigate = useNavigate()
    const orgId = useOrgId()
    const chatGroupsApi = useChatGroupsApi()
    const chatConfigApi = useChatConfigApi()

    const [loading, setLoading] = useState(false)
    const [userIds, setUserIds] = useState<string[]>([])
    const [groupName, setGroupName] = useState('')

    return (
        <Modal
            open={groupType != undefined}
            onClose={dontCloseOnBackgroundClick(close)}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>New Chat</DialogTitle>
                <DialogContent>
                    {groupType == 'channel' && (
                        <Input
                            label="Channel Name"
                            value={groupName}
                            onChange={setGroupName}
                        />
                    )}

                    <Autocomplete
                        label="Users"
                        multiple
                        value={userIds}
                        onChange={(_, val) => setUserIds(val)}
                        options={usersApi.maybeData
                            .orSome([])
                            .map(prop('userId'))
                            .filter(userId => user.userId != userId)}
                        getOptionLabel={userId =>
                            findUserFromId(userId)
                                .map(user => `${user.firstName} ${user.lastName}`)
                                .orSome('loading...')
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            const chatGroupId = await api
                                .post('/chatgroups/create', {
                                    groupName,
                                    groupMembers: [...userIds, user.userId],
                                    groupType: groupType,
                                })
                                .then(prop('data'))
                            await Promise.all([chatGroupsApi.refresh(), chatConfigApi.refresh()])

                            setLoading(false)
                            navigate(`/${orgId}/chat/${chatGroupId}`)
                            close()
                        }}
                    >
                        Start Chat
                    </Button>
                    <Button onClick={close}>Cancel</Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
