import { Box, Button, Stack, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useOrgIntegrationsApi } from 'apiProviders'
import { useUserData } from 'auth'
import DropZoneInner from 'components/dropzone'
import Loading from 'components/Loading'
import IframeModal from 'listings/docusignFormStepper.tsx/iframeModal'
import { prop } from 'ramda'
import { useState } from 'react'

const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = error => reject(error)
    })
}

export default () => {
    const api = useApi()
    const orgIntegrationsApi = useOrgIntegrationsApi()
    const { user } = useUserData()

    const [file, setFile] = useState<File>()
    const [docusignUrl, setDocusignUrl] = useState<string>()
    const [loading, setLoading] = useState(false)

    if (!user.docusignUserInfo) {
        return <Typography>Please connect to Docusign first</Typography>
    }

    if (orgIntegrationsApi.loading) {
        return <Loading />
    }

    return (
        <>
            {orgIntegrationsApi.data?.fundDisbursementTemplateId ? (
                <Stack gap={2}>
                    <Box>
                        <Button
                            loading={loading}
                            onClick={async () => {
                                setLoading(true)
                                const { url } = await api
                                    .post('/funddisbursementtemplate/edit', {
                                        returnUrl: `${window.location.origin}/docusign/close`,
                                    })
                                    .then(prop('data'))
                                setDocusignUrl(url)
                                setLoading(false)
                            }}
                        >
                            Edit
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            loading={loading}
                            color="danger"
                            onClick={async () => {
                                setLoading(true)
                                await api.post('/funddisbursementtemplate/remove')
                                setLoading(false)
                            }}
                        >
                            Remove
                        </Button>
                    </Box>
                </Stack>
            ) : (
                <>
                    <DropZoneInner
                        options={{
                            accept: {
                                'application/pdf': ['.pdf'],
                            },
                            maxFiles: 1,
                        }}
                        onUpload={async files => {
                            //setFile(files[0])
                            const url = await api
                                .post('/funddisbursementtemplate/create', {
                                    file: (await convertFileToBase64(files[0])).replace(
                                        'data:application/pdf;base64,',
                                        '',
                                    ),
                                    returnUrl: `${window.location.origin}/docusign/close`,
                                })
                                .then(prop('data'))
                            setDocusignUrl(url)
                        }}
                    />
                </>
            )}
            <IframeModal
                title="Docusign Fund Disbursement Template"
                url={docusignUrl}
                onClose={() => {
                    setDocusignUrl(undefined)
                }}
            />
        </>
    )
}
