import { capitalize } from '@fastre/core/src/helperFunctions/string'
import { getContractCommission } from '@fastre/core/src/schemas/contract'
import { formatAddress } from '@fastre/core/src/schemas/generic'
import { TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { AttachMoney, ChecklistRtlRounded, FeedRounded, HouseRounded } from '@mui/icons-material'
import { Box, Card, CardContent, Grid, Link, Sheet, Stack, Typography } from '@mui/joy'
import { differenceInDays, format, isSameMonth } from 'date-fns'
import { useOrgId } from 'helpers'
import { Maybe } from 'monet'
import { always, propEq, sum } from 'ramda'

const red = '#8B0000'
const blue = '#002147'

const SmallCard = ({ title, value, Icon }) => (
    <Grid xs={1}>
        <Card
            sx={{
                position: 'relative',
                background: blue,
                height: {
                    xs: undefined,
                    sm: '152px',
                },
            }}
        >
            <Typography
                level="title-lg"
                sx={{ color: 'white' }}
            >
                {title}
            </Typography>
            <Icon
                sx={{
                    position: 'absolute',
                    bottom: '0.5rem',
                    right: '0.5rem',
                    fill: 'white',
                    height: '24px',
                    width: '24px',
                }}
            />
            <CardContent orientation="horizontal">
                <Typography
                    level="body-lg"
                    sx={{ color: 'white' }}
                >
                    {value}
                </Typography>
            </CardContent>
        </Card>
    </Grid>
)

const DataRow = ({ title, value, Icon }) => (
    <Sheet
        variant="soft"
        sx={{
            px: 2,
            py: 0.5,
            borderRadius: 'sm',
            flexGrow: 1,
            background: red,
        }}
    >
        <Stack
            direction="row"
            sx={{
                justifyContent: 'space-between',
                borderRadius: 'sm',
            }}
        >
            <Stack
                direction="row"
                gap={1}
            >
                <Icon
                    sx={{
                        fill: 'white',
                        height: '24px',
                        width: '24px',
                    }}
                />
                <Typography sx={{ color: 'white' }}>{title}</Typography>
            </Stack>
            <Typography sx={{ color: 'white' }}>{value}</Typography>
        </Stack>
    </Sheet>
)

const sameMonthFilter = (date?: string | number) => date && isSameMonth(date, new Date())

export default ({ allListings, userId }: { allListings: TypesenseListingSchema[]; userId: string }) => {
    const orgId = useOrgId()

    const listings = allListings.filter(propEq('agentUserId', userId))

    const contracts = listings.filter(x => sameMonthFilter(x.contract?.contractDate))

    return (
        <Box
            sx={{
                pt: 2,
            }}
        >
            <Grid
                container
                columns={{
                    xs: 2,
                    sm: 3,
                    lg: 6,
                }}
                spacing={2}
            >
                <SmallCard
                    title="New Listings"
                    value={listings.filter(x => sameMonthFilter(x.authorityStartDate)).length}
                    Icon={HouseRounded}
                />
                <SmallCard
                    title="Contracts"
                    value={
                        contracts.filter(
                            x => x.listingStatus == 'under contract' || x.listingStatus == 'unconditional',
                        ).length
                    }
                    Icon={FeedRounded}
                />
                <SmallCard
                    title="Settled"
                    value={contracts.filter(x => x.statusLog?.unconditional != undefined).length}
                    Icon={ChecklistRtlRounded}
                />

                <SmallCard
                    title="Commission"
                    value={
                        '$' +
                        sum(
                            contracts
                                .filter(x => x.statusLog?.unconditional != undefined)
                                .map(getContractCommission),
                        ).toLocaleString('en-US', {
                            maximumFractionDigits: 0,
                        })
                    }
                    Icon={AttachMoney}
                />

                <Grid xs={2}>
                    <Stack spacing={1}>
                        <DataRow
                            title="Listed (YTD)"
                            value={listings.length}
                            Icon={HouseRounded}
                        />
                        <DataRow
                            title="Contracts Processed (YTD)"
                            value={listings.filter(x => x.contract != undefined).length}
                            Icon={FeedRounded}
                        />
                        <DataRow
                            title="Settled Properties (YTD)"
                            value={listings.filter(x => x.statusLog?.unconditional != undefined).length}
                            Icon={ChecklistRtlRounded}
                        />
                        <DataRow
                            title="Total Commission (YTD)"
                            value={
                                '$' +
                                sum(
                                    listings
                                        .filter(x => x.statusLog?.unconditional != undefined)
                                        .map(getContractCommission),
                                ).toLocaleString('en-US', {
                                    maximumFractionDigits: 0,
                                })
                            }
                            Icon={AttachMoney}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <Grid
                container
                columns={{
                    xs: 1,
                    md: 3,
                }}
                spacing={2}
                mt={4}
            >
                {[
                    'appraisal',
                    'offline',
                    'available',
                    'under contract',
                    'unconditional',
                    'sold',
                    'withdrawn',
                    'not gained',
                ].map(status => (
                    <Grid
                        key={status}
                        xs={status == 'unconditional' ? 2 : 1}
                    >
                        <Card
                            sx={{
                                height: '100%',
                            }}
                        >
                            <Typography level="title-lg">
                                {status.split(' ').map(capitalize).join(' ')}
                            </Typography>
                            <CardContent sx={{ minHeight: '132px' }}>
                                {listings
                                    .filter(propEq('listingStatus', status))
                                    .filter(
                                        listing =>
                                            (status != 'sold' && status != 'withdrawn') ||
                                            (listing.statusLog &&
                                                isSameMonth(listing.statusLog[status], new Date())),
                                    )
                                    .map(listing => ({
                                        ...listing,
                                        settlementDate: listing.settlementDate
                                            ? new Date(listing.settlementDate)
                                            : undefined,
                                    }))
                                    .sort(
                                        status == 'unconditional'
                                            ? (a, b) =>
                                                  (a.settlementDate?.getTime() ?? 0) -
                                                  (b.settlementDate?.getTime() ?? 0)
                                            : always(0),
                                    )
                                    .map(listing => (
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Link
                                                key={listing.listingId}
                                                href={`/${orgId}/listings/sale/${listing.listingId}`}
                                            >
                                                {formatAddress(listing.listingAddress)}
                                            </Link>
                                            {status == 'unconditional' && (
                                                <Typography
                                                /*sx={{
                                                        display: {
                                                            sm: 'block',
                                                            md: 'none',
                                                            lg: 'block',
                                                        },
                                                    }}*/
                                                >
                                                    {Maybe.fromUndefined(listing.settlementDate)
                                                        .map(
                                                            x =>
                                                                `${format(x, 'dd MMM')} (${differenceInDays(x, new Date())} days)`,
                                                        )
                                                        .orUndefined()}
                                                </Typography>
                                            )}
                                        </Box>
                                    ))}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
