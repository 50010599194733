import { injectIf } from '@fastre/core/src/helperFunctions/object'
import { MarketingPackageSchema, UpdateMarketingPackageSchema } from '@fastre/core/src/schemas/marketingPackage'
import { zodResolver } from '@hookform/resolvers/zod'
import { Add, Delete } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Modal,
    ModalClose,
    ModalDialog,
    Sheet,
    Stack,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useMarketingItemsApi, useMarketingPackagesApi } from 'apiProviders'
import ButtonSheet from 'components/buttonSheet'
import { SlotInput } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { useMaybeState } from 'helperFunctions/react'
import { useOrgId } from 'helpers'
import { prop } from 'ramda'
import { useState } from 'react'
import { Controller, SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

const EditPackageModal = ({ mpackage, close }: { mpackage: MarketingPackageSchema; close: () => void }) => {
    const isNew = mpackage.packageId == undefined
    const api = useApi()
    const showSnack = useShowSnack()
    const packagesApi = useMarketingPackagesApi()
    const itemsApi = useMarketingItemsApi()
    const orgId = useOrgId()

    const { register, control, handleSubmit, formState, getValues, setValue } = useForm<MarketingPackageSchema>({
        defaultValues: {
            ...injectIf(isNew, {
                parentId: orgId!,
                packageId: uuid(),
            }),
            ...mpackage,
        },
        resolver: zodResolver(UpdateMarketingPackageSchema),
    })

    const {
        fields: items,
        append: addItem,
        prepend,
        remove: removeItem,
        swap,
        move,
        insert: insertItem,
    } = useFieldArray({
        control,
        name: 'packageItems',
    })

    const itemsWatch = useWatch({ control, name: 'packageItems' })

    const [loading, setLoading] = useState(false)

    const onSubmit: SubmitHandler<MarketingPackageSchema> = async data => {
        setLoading(true)
        try {
            await api.post(`/marketingpackage/addupdate`, data).then(prop('data'))
            packagesApi.refresh()
            close()
            showSnack('Marketing package saved', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error saving marketing package', 'danger')
        } finally {
            setLoading(false)
        }
    }

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    return (
        <ModalDialog minWidth={800}>
            <DialogTitle>{isNew ? 'Create' : 'Edit'} Marketing Package</DialogTitle>
            <ModalClose />
            <DialogContent sx={{ overflow: 'auto' }}>
                <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack
                        direction="row"
                        gap={4}
                    >
                        <Stack sx={{ flex: '1' }}>
                            {itemsApi.maybeData
                                .map(templateItems =>
                                    templateItems
                                        .filter(item => !items.some(i => i.itemId === item.itemId))
                                        .map(item => (
                                            <Sheet
                                                key={item.itemId}
                                                sx={{
                                                    display: 'flex',
                                                    gap: 2,
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <IconButton onClick={() => addItem(item)}>
                                                    <Add />
                                                </IconButton>
                                                <Typography>
                                                    {item.itemName} - {item.itemPrice}
                                                </Typography>
                                            </Sheet>
                                        )),
                                )
                                .orSome([])}
                        </Stack>
                        <Stack
                            sx={{ flex: '1' }}
                            gap={2}
                        >
                            <Controller
                                name="packageName"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Package Name"
                                        {...field}
                                    />
                                )}
                            />
                            <Stack sx={{ flexShrink: 1, gap: 2 }}>
                                {items.map((item, itemIndex) => (
                                    <Box
                                        key={item.id}
                                        sx={{
                                            display: 'flex',
                                            gap: 2,
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Controller
                                            name={`packageItems.${itemIndex}.itemName`}
                                            control={control}
                                            render={field => (
                                                <SlotInput
                                                    label="Item Name"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name={`packageItems.${itemIndex}.itemPrice`}
                                            control={control}
                                            render={field => (
                                                <SlotInput
                                                    label="Item Price"
                                                    type="number"
                                                    startDecorator="$"
                                                    {...field}
                                                    onChange={e => {
                                                        console.log('new val', e)
                                                        field.field.onChange(e)
                                                    }}
                                                />
                                            )}
                                        />
                                        <Box>
                                            <IconButton onClick={() => removeItem(itemIndex)}>
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}
                                <Button
                                    variant="outlined"
                                    onClick={() => addItem({ itemCategory: 'Advertising', itemName: '', itemPrice: 0 })}
                                >
                                    Add Item
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: 18, my: 2 }}>
                            Total Price: ${itemsWatch.reduce((acc, item) => acc + item.itemPrice, 0)}
                        </Typography>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    //type="submit"
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </Button>
                <Button
                    variant="outlined"
                    onClick={close}
                >
                    Cancel
                </Button>
                {!isNew && (
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <Button
                            color="danger"
                            variant="outlined"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true)
                                await api.post('/marketingpackage/delete', {
                                    parentId: orgId,
                                    packageId: mpackage.packageId,
                                })
                                await packagesApi.refresh()
                                close()
                            }}
                        >
                            Delete
                        </Button>
                    </Box>
                )}
            </DialogActions>
        </ModalDialog>
    )
}

const MarketingSettings = () => {
    const packagesApi = useMarketingPackagesApi()
    const [maybeEditPackage, setEditPackage] = useMaybeState<MarketingPackageSchema>()

    return (
        <>
            <Box>
                <Stack
                    spacing={2}
                    sx={{ mt: 2 }}
                >
                    {packagesApi.maybeData
                        .map(packages =>
                            packages.map(p => (
                                <ButtonSheet
                                    key={p.packageId}
                                    onClick={() => setEditPackage(p)}
                                >
                                    {p.packageName}
                                </ButtonSheet>
                            )),
                        )
                        .orSome([])}

                    <Box>
                        <Button
                            onClick={() =>
                                setEditPackage({
                                    packageName: '',
                                    packageItems: [],
                                })
                            }
                            sx={{ mt: 2 }}
                            variant="outlined"
                        >
                            New Package
                        </Button>
                    </Box>
                </Stack>
                <Modal
                    open={maybeEditPackage.isSome()}
                    onClose={dontCloseOnBackgroundClick(() => setEditPackage(undefined))}
                >
                    {maybeEditPackage
                        .map(p => (
                            <EditPackageModal
                                mpackage={p}
                                close={() => setEditPackage(undefined)}
                            />
                        ))
                        .orSome(<></>)}
                </Modal>
            </Box>
        </>
    )
}

export default MarketingSettings
