import { reduceToObject } from '@fastre/core/src/helperFunctions/array'
import { OrgSettingsSchema } from '@fastre/core/src/schemas/org'
import { addDays, format } from 'date-fns'
import { toLower } from 'ramda'
import { InternalConjunctionalAgencySchema } from './conjunionalAgency'
import { formatAddress } from './generic'
import { FormType } from './rei'
import { InternalSaleListingSchema } from './saleListing'
import { InternalUserSchema } from './user'

const transformStreetNames = (address: string) =>
    address
        .replaceAll('street', 'st')
        .replaceAll('road', 'rd')
        .replaceAll('avenue', 'ave')
        .replaceAll('drive', 'dr')
        .replaceAll('terrace', 'tce')
        .replaceAll('place', 'pl')
        .replaceAll('court', 'ct')
        .replaceAll('close', 'cl')
        .replaceAll('crescent', 'cres')
        .replaceAll('highway', 'hwy')
        .replaceAll('boulevard', 'blvd')
        .replaceAll('square', 'sq')
        .replaceAll('lane', 'ln')
        .replaceAll('parade', 'pde')
        .replaceAll('circuit', 'cct')
        .replaceAll('way', 'wy')
        .replaceAll('quay', 'qy')
        .replaceAll('esplanade', 'esp')
        .replaceAll('promenade', 'prom')

interface Polygon {
    x: number
    y: number
}

interface BoundingRegion {
    pageNumber: number
    polygon: Polygon[]
}

interface Span {
    offset: number
    length: number
}

export interface BaseField {
    kind: string
    value?: string
    boundingRegions?: BoundingRegion[]
    content?: string
    spans?: Span[]
    confidence?: number
}

export interface SelectionMarkField extends BaseField {
    value: 'selected' | 'unselected'
}

interface OtherFees {
    description: Record<string, unknown>
    amount: Record<string, unknown>
    whenPayable: Record<string, unknown>
}

export interface AzureOcrData_Form6 {
    vendor1Name: BaseField
    vendor1Email: BaseField
    propertyAddress: BaseField
    propertySuburb: BaseField
    propertyState: BaseField
    propertyPostCode: BaseField
    planNumber: BaseField
    lotNumber: BaseField
    titleReference: BaseField
    vendor1Address: BaseField
    vendor1State: BaseField
    vendor1Postcode: BaseField
    salePrice: BaseField
    registeredForGst1: SelectionMarkField
    registeredForGst2: SelectionMarkField
    sale: SelectionMarkField
    auction: SelectionMarkField
    tradingName: BaseField
    licenseeName: BaseField
    orgLicenceNumber: BaseField
    orgAbn: BaseField
    orgAcn: BaseField
    orgLicenceExpiry: BaseField
    otherFees: OtherFees
    vendor2Postcode: BaseField
    vendor1Suburb: BaseField
    vendor2Suburb: BaseField
    vendor2State: BaseField
    vendor2Acn: BaseField
    vendor2Abn: BaseField
    vendor2Email: BaseField
    vendor1Mobile: BaseField
    vendor1Acn: BaseField
    vendor2Name: BaseField
    authorityEndDate: BaseField
    propertyDescription: BaseField
    hiddenPrice: BaseField
    vendor1Abn: BaseField
    vendor2Address: BaseField
    marketingAmount: BaseField
    authorityStartDate: BaseField
    vendor2Mobile: BaseField
    depositHolder: BaseField
    trustAccount: BaseField
    bank: BaseField
    bsb: BaseField
    accountNumber: BaseField
}

export const azureOcrDataMap_form6 = (
    listing: InternalSaleListingSchema,
    orgDetails: OrgSettingsSchema,
    agent: InternalUserSchema,
    conjunctionalAgency?: InternalConjunctionalAgencySchema,
): {
    results: Partial<Record<keyof AzureOcrData_Form6, string | undefined>>
    transformations: Record<keyof AzureOcrData_Form6, (val: string | undefined) => string | undefined>
} => {
    const vendorAddresses = listing.vendors?.map(vendor =>
        listing.occupantStatus === 'investment' ? vendor.address! : listing.listingAddress,
    )

    return {
        transformations: {
            ...[0, 1]
                .map(i => ({
                    [`vendor${i + 1}Email`]: val => {
                        const t1 = val?.startsWith('address.') ? val.replace('address.', '') : val
                        return t1?.toLocaleLowerCase()
                    },
                    [`vendor${i + 1}Postcode`]: val => val.replaceAll('.', ''),
                    [`vendor${i + 1}State`]: val => val.replaceAll('.', ''),
                    [`vendor${i + 1}Suburb`]: val => val.replaceAll('.', ''),
                }))
                .reduce(reduceToObject, {}),
            salePrice: val =>
                val?.replace('$', '').replaceAll(',', '').replaceAll(' ', '').replaceAll('.', ''),
            hiddenPrice: val => val.replace('$.', '').replace('$', ''),
            propertyDescription: toLower,
            propertyAddress: val => transformStreetNames(val),
            orgLicenceExpiry: val =>
                val
                    .replaceAll(' ', '')
                    .split('/')
                    .map((x, i) => (i < 2 ? x.padStart(2, '0') : x.padStart(4, '20')))
                    .join('/'),
        } as any,
        results: {
            ...[0, 1]
                .map(i => ({
                    [`vendor${i + 1}Name`]: `${listing.vendors[i]?.vendorType == 'company' ? (listing.vendors[i] as any)?.companyName + ' - ' : ''}${listing.vendors[i]?.firstName.trim()} ${listing.vendors[i]?.lastName.trim()}`,
                    [`vendor${i + 1}Email`]: listing.vendors[i]?.email,
                    [`vendor${i + 1}Address`]: vendorAddresses[i] ? formatAddress(vendorAddresses[i]) : '',
                    [`vendor${i + 1}State`]: vendorAddresses[i]?.state as string,
                    [`vendor${i + 1}Postcode`]: vendorAddresses[i]?.postcode,
                    [`vendor${i + 1}Suburb`]: vendorAddresses[i]?.suburb,
                    [`vendor${i + 1}Mobile`]: listing.vendors[i]?.mobileNumber,
                    [`vendor${i + 1}Abn`]:
                        listing.vendors[i]?.vendorType == 'company'
                            ? (listing.vendors[i] as any)?.abn
                            : undefined,
                    [`vendor${i + 1}Acn`]:
                        listing.vendors[i]?.vendorType == 'company'
                            ? (listing.vendors[i] as any)?.acn
                            : undefined,
                    [`registeredForGst${i + 1}`]:
                        listing.vendors[i]?.vendorType == 'company' ? 'selected' : 'unselected',
                }))
                .reduce(reduceToObject, {}),

            propertyAddress: transformStreetNames(formatAddress(listing.listingAddress)),
            propertySuburb: listing.listingAddress.suburb,
            propertyState: listing.listingAddress.state,
            propertyPostCode: listing.listingAddress.postcode,
            planNumber: listing.planNumber,
            lotNumber: listing.lotNumber,
            titleReference: listing.titleReference,
            salePrice: listing.advertisedPrice
                ?.replace('$', '')
                .replaceAll(',', '')
                .replaceAll(' ', '')
                .replaceAll('.', ''),
            sale: listing.listingType === 'sale' ? 'selected' : 'unselected',
            auction: listing.listingType === 'auction' ? 'selected' : 'unselected',
            authorityStartDate: listing.authorityStartDate
                ? format(new Date(listing.authorityStartDate), 'dd/MM/yyyy')
                : undefined,
            authorityEndDate: listing.authorityStartDate
                ? format(
                      addDays(new Date(listing.authorityStartDate), listing.authorityPeriod!),
                      'dd/MM/yyyy',
                  )
                : undefined,
            propertyDescription: 'residential',
            hiddenPrice:
                listing.hiddenPrice?.type == 'range'
                    ? `${listing.hiddenPrice.min} - ${listing.hiddenPrice.max}`
                    : '' + listing.hiddenPrice?.amount,
            marketingAmount: '',
            reserve: listing.listingType === 'auction' ? 'selected' : 'unselected',
            list: listing.listingType === 'sale' ? 'selected' : 'unselected',

            tradingName:
                `${orgDetails.licenseeName} t/as ${orgDetails.tradingName}` +
                (conjunctionalAgency ? ` in conjunction with ${conjunctionalAgency?.licenseeName}` : ''),
            licenseeName:
                orgDetails.licenseeName +
                (conjunctionalAgency ? ` in conjunction with ${conjunctionalAgency?.licenseeName}` : ''),
            orgLicenceNumber:
                orgDetails.licenceNumber +
                (conjunctionalAgency ? ` / ${conjunctionalAgency?.licenseNumber}` : ''),

            orgAbn: orgDetails.abn + (conjunctionalAgency ? ` / ${conjunctionalAgency?.abn}` : ''),
            orgAcn: orgDetails.acn + (conjunctionalAgency ? ` / ${conjunctionalAgency?.acn}` : ''),
            orgLicenceExpiry: format(orgDetails.licenceExpiry, 'dd/MM/yyyy'),
        } as any,
    }
}

export interface AzureOcrData_Contract {
    orgAbn: BaseField
    conjunctionalAbn: BaseField
    orgLicenceNumber: BaseField
    conjunctionalLicenceNumber: BaseField
    orgAddress: BaseField
    orgSuburb: BaseField
    orgState: BaseField
    orgPostcode: BaseField
    orgPhone: BaseField
    orgMobile: BaseField
    orgFax: BaseField
    orgEmail: BaseField
    seller1Name: BaseField
    seller1Abn: BaseField
    seller1Address: BaseField
    serller1Suburb: BaseField
    seller1State: BaseField
    seller1Postcode: BaseField
    seller1Mobile: BaseField
    seller1Email: BaseField
    seller2Name: BaseField
    seller2Abn: BaseField
    seller2Address: BaseField
    seller2Suburb: BaseField
    seller2State: BaseField
    seller2Postcode: BaseField
    seller2Mobile: BaseField
    seller2Email: BaseField
    sellerSolicitorName: BaseField
    sellerSolicitorContact: BaseField
    sellerSolicitorEmail: BaseField
    sellerSolicitorMobile: BaseField
    buyer1Name: BaseField
    buyer1Abn: BaseField
    buyer1Mobile: BaseField
    buyer1Email: BaseField
    buyer2Name: BaseField
    buyer2Abn: BaseField
    buyer2Mobile: BaseField
    buyer2Email: BaseField
    buyersAgentName: BaseField
    buyersAgentAbn: BaseField
    buyersAgentLicenceNumber: BaseField
    buyersAgentMobile: BaseField
    buyersAgentEmail: BaseField
    buyerSolicitorName: BaseField
    buyerSolicitorContact: BaseField
    buyerSolicitorEmail: BaseField
    buyerSolicitorMobile: BaseField
    propertyAddress: BaseField
    propertySuburb: BaseField
    propertyState: BaseField
    propertyPostCode: BaseField
    planNumber: BaseField
    lotNumber: BaseField
    titleReference: BaseField
}

export const azureOcrDataMap_contract = (
    listing: InternalSaleListingSchema,
    orgDetails: OrgSettingsSchema,
    agent: InternalUserSchema,
    conjunctionalAgency?: InternalConjunctionalAgencySchema,
): {
    results: Partial<Record<keyof AzureOcrData_Contract, string | undefined>>
    transformations: Record<keyof AzureOcrData_Contract, (val: string | undefined) => string | undefined>
} => {
    const vendorAddresses = listing.vendors?.map(vendor =>
        listing.occupantStatus === 'investment' ? vendor.address! : listing.listingAddress,
    )

    const parseNum = val => Number.parseFloat(val?.replaceAll(',', '') ?? '0').toFixed(2)

    return {
        transformations: {
            ...[0, 1]
                .map(i => ({
                    [`vendor${i + 1}Email`]: val => {
                        const t1 = val?.startsWith('address.') ? val.replace('address.', '') : val
                        return t1?.toLocaleLowerCase()
                    },
                    [`vendor${i + 1}Postcode`]: val => val.replaceAll('.', ''),
                    [`vendor${i + 1}State`]: val => val.replaceAll('.', ''),
                    [`vendor${i + 1}Suburb`]: val => val.replaceAll('.', ''),
                }))
                .reduce(reduceToObject, {}),
            propertyAddress: val => transformStreetNames(val),
            salePrice: parseNum,
            deposit1: parseNum,
            deposit2: parseNum,
        } as any,
        results: {
            ...[0, 1]
                .map(i => ({
                    [`seller${i + 1}Name`]: `${listing.vendors[i]?.vendorType == 'company' ? (listing.vendors[i] as any)?.companyName + ' - ' : ''}${listing.vendors[i]?.firstName.trim()} ${listing.vendors[i]?.lastName.trim()}`,
                    [`seller${i + 1}Email`]: listing.vendors[i]?.email,
                    [`seller${i + 1}Address`]: vendorAddresses[i] ? formatAddress(vendorAddresses[i]) : '',
                    [`seller${i + 1}State`]: vendorAddresses[i]?.state as string,
                    [`seller${i + 1}Postcode`]: vendorAddresses[i]?.postcode,
                    [`seller${i + 1}Suburb`]: vendorAddresses[i]?.suburb,
                    [`seller${i + 1}Mobile`]: listing.vendors[i]?.mobileNumber,
                    [`seller${i + 1}Abn`]:
                        listing.vendors[i]?.vendorType == 'company'
                            ? (listing.vendors[i] as any)?.abn
                            : undefined,
                    [`seller${i + 1}Acn`]:
                        listing.vendors[i]?.vendorType == 'company'
                            ? (listing.vendors[i] as any)?.acn
                            : undefined,
                    [`seller${i + 1}Signature`]: listing.vendors[i] == undefined ? 'not signed' : 'signed',
                }))
                .reduce(reduceToObject, {}),

            propertyAddress: transformStreetNames(formatAddress(listing.listingAddress)),
            propertySuburb: listing.listingAddress.suburb,
            propertyState: listing.listingAddress.state,
            propertyPostcode: listing.listingAddress.postcode,
            planNumber: listing.planNumber,
            lotNumber: listing.lotNumber,
            titleReference: listing.titleReference,

            orgName:
                `${orgDetails.licenseeName} t/as ${orgDetails.tradingName}` +
                (conjunctionalAgency ? ` in conjunction with ${conjunctionalAgency?.licenseeName}` : ''),
            licenseeName:
                orgDetails.licenseeName +
                (conjunctionalAgency ? ` in conjunction with ${conjunctionalAgency?.licenseeName}` : ''),
            orgLicenceNumber: orgDetails.licenceNumber,
            conjunctionalLicenceNumber: conjunctionalAgency?.licenseNumber,

            orgAbn: orgDetails.abn,
            conjunctionalAbn: conjunctionalAgency?.abn,
            orgLicenceExpiry: format(orgDetails.licenceExpiry, 'dd/MM/yyyy'),

            orgAddress: orgDetails.address,

            orgPhone: orgDetails.phone,
            orgMobile: agent.mobileNumber,
            orgFax: orgDetails.fax,
            orgEmail: orgDetails.email,

            sellerSolicitorName: listing.contract?.vendorSolicitor?.name,
            sellerSolicitorContact: listing.contract?.vendorSolicitor?.contact,
            sellerSolicitorEmail: listing.contract?.vendorSolicitor?.email,
            sellerSolicitorMobile: listing.contract?.vendorSolicitor?.mobileNumber,
            buyerSolicitorName: listing.contract?.buyerSolicitor?.name,
            buyerSolicitorContact: listing.contract?.buyerSolicitor?.contact,
            buyerSolicitorEmail: listing.contract?.buyerSolicitor?.email,
            buyerSolicitorMobile: listing.contract?.buyerSolicitor?.mobileNumber,
            buyersAgentName: listing.contract?.buyersAgent?.name,
            buyersAgentAbn: listing.contract?.buyersAgent?.abn,
            buyersAgentLicenceNumber: listing.contract?.buyersAgent?.licenceNumber,
            buyersAgentMobile: listing.contract?.buyersAgent?.mobileNumber,
            buyersAgentEmail: listing.contract?.buyersAgent?.email,

            buyer1Name: listing.contract?.buyers[0]?.firstName + ' ' + listing.contract?.buyers[0]?.lastName,
            buyer1Mobile: listing.contract?.buyers[0]?.mobileNumber,
            buyer1Email: listing.contract?.buyers[0]?.email,
            buyer1Signature: listing.contract?.buyers[0] == undefined ? 'not signed' : 'signed',
            buyer2Name: listing.contract?.buyers[1]?.firstName + ' ' + listing.contract?.buyers[1]?.lastName,
            buyer2Mobile: listing.contract?.buyers[1]?.mobileNumber,
            buyer2Email: listing.contract?.buyers[1]?.email,
            buyer2Signature: listing.contract?.buyers[1] == undefined ? 'not signed' : 'signed',

            depositHolder: orgDetails.trustAccountName,
            trustAccount: orgDetails.trustHolderName,
            bank: orgDetails.trustBank,
            bsb: orgDetails.trustBsb,
            accountNumber: orgDetails.trustAccountNumber,

            salePrice: listing.contract?.salePrice?.toFixed(2),
            deposit1: listing.contract?.initialDeposit?.toFixed(2),
            deposit2: listing.contract?.balanceDeposit?.toFixed(2),
        } as any,
    }
}

export const getOcrDataMap = (
    formType: FormType,
    listing: InternalSaleListingSchema,
    orgDetails: OrgSettingsSchema,
    agent: InternalUserSchema,
    conjunctionalAgency?: InternalConjunctionalAgencySchema,
): {
    results: Partial<Record<string, string | undefined>>
    transformations: Record<string, (val: string | undefined) => string | undefined>
} => {
    switch (formType) {
        case 'agentAppointmentForm':
            return azureOcrDataMap_form6(listing, orgDetails, agent, conjunctionalAgency)
        case 'contractForm':
            return azureOcrDataMap_contract(listing, orgDetails, agent, conjunctionalAgency)
    }
}
