import z from 'zod'
import { BankDetailsSchema } from './bankDetails'

export const ConjunctionalAgencySchema = z.object({
    licenseeName: z.string(),
    abn: z.string(),
    acn: z.string().optional(),
    licenseNumber: z.string(),
    licenseExpiry: z.string(),
    userIds: z.array(z.string()),
    removedUserIds: z.array(z.string()).optional(),
    agencyCommission: z.number().default(0),
    bankDetails: BankDetailsSchema,
    xero: z
        .object({
            id: z.string(),
            name: z.string(),
        })
        .optional(),
})

export type ConjunctionalAgencySchema = z.infer<typeof ConjunctionalAgencySchema>
export interface InternalConjunctionalAgencySchema
    extends Omit<ConjunctionalAgencySchema, 'userIds' | 'removedUserIds'> {
    conjunctionalAgencyId: string
}
