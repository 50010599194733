import { injectIf } from '@fastre/core/src/helperFunctions/object'
import { InternalLinkSchema, UpdateLinkSchema } from '@fastre/core/src/schemas/link'
import { zodResolver } from '@hookform/resolvers/zod'
import { Delete, Save } from '@mui/icons-material'
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Sheet,
    Stack,
} from '@mui/joy'
import { useApi } from 'api'
import { useLinksApi, useRolesApi } from 'apiProviders'
import { MultipleAgentInput } from 'components/agentInput'
import FileUploadButton from 'components/fileUploadButton'
import { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { forwardRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Resizer from 'react-image-file-resizer'

const resizeFile = (file): Promise<any> =>
    new Promise(resolve => {
        Resizer.imageFileResizer(file, 200, 200, 'PNG', 100, 0, resolve /*, 'file'*/)
    })

interface EditLinkProps {
    link?: InternalLinkSchema
    close: () => void
}

const EditLink = forwardRef(({ link, close }: EditLinkProps, ref: any) => {
    const api = useApi()
    const linksApi = useLinksApi()
    const rolesApi = useRolesApi()

    const { control, formState, handleSubmit, setValue, watch } = useForm<UpdateLinkSchema>({
        defaultValues: link ?? {},
        resolver: zodResolver(UpdateLinkSchema),
    })

    const category = watch('category')

    const [icon, setIcon] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState(false)

    const [deleting, setDeleting] = useState(false)

    const isNew = !link

    const onSubmit = async data => {
        setLoading(true)
        try {
            if (isNew) {
                await api.post('/link', {
                    ...data,
                    icon,
                    order:
                        linksApi.maybeData.orSome([]).find(link => link.category == category)?.links.length ??
                        0,
                })
            } else {
                await api.post(`/link/${link.linkId}/update`, {
                    ...data,
                    icon: icon,
                    ...injectIf(category != link.category, {
                        order:
                            linksApi.maybeData.orSome([]).find(link => link.category == category)?.links
                                .length ?? 0,
                    }),
                })
            }
            await linksApi.refresh()
            close()
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <ModalDialog
                sx={{
                    '&.MuiModalDialog-root': {
                        width: '450px',
                        maxWidth: '450px',
                    },
                }}
            >
                <ModalClose />
                <DialogTitle>{isNew ? 'Create' : 'Edit'} Link</DialogTitle>
                <DialogContent
                    sx={{
                        '&.MuiModalDialog-root': {
                            width: '450px',
                            maxWidth: '450px',
                        },
                    }}
                >
                    <Stack gap={2}>
                        <Box />
                        <Controller
                            name="linkName"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Name"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="linkUrl"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Link URL"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="category"
                            control={control}
                            render={field => (
                                <FormControl>
                                    <FormLabel>Category</FormLabel>
                                    <Autocomplete
                                        {...field}
                                        freeSolo
                                        value={category}
                                        options={linksApi.maybeData.orSome([]).map(x => x.category)}
                                        onInputChange={(e, value) => setValue('category', value ?? '')}
                                        onChange={(e, value) => setValue('category', value ?? '')}
                                    />
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="availableToRoles"
                            control={control}
                            render={field => (
                                <SlotWrapper
                                    {...field}
                                    label="Available to Roles"
                                >
                                    <Select
                                        multiple
                                        onChange={(e, value) => setValue('availableToRoles', value as any)}
                                        disabled={false}
                                    >
                                        {rolesApi.maybeData.orSome([]).map(role => (
                                            <Option
                                                key={role.id}
                                                value={role.id}
                                            >
                                                {role.roleName}
                                            </Option>
                                        ))}
                                    </Select>
                                </SlotWrapper>
                            )}
                        />
                        <Controller
                            name="availableToUsers"
                            control={control}
                            render={field => (
                                <SlotWrapper
                                    {...field}
                                    label="Available to Users"
                                >
                                    <MultipleAgentInput />
                                </SlotWrapper>
                            )}
                        />
                        <Controller
                            name="showInTab"
                            control={control}
                            render={({ field }) => (
                                <Box>
                                    <Checkbox
                                        label="Show in Marketing Tab"
                                        {...field}
                                        checked={field.value == 'marketing'}
                                        onChange={e => field.onChange(e.target.checked ? 'marketing' : '')}
                                    />
                                </Box>
                            )}
                        />
                        <Box
                            sx={{
                                m: 3,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Sheet
                                sx={{
                                    width: '4rem',
                                    height: '4rem',
                                }}
                            >
                                <img
                                    src={icon ? /*URL.createObjectURL*/ icon : link?.icon}
                                    style={{
                                        borderRadius: 2,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Sheet>
                        </Box>
                        <FileUploadButton
                            loading={loading}
                            accept="image/*"
                            onChange={async files => {
                                const reized = await resizeFile(files[0])
                                setIcon(reized)
                            }}
                        >
                            Upload File
                        </FileUploadButton>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        startDecorator={<Save />}
                        type="submit"
                    >
                        Save
                    </Button>
                    <Box sx={{ flex: 1 }} />
                    {!isNew && (
                        <Button
                            startDecorator={<Delete />}
                            variant="outlined"
                            color="danger"
                            onClick={() => setDeleting(true)}
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </ModalDialog>
            {link && (
                <Modal
                    open={deleting}
                    onClose={dontCloseOnBackgroundClick(() => setDeleting(false))}
                >
                    <ModalDialog>
                        <DialogContent>Are you sure you want to delete {link.linkName}?</DialogContent>
                        <DialogActions>
                            <Button
                                color="danger"
                                loading={loading}
                                onClick={async () => {
                                    setLoading(true)
                                    try {
                                        await api.post(`/link/${link.linkId}/delete`)
                                        linksApi.refresh()
                                        close()
                                    } catch (error) {
                                        console.error(error)
                                    }
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => setDeleting(false)}
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </ModalDialog>
                </Modal>
            )}
        </form>
    )
})

export default EditLink
