import { FormType } from '@fastre/core/src/schemas/rei'
import { DeleteRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Modal,
    ModalClose,
    ModalDialog,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useListingType } from 'apiProviders'
import axios from 'axios'
import DropZoneInner from 'components/dropzone'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { useListingContext } from 'listings/listingProvider'
import { PDFDocument } from 'pdf-lib'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'

interface UploadFormModalProps {
    formType: FormType
    open: boolean
    close: () => void
}

export default ({ formType, open, close }: UploadFormModalProps) => {
    const api = useApi()
    const listingType = useListingType()
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()

    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState<File[]>([])
    const [error, setError] = useState<string | undefined>()

    useEffect(() => {
        if (!open) {
            setFiles([])
            setError(undefined)
        }
    }, [open])

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(() => close())}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>Upload Form</DialogTitle>
                <DialogContent sx={{ width: '450px' }}>
                    {error ? (
                        <Typography>{error}</Typography>
                    ) : (
                        <>
                            <DropZoneInner
                                options={{
                                    accept: {
                                        'application/pdf': ['.pdf'],
                                    },
                                    maxFiles: 1,
                                }}
                                onUpload={files => {
                                    //setFiles(concat(files))
                                    setFiles(files)
                                }}
                            />
                            {files.length > 0 && (
                                <>
                                    <Typography>Selected file:</Typography>
                                    {files.map(file => (
                                        <Box
                                            key={file.name}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography>{file.name}</Typography>
                                            <IconButton
                                                onClick={() => setFiles(files.filter(f => f !== file))}
                                            >
                                                <DeleteRounded />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {!error && (
                        <Button
                            loading={loading}
                            onClick={async () => {
                                try {
                                    setLoading(true)
                                    const { presignedUrl } = await api
                                        .post(
                                            `listing/${listingType}/${listing.listingId}/forms/${formType}/manualupload`,
                                        )
                                        .then(prop('data'))

                                    if (files.length > 1) {
                                        const mergedPdf = await PDFDocument.create()

                                        for (const file of files) {
                                            const arrayBuffer = await file.arrayBuffer()
                                            try {
                                                const pdfDoc = await PDFDocument.load(arrayBuffer, {
                                                    ignoreEncryption: false,
                                                })

                                                const copiedPages = await mergedPdf.copyPages(
                                                    pdfDoc,
                                                    pdfDoc.getPageIndices(),
                                                )
                                                copiedPages.forEach(page => mergedPdf.addPage(page))
                                            } catch (error) {
                                                console.error(error)
                                                setError(
                                                    `Error merging ${file.name}.  This is generally caused by encrypted PDFs.`,
                                                )
                                                return
                                            }
                                        }

                                        const mergedPdfBytes = await mergedPdf.save()
                                        // convert to file
                                        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' })
                                        const file = new File([blob], `${formType}.pdf`)
                                        await axios.put(presignedUrl, file)
                                    } else {
                                        await axios.put(presignedUrl, files[0])
                                    }

                                    setListing({ ...listing, [formType]: { formStatus: 'manual upload' } })
                                    showSnack('Form uploaded', 'success')
                                    close()
                                } catch (error) {
                                    showSnack('Error uploading form', 'danger')
                                    console.error(error)
                                } finally {
                                    setLoading(false)
                                }
                            }}
                            disabled={files.length === 0}
                        >
                            Upload
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
