import { UpdateConjunctionalAgreementSchema } from '@fastre/core/src/schemas/saleListing'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded } from '@mui/icons-material'
import { Box, Button, IconButton, Radio, RadioGroup, Stack } from '@mui/joy'
import { useApi } from 'api'
import { useUserData } from 'auth'
import AgentInput from 'components/agentInput'
import { SlotInput, SlotWrapper } from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm,
    useFormContext,
} from 'react-hook-form'
import { useListingContext } from './listingProvider'

const Line = ({
    index,
    removeAgreement,
    disabled,
}: {
    index: number
    removeAgreement: () => void
    disabled: boolean
}) => {
    const { watch, control, setValue } = useFormContext()

    const agreementType = watch(`conjunctionalAgreements.${index}.agreementType`)
    const type = watch(`conjunctionalAgreements.${index}.type`)

    console.log('disabled', disabled)

    return (
        <>
            <Stack
                direction="row"
                gap={2}
            >
                <Controller
                    control={control}
                    disabled={disabled}
                    name={`conjunctionalAgreements.${index}.type`}
                    render={field => (
                        <SlotWrapper
                            {...field}
                            formControlProps={{
                                sx: {
                                    justifyContent: 'flex-end',
                                },
                            }}
                        >
                            <RadioGroup
                                onChange={e => {
                                    field.field.onChange(e)
                                    setValue(`conjunctionalAgreements.${index}.type`, e.target.value as any)
                                }}
                            >
                                <Radio
                                    value="internal"
                                    label="Internal"
                                    disabled={disabled}
                                />
                                <Radio
                                    value="external"
                                    label="External"
                                    disabled={disabled}
                                />
                            </RadioGroup>
                        </SlotWrapper>
                    )}
                />
                {type == 'external' && (
                    <Controller
                        control={control}
                        disabled={disabled}
                        name={`conjunctionalAgreements.${index}.referrerName`}
                        render={field => (
                            <SlotInput
                                label="Referrer Name"
                                {...field}
                            />
                        )}
                    />
                )}
                {type == 'internal' && (
                    <Controller
                        control={control}
                        disabled={disabled}
                        name={`conjunctionalAgreements.${index}.agentId`}
                        render={field => (
                            <SlotWrapper
                                {...field}
                                label="Agent"
                            >
                                <AgentInput {...field.field} />
                            </SlotWrapper>
                        )}
                    />
                )}
                <Controller
                    control={control}
                    disabled={disabled}
                    name={`conjunctionalAgreements.${index}.agreementType`}
                    render={field => (
                        <SlotWrapper
                            {...field}
                            formControlProps={{
                                sx: {
                                    justifyContent: 'flex-end',
                                },
                            }}
                        >
                            <RadioGroup
                                onChange={e => {
                                    field.field.onChange(e)
                                    setValue(
                                        `conjunctionalAgreements.${index}.agreementType`,
                                        e.target.value as any,
                                    )
                                }}
                            >
                                <Radio
                                    value="fixed"
                                    label="Fixed"
                                    disabled={disabled}
                                />
                                <Radio
                                    value="percentage"
                                    label="Percentage"
                                    disabled={disabled}
                                />
                            </RadioGroup>
                        </SlotWrapper>
                    )}
                />
                {agreementType === 'fixed' && (
                    <Controller
                        control={control}
                        disabled={disabled}
                        name={`conjunctionalAgreements.${index}.commissionAmount`}
                        render={field => (
                            <SlotInput
                                label="Amount"
                                type="dollar"
                                {...field}
                            />
                        )}
                    />
                )}
                {agreementType === 'percentage' && (
                    <Controller
                        control={control}
                        disabled={disabled}
                        name={`conjunctionalAgreements.${index}.commissionPercent`}
                        render={field => (
                            <SlotInput
                                label="Percentage"
                                type="number"
                                endDecorator="%"
                                {...field}
                            />
                        )}
                    />
                )}
                {!disabled && (
                    <Box sx={{ display: 'flex' }}>
                        <IconButton
                            onClick={removeAgreement}
                            sx={{ mt: 'auto', mb: 1 }}
                        >
                            <DeleteRounded />
                        </IconButton>
                    </Box>
                )}
            </Stack>
        </>
    )
}

export default function ConjunctionalAgreement() {
    const { listing, setListing } = useListingContext()
    const api = useApi()
    const showSnack = useShowSnack()
    const { user } = useUserData()

    const formDisabled =
        !user.permissions.includes('listings.fullControl') && listing.agentUserId != user.userId

    console.log('formDisabled', formDisabled)

    const hookForm = useForm<UpdateConjunctionalAgreementSchema>({
        resolver: zodResolver(UpdateConjunctionalAgreementSchema),
        defaultValues: {
            conjunctionalAgreements: listing.conjunctionalAgreements,
        },
    })

    const { control, handleSubmit, formState, getValues, setValue, trigger, reset, watch } = hookForm

    const {
        fields: conjunctionalAgreements,
        append: appendAgreement,
        remove: removeAgreement,
    } = useFieldArray({
        control,
        name: 'conjunctionalAgreements',
    })

    useEffect(() => {
        reset({
            conjunctionalAgreements: listing.conjunctionalAgreements,
        })
    }, [listing.listingId])

    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const onSubmit: SubmitHandler<UpdateConjunctionalAgreementSchema> = async data => {
        setSubmitLoading(true)
        try {
            const updatedListingData = await api
                .post(`/listing/sale/${listing.listingId}/updateconjunctionalagreements`, data)
                .then(prop('data'))

            const updatedListing = { ...listing, ...updatedListingData }

            setListing(updatedListing)
            reset(updatedListing)
            showSnack('Conjunctional agreements updated', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error saving conjunctional agreements', 'danger')
        } finally {
            setSubmitLoading(false)
        }
    }

    return (
        <Box>
            <FormProvider {...hookForm}>
                <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack spacing={2}>
                        {conjunctionalAgreements.map((conj, index) => (
                            <Line
                                key={conj.id}
                                index={index}
                                removeAgreement={() => removeAgreement(index)}
                                disabled={formDisabled}
                            />
                        ))}
                    </Stack>
                    {!formDisabled && (
                        <>
                            <Button
                                variant="soft"
                                sx={{ mt: 2 }}
                                onClick={() =>
                                    appendAgreement({
                                        type: 'external',
                                        referrerName: '',
                                        agreementType: 'fixed',
                                        commissionAmount: 0,
                                    })
                                }
                            >
                                Add Agreement
                            </Button>

                            <Box sx={{ mt: 6 }}>
                                <Button
                                    type="submit"
                                    loading={submitLoading}
                                >
                                    Save
                                </Button>
                            </Box>
                        </>
                    )}
                </form>
            </FormProvider>
        </Box>
    )
}
