import { roundTo2 } from '@fastre/core/src/helperFunctions/number'
import { ListingContractSchema } from '@fastre/core/src/schemas/contract'
import { VendorType, getVendorName } from '@fastre/core/src/schemas/vendor'
import { zodResolver } from '@hookform/resolvers/zod'
import { Add, BlockRounded, CheckRounded, CloseRounded, Save } from '@mui/icons-material'
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useListingsApi } from 'apiProviders'
import { useUserData } from 'auth'
import Input, { SlotInput } from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { MobileFriendlyStack } from 'components/stack'
import UnsavedModal from 'components/unsavedModal'
import { addDays, differenceInDays, format } from 'date-fns'
import { RenderListingValues } from 'listings/additionalValues'
import { omit, prop } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useBlocker } from 'react-router'
import FormStepper from '../formStepper'
import { useListingContext } from '../listingProvider'
import VendorList from '../vendorList'
import ConditionRender, { getDueDate } from './conditionRender'
import EditSolicitor from './editSolicitorModal'

const SectionHead = ({ children }: { children: React.ReactNode }) => (
    <Typography
        level="h4"
        sx={{
            pt: 2,
        }}
    >
        {children}
    </Typography>
)

const VoidContractModal = ({ onClose }: { onClose: (done: boolean) => void }) => {
    const { listing, setListing } = useListingContext()
    const api = useApi()
    const showSnack = useShowSnack()

    const [loading, setLoading] = useState(false)

    return (
        <ModalDialog>
            <ModalClose />
            <DialogTitle>Void Contract</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography>
                        Are you sure you want to void this contract? This action cannot be undone.
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    loading={loading}
                    color="danger"
                    onClick={async () => {
                        setLoading(true)
                        try {
                            const updatedListing = await api
                                .post(`/listing/sale/${listing.listingId}/rei/contractform/void`)
                                .then(prop('data'))
                            setListing(updatedListing)
                            showSnack('Contract voided', 'success')
                            onClose(true)
                        } catch (e) {
                            console.error(e)
                            showSnack('Error voiding contract', 'danger')
                        } finally {
                            setLoading(false)
                        }
                    }}
                >
                    Void Contract
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => onClose(false)}
                >
                    Cancel
                </Button>
            </DialogActions>
        </ModalDialog>
    )
}

const DepositPaidRender = ({ depositNum }: { depositNum: number }) => {
    const { listing } = useListingContext()

    return (
        <FormControl>
            <FormLabel>Paid</FormLabel>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {listing.balanceSheet?.find(x => x.externalRef == `deposit${depositNum}`)?.paid ? (
                    <CheckRounded color="success" />
                ) : (
                    <CloseRounded />
                )}
            </Box>
        </FormControl>
    )
}

const Contract = () => {
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()
    const api = useApi()
    const { user } = useUserData()
    const listingsApi = useListingsApi(false)

    const [loading, setLoading] = useState(false)
    const [voidModalOpen, setVoidModalOpen] = useState(false)
    const [vendorType, setVendorType] = useState<VendorType>(
        listing?.contract?.buyers[0]?.vendorType ?? 'individual',
    )

    const [ocrLoading, setOcrLoading] = useState(false)

    const formEnabled =
        user.permissions.includes('listings.fullControl') ||
        (listing.contractForm == undefined &&
            user.permissions.includes('listings.edit') &&
            listing.agentUserId == user.userId)

    const formDisabled = !formEnabled

    const formFullyDisabled =
        !user.permissions.includes('listings.fullControl') &&
        !(listing.agentUserId == user.userId && user.permissions.includes('listings.edit'))

    const hookForm = useForm<ListingContractSchema>({
        defaultValues: {
            buyers: (listing as any).contract?.buyers ?? [
                {
                    vendorType: 'individual',
                    name: '',
                    email: '',
                    phone: '',
                },
            ],
            initialDepositDue: 1,
            settlementDateDays: 30,
            ...((listing as any).contract ?? {}),
            additionalDetails: listing.additionalDetails ?? {},
        },
        resolver: zodResolver(ListingContractSchema),
    })

    const { register, control, handleSubmit, formState, getValues, setValue, trigger, reset, watch } =
        hookForm

    const salePrice = watch('salePrice')
    const contractDate = watch('contractDate')
    const initialDepositDue = watch('initialDepositDue')
    const balanceDepositDue = watch('balanceDepositDue')
    const settlementDateDays = watch('settlementDateDays')
    const buyersAgent = watch('buyersAgent')
    const commissionOverride = watch('commissionOverride') ?? listing.contract?.commissionOverride
    const commissionOverrideValue = watch('commissionOverrideValue')
    const buildingAndPestRequired = watch('buildingAndPestRequired')
    const requiresFinance = watch('requiresFinance')

    const {
        fields: buyers,
        append: appendBuyer,
        remove: removeBuyer,
    } = useFieldArray({
        control,
        name: 'buyers',
    })

    const {
        fields: conditions,
        append: appendCondition,
        prepend: prependCondition,
        remove: removeCondition,
    } = useFieldArray({
        control,
        name: 'contractConditions',
    })

    const onSubmit: SubmitHandler<ListingContractSchema> = async ({ additionalDetails, ...data }) => {
        console.log('saving contra')

        setLoading(true)
        try {
            const updatedListing = await api
                .post(`/listing/sale/${listing.listingId}/upsertcontract`, {
                    contract: data,
                    additionalDetails,
                })
                .then(prop('data'))
            setListing(updatedListing)
            reset({
                ...updatedListing.contract,
                additionalDetails: updatedListing.additionalDetails,
            })
            showSnack('Contract saved', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error saving contract data', 'danger')
        } finally {
            setLoading(false)
        }
    }

    const handleSubmitPromise = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            handleSubmit(
                async data => {
                    try {
                        console.log('onSubmit calling')
                        await onSubmit(data)
                        resolve(true)
                    } catch (error) {
                        resolve(false)
                    }
                },
                error => {
                    resolve(false)
                },
            )()
        })
    }

    const saveForm = async (): Promise<boolean> => {
        if (formDisabled) {
            setLoading(true)
            try {
                const updatedListing = await api
                    .post(`/listing/sale/${listing.listingId}/updatelockedcontract`, {
                        additionalDetails: getValues('additionalDetails'),
                        contractDate: getValues('contractDate'),
                    })
                    .then(prop('data'))
                setListing(updatedListing)
                reset(updatedListing)
                listingsApi.refreshSpecific(listing.listingId)
                showSnack('Contract saved', 'success')
                return true
            } catch (e) {
                console.error(e)
                showSnack('Error saving contract', 'danger')
                return false
            } finally {
                setLoading(false)
            }
        } else {
            return await handleSubmitPromise()
        }
    }

    if (Object.keys(formState.errors).length > 0) {
        console.log('errors', formState.errors)
    }

    useEffect(() => {
        // Force refresh on dirty states
        console.log('formstate.isdirty', formState.isDirty)
        console.log('formState.dirtyFields', formState.dirtyFields)
    }, [JSON.stringify(formState.dirtyFields), formState.isDirty])

    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        //return false
        //console.log('dirty fields', formState.dirtyFields)
        //console.log('is dirty', formState.isDirty)
        return (
            currentLocation.pathname !== nextLocation.pathname &&
            formState.isDirty &&
            Object.keys(formState.dirtyFields).length > 0
        )
    })

    return (
        <>
            <FormProvider {...hookForm}>
                <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack gap={2}>
                        <MobileFriendlyStack>
                            <Controller
                                name="salePrice"
                                disabled={formDisabled}
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Price"
                                        type="dollar"
                                        startDecorator="$"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                    />
                                )}
                            />
                            <Stack gap={2}>
                                <Controller
                                    name="contractDate"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Contract Date"
                                            type="date"
                                            {...field}
                                            formControlProps={{
                                                sx: { flex: 1 },
                                            }}
                                            fullWidth
                                            disabled={listing.contractForm?.formStatus != 'manual upload'}
                                        />
                                    )}
                                />
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Controller
                                        name="commissionOverride"
                                        control={control}
                                        disabled={formDisabled}
                                        render={({ field }) => (
                                            <Stack
                                                direction="row"
                                                gap={2}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '15px',
                                                    }}
                                                >
                                                    Commission Override
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Checkbox
                                                        //label="Commission Override"
                                                        {...omit(['value'], field)}
                                                        checked={field.value}
                                                        onChange={e => {
                                                            field.onChange(e.target.checked)
                                                            if (!e.target.checked) {
                                                                setValue(
                                                                    'commissionOverrideValue',
                                                                    undefined,
                                                                    {
                                                                        shouldDirty: true,
                                                                    },
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </Stack>
                                        )}
                                    />
                                </Box>
                            </Stack>
                            <Stack
                                sx={{
                                    gap: 2,
                                    alignItems: 'center',
                                }}
                            >
                                <Input
                                    label="Commission Inc"
                                    value={roundTo2(
                                        ((listing.commission ?? 0) *
                                            (salePrice ?? listing.contract?.salePrice ?? 0)) /
                                            100,
                                    )}
                                    type="dollar"
                                    startDecorator="$"
                                    readOnly
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                    fullWidth
                                    disabled
                                    sx={{
                                        '> input': {
                                            textDecoration: commissionOverride ? 'line-through' : undefined,
                                        },
                                    }}
                                />
                                <Controller
                                    name="commissionOverrideValue"
                                    control={control}
                                    disabled={formDisabled}
                                    render={field => (
                                        <Box
                                            sx={{
                                                opacity: commissionOverride ? 1 : 0,
                                                pointerEvents: commissionOverride ? 'auto' : 'none',
                                            }}
                                        >
                                            <SlotInput
                                                label="Commission Override Inc"
                                                type="dollar"
                                                startDecorator="$"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                                fullWidth
                                                tabIndex={commissionOverride ? undefined : -1}
                                            />
                                        </Box>
                                    )}
                                />
                            </Stack>
                            {user.permissions.includes('listings.fullControl') && (
                                <Stack gap={2}>
                                    <Input
                                        label="Commission Exc"
                                        value={roundTo2(((listing.commission ?? 0) * salePrice) / 100 / 1.1)}
                                        type="dollar"
                                        startDecorator="$"
                                        readOnly
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                        fullWidth
                                        disabled
                                        sx={{
                                            '> input': {
                                                textDecoration: commissionOverride
                                                    ? 'line-through'
                                                    : undefined,
                                            },
                                        }}
                                    />
                                    <Controller
                                        name="commissionOverrideValue"
                                        control={control}
                                        disabled={formDisabled}
                                        render={field => (
                                            <Box
                                                sx={{
                                                    opacity: commissionOverride ? 1 : 0,
                                                    pointerEvents: commissionOverride ? 'auto' : 'none',
                                                }}
                                            >
                                                <SlotInput
                                                    label="Commission Override Exc"
                                                    type="dollar"
                                                    startDecorator="$"
                                                    {...omit(['value'], field)}
                                                    value={
                                                        commissionOverrideValue
                                                            ? roundTo2(commissionOverrideValue / 1.1)
                                                            : undefined
                                                    }
                                                    onChange={(val: any) =>
                                                        field.field.onChange(val ? roundTo2(val * 1.1) : val)
                                                    }
                                                    formControlProps={{
                                                        sx: { flex: 1 },
                                                    }}
                                                    tabIndex={commissionOverride ? undefined : -1}
                                                    fullWidth
                                                />
                                            </Box>
                                        )}
                                    />
                                </Stack>
                            )}
                        </MobileFriendlyStack>
                        <SectionHead>Vendors</SectionHead>
                        <Grid
                            container
                            spacing={2}
                            columns={{ xs: 1, sm: 2 }}
                        >
                            {listing.vendors?.map((vendor, i) => (
                                <Grid
                                    xs={1}
                                    key={vendor.firstName}
                                >
                                    <Input
                                        key={i}
                                        value={getVendorName(vendor)}
                                        label={`Vendor ${i + 1}`}
                                        readOnly
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                        disabled
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <SectionHead>Vendor Solicitor</SectionHead>
                        <EditSolicitor
                            control={control}
                            solicitor="vendorSolicitor"
                            disabled={formDisabled}
                        />

                        <Stack gap={2}>
                            <VendorList
                                name="Buyer"
                                disabled={formDisabled}
                                vendors={buyers}
                                removeVendor={removeBuyer}
                                vendorType={vendorType}
                                setVendorType={setVendorType}
                                showOccupantStatus={false}
                            />
                            {buyers.length < 6 && !formDisabled && (
                                <Box sx={{ mt: 2 }}>
                                    <Button
                                        variant="soft"
                                        onClick={() =>
                                            appendBuyer({
                                                vendorType: vendorType as any,
                                                firstName: '',
                                                lastName: '',
                                                email: '',
                                                mobileNumber: '',
                                            })
                                        }
                                        startDecorator={<Add />}
                                    >
                                        Add Buyer
                                    </Button>
                                </Box>
                            )}
                        </Stack>

                        <Stack
                            direction="row"
                            gap={2}
                            sx={{
                                alignItems: 'flex-end',
                            }}
                        >
                            <SectionHead>Buyers Agent</SectionHead>
                            <Checkbox
                                checked={buyersAgent != undefined}
                                onChange={e => {
                                    setValue('hasBuyersAgent', e.target.checked, { shouldDirty: true })

                                    if (e.target.checked) {
                                        setValue('buyersAgent', {
                                            name: '',
                                        })
                                    } else {
                                        setValue('buyersAgent', undefined)
                                    }
                                }}
                                sx={{
                                    mb: '3px',
                                }}
                                disabled={formDisabled}
                            />
                        </Stack>
                        {buyersAgent && (
                            <>
                                <Controller
                                    name="buyersAgent.name"
                                    control={control}
                                    disabled={formDisabled}
                                    render={field => (
                                        <SlotInput
                                            label="Name"
                                            {...field}
                                            formControlProps={{
                                                sx: { flex: 1 },
                                            }}
                                        />
                                    )}
                                />
                                <MobileFriendlyStack>
                                    <Controller
                                        name="buyersAgent.email"
                                        control={control}
                                        disabled={formDisabled}
                                        render={field => (
                                            <SlotInput
                                                label="Email"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="buyersAgent.mobileNumber"
                                        control={control}
                                        disabled={formDisabled}
                                        render={field => (
                                            <SlotInput
                                                label="Mobile Number"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                </MobileFriendlyStack>
                                <MobileFriendlyStack>
                                    <Controller
                                        name="buyersAgent.licenceNumber"
                                        control={control}
                                        disabled={formDisabled}
                                        render={field => (
                                            <SlotInput
                                                label="Licence Number"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="buyersAgent.abn"
                                        control={control}
                                        disabled={formDisabled}
                                        render={field => (
                                            <SlotInput
                                                label="ABN"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                </MobileFriendlyStack>
                            </>
                        )}

                        <SectionHead>Buyer Solicitor</SectionHead>
                        <EditSolicitor
                            control={control}
                            solicitor={'buyerSolicitor'}
                            disabled={formDisabled}
                        />

                        <SectionHead>Deposits</SectionHead>
                        <MobileFriendlyStack>
                            <Controller
                                name="initialDeposit"
                                disabled={formDisabled}
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Initial Deposit"
                                        type="dollar"
                                        startDecorator="$"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                        fullWidth
                                    />
                                )}
                            />
                            <Controller
                                name="initialDepositDue"
                                disabled={formDisabled}
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Due (days from contract date)"
                                        //type='date'
                                        type="number"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                        fullWidth
                                    />
                                )}
                            />
                            <Input
                                label="Due Date"
                                type="date"
                                value={
                                    contractDate && initialDepositDue
                                        ? getDueDate(new Date(contractDate), initialDepositDue)
                                        : undefined
                                }
                                formControlProps={{
                                    sx: { flex: 1 },
                                }}
                                readOnly
                                disabled
                                fullWidth
                            />
                            <DepositPaidRender depositNum={1} />
                        </MobileFriendlyStack>
                        <MobileFriendlyStack>
                            <Controller
                                name="balanceDeposit"
                                disabled={formDisabled}
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Balance Deposit"
                                        type="dollar"
                                        startDecorator="$"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="balanceDepositDue"
                                disabled={formDisabled}
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Due (days from contract date)"
                                        //type='date'
                                        type="number"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                    />
                                )}
                            />
                            <Input
                                label="Due Date"
                                type="date"
                                value={getDueDate(
                                    contractDate ? new Date(contractDate) : undefined,
                                    balanceDepositDue,
                                )}
                                formControlProps={{
                                    sx: { flex: 1 },
                                }}
                                readOnly
                                disabled
                                fullWidth
                            />
                            <DepositPaidRender depositNum={2} />
                        </MobileFriendlyStack>
                        <MobileFriendlyStack>
                            <Controller
                                name="settlementDateDays"
                                disabled={formDisabled}
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Settlement Date (days from contract date)"
                                        type="number"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                    />
                                )}
                            />
                            <Input
                                label="Settlement Date"
                                type="date"
                                value={
                                    contractDate && settlementDateDays
                                        ? format(
                                              addDays(new Date(contractDate), settlementDateDays),
                                              'yyyy-MM-dd',
                                          )
                                        : undefined
                                }
                                onChange={val =>
                                    setValue(
                                        'settlementDateDays',
                                        differenceInDays(new Date(val), new Date(contractDate!)),
                                    )
                                }
                                formControlProps={{
                                    sx: { flex: 1 },
                                }}
                                disabled={!contractDate || formDisabled}
                                fullWidth
                            />
                        </MobileFriendlyStack>
                        <Typography level="h4">Conditions</Typography>
                        <Box>
                            <Controller
                                name="requiresFinance"
                                control={control}
                                disabled={formDisabled}
                                render={({ field }) => (
                                    <Checkbox
                                        label="Requires Finance"
                                        {...omit(['value'], field)}
                                        checked={requiresFinance}
                                        onChange={e => {
                                            console.log('requires finance', e.target.checked)
                                            //field.onChange(e.target.checked)
                                            setValue('requiresFinance', e.target.checked)
                                            if (e.target.checked) {
                                                console.log('prepending')
                                                appendCondition({
                                                    condition: 'Subject to Finance',
                                                    //conditionDate: format(new Date(), 'yyyy-MM-dd'),
                                                    dueDaysFromContract: 7,
                                                    fulfilled: false,
                                                    special: 'requiresFinance',
                                                })
                                            } else {
                                                removeCondition(
                                                    conditions.findIndex(x => x.special == 'requiresFinance'),
                                                )
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Controller
                                name="buildingAndPestRequired"
                                control={control}
                                disabled={formDisabled}
                                render={({ field }) => (
                                    <Checkbox
                                        label="Building & Pest Required"
                                        {...omit(['value'], field)}
                                        checked={buildingAndPestRequired}
                                        onChange={e => {
                                            console.log('building and pest required', e.target.checked)
                                            //field.onChange(e.target.checked)
                                            setValue('buildingAndPestRequired', e.target.checked)
                                            if (e.target.checked) {
                                                console.log('prepending')
                                                appendCondition({
                                                    condition: 'Building & Pest',
                                                    //conditionDate: format(new Date(), 'yyyy-MM-dd'),
                                                    dueDaysFromContract: 7,
                                                    fulfilled: false,
                                                    special: 'buildingAndPestRequired',
                                                })
                                            } else {
                                                console.log(
                                                    'index',
                                                    conditions.findIndex(
                                                        x => x.special == 'buildingAndPestRequired',
                                                    ),
                                                )
                                                removeCondition(
                                                    conditions.findIndex(
                                                        x => x.special == 'buildingAndPestRequired',
                                                    ),
                                                )
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        {conditions.map((condition, i) => (
                            <ConditionRender
                                key={condition.id}
                                index={i}
                                remove={() => removeCondition(i)}
                                contractDate={contractDate ? new Date(contractDate) : undefined}
                                formDisabled={formDisabled}
                            />
                        ))}
                        {!formDisabled && (
                            <Box>
                                <Button
                                    variant="soft"
                                    startDecorator={<Add />}
                                    onClick={() =>
                                        appendCondition({
                                            condition: '',
                                            //conditionDate: format(new Date(), 'yyyy-MM-dd'),
                                            dueDaysFromContract: 7,
                                            fulfilled: false,
                                        })
                                    }
                                >
                                    Add Condition
                                </Button>
                            </Box>
                        )}
                    </Stack>
                    {!formFullyDisabled && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                my: 4,
                            }}
                        >
                            <Button
                                startDecorator={<Save />}
                                loading={loading}
                                //type="submit"
                                onClick={saveForm}
                            >
                                Save
                            </Button>
                            {user.permissions.includes('listings.fullControl') && (
                                <Button
                                    variant="soft"
                                    color="danger"
                                    startDecorator={<BlockRounded />}
                                    onClick={() => setVoidModalOpen(true)}
                                >
                                    Void Contract
                                </Button>
                            )}
                        </Box>
                    )}
                    <UnsavedModal
                        blocker={blocker}
                        save={(onSuccess, onFail) => saveForm().then(x => (x ? onSuccess() : onFail()))}
                    />

                    <Box sx={{ mt: 4 }} />
                    {user.superUser && user.lastName == 'Bleier' && (
                        <Box>
                            <Button
                                color="danger"
                                loading={ocrLoading}
                                onClick={async () => {
                                    setOcrLoading(true)
                                    await api.post(`/listing/sale/${listing.listingId}/contractform/azureocr`)
                                    setOcrLoading(false)
                                }}
                                sx={{ mb: 2 }}
                            >
                                OCR
                            </Button>
                        </Box>
                    )}
                    <FormStepper
                        title="Contract Form"
                        formType="contractForm"
                        allowAttachments
                        createDisabled={
                            listing.vendors.length == 0 ||
                            listing.agentUserId == undefined ||
                            listing.contract == undefined ||
                            listing.contract?.buyers.length == 0 ||
                            formFullyDisabled
                        }
                        validate={async () => {
                            console.log('validate', formState.isDirty)
                            if (formState.isDirty) {
                                return await trigger()
                            }
                            return true
                        }}
                        preCreate={async () => {
                            try {
                                await handleSubmit(onSubmit)()
                                return true
                            } catch (e) {
                                return false
                            }
                        }}
                    />
                    <Box sx={{ mt: 4 }}>
                        <RenderListingValues tab="contract" />
                    </Box>
                </form>
            </FormProvider>
            <Modal
                open={voidModalOpen}
                onClose={() => setVoidModalOpen(false)}
            >
                <VoidContractModal
                    onClose={voided => {
                        setVoidModalOpen(false)
                        if (voided) {
                            window.location.reload()
                        }
                    }}
                />
            </Modal>
        </>
    )
}

export default Contract
