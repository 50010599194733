import { getContractCommission } from '@fastre/core/src/schemas/contract'
import { formatAddress } from '@fastre/core/src/schemas/generic'
import { SaleListingSearchSchema, TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { Box, Link, Sheet, Table, Typography, useTheme } from '@mui/joy'
import { useApi } from 'api'
import Loading from 'components/Loading'
import { MobileFriendlyStack } from 'components/stack'
import { addMonths, format, isSameMonth, startOfMonth } from 'date-fns'
import { useOrgId } from 'helpers'
import { groupBy, prop, sortBy, sum } from 'ramda'
import { useEffect, useState } from 'react'

const MonthSummary = ({ listings, month }: { listings: TypesenseListingSchema[]; month: Date }) => {
    const theme = useTheme()
    const thisMonth = isSameMonth(month, new Date())

    const data = Object.entries(groupBy(prop('userNames'), listings)).map(([userNames, listings]) => {
        const contractListings = listings.filter(
            listing =>
                /*listing.statusLog &&
                listing.statusLog['under contract'] &&
                isSameMonth(new Date(listing.statusLog['under contract']), month),*/
                listing.contract?.contractDate &&
                isSameMonth(new Date(listing.contract?.contractDate), month),
        )

        return {
            agent: userNames,
            uc: contractListings.length,
            avgComm:
                contractListings.length > 0
                    ? (
                          sum(contractListings.map(l => l.commission ?? 0)) / contractListings.length
                      ).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      })
                    : '',
            mtdContracts: sum(contractListings.map(x => x.contract?.salePrice ?? 0)),
            newListings: listings.filter(
                listing => listing.authorityStartDate && isSameMonth(listing.authorityStartDate, month),
            ).length,
            totalListings: listings.length,
        }
    })

    return (
        <Box>
            <Typography
                level="h2"
                sx={{ mb: 2 }}
            >
                {format(month, 'LLLL')}
            </Typography>
            <Sheet
                variant="outlined"
                sx={{
                    width: '100%',
                    borderRadius: 'sm',
                }}
            >
                <Table
                    sx={{
                        '& th': {
                            height: '65px',
                            backgroundColor: thisMonth ? '#8B0000' : '#002147',
                            color: 'white',
                        },
                        '& tr': {
                            '&:nth-of-type(odd)': {
                                backgroundColor: theme.palette.background.level1,
                            },
                        },
                    }}
                >
                    <thead>
                        <tr>
                            <th>Agent</th>
                            <th
                                style={{ textAlign: 'right' }}
                                align="right"
                            >
                                U/C
                            </th>
                            <th
                                style={{ textAlign: 'right' }}
                                align="right"
                            >
                                Avg Comm
                            </th>
                            <th
                                style={{ textAlign: 'right' }}
                                align="right"
                            >
                                {thisMonth && 'MTD '}Contracts $
                            </th>
                            <th
                                style={{ textAlign: 'right' }}
                                align="right"
                            >
                                New Listings
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortBy(x => -x.mtdContracts, data).map((row, index) => (
                            <tr key={index}>
                                <td>{row.agent}</td>
                                <td align="right">{row.uc}</td>
                                <td align="right">
                                    {row.avgComm}
                                    {row.avgComm && '%'}
                                </td>
                                <td align="right">
                                    $
                                    {row.mtdContracts.toLocaleString('en-US', {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td align="right">{row.newListings}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </Box>
    )
}

const MainTable = ({ listings }: { listings: TypesenseListingSchema[] }) => {
    const theme = useTheme()
    const orgId = useOrgId()

    return (
        <Sheet
            variant="outlined"
            sx={{ mb: 4, width: '100%', borderRadius: 'sm' }}
        >
            <Table
                //stripe="odd"
                sx={{
                    '& th': {
                        height: '65px',
                        backgroundColor: '#002147',
                        color: 'white',
                    },
                    '& tr': {
                        '&:nth-of-type(odd)': {
                            backgroundColor: theme.palette.background.level1,
                        },
                    },
                }}
            >
                <thead>
                    <tr>
                        <th>Agent</th>
                        <th>Address</th>
                        <th
                            style={{ textAlign: 'right' }}
                            align="right"
                        >
                            Contract $
                        </th>
                        <th
                            style={{ textAlign: 'right' }}
                            align="right"
                        >
                            Gross Comm $ ex
                        </th>
                        <th
                            style={{ textAlign: 'right' }}
                            align="right"
                        >
                            Comm % ex
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {listings.map((listing, index) => (
                        <tr key={index}>
                            <td>{listing.userNames}</td>
                            <td>
                                <Link href={`/${orgId}/listings/sale/${listing.id}`}>
                                    {formatAddress(listing.listingAddress)}
                                </Link>
                            </td>
                            <td align="right">
                                {listing.contract?.salePrice &&
                                    `$${listing.contract?.salePrice.toLocaleString()}`}
                            </td>
                            <td align="right">
                                {listing.contract?.salePrice &&
                                    `$${getContractCommission(listing).toLocaleString('en-US', { maximumFractionDigits: 0 })}`}
                            </td>
                            <td align="right">
                                {listing.commission &&
                                    `${listing.commission.toLocaleString('en-US', { minimumFractionDigits: 2 })}%`}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
    )
}

const SummarySection = ({ soldListings }: { soldListings: TypesenseListingSchema[] }) => {
    const totalCommission = sum(soldListings.map(getContractCommission))

    return (
        <MobileFriendlyStack>
            <Box>
                <Sheet
                    variant="outlined"
                    sx={{
                        width: '100%',
                        borderRadius: 'sm',
                        mb: 4,
                    }}
                >
                    <Table>
                        <tbody>
                            {[
                                ['# of Sales', soldListings.length],
                                [
                                    'Total Sales Contract Value',
                                    `$${sum(soldListings.map(x => x.contract?.salePrice ?? 0)).toLocaleString()}`,
                                ],
                            ].map(([label, value]) => (
                                <tr key={label}>
                                    <td>{label}</td>
                                    <td align="right">{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Sheet>
            </Box>
            <Box>
                <Sheet
                    variant="outlined"
                    sx={{
                        width: '100%',
                        borderRadius: 'sm',
                        mb: 4,
                    }}
                >
                    <Table>
                        <tbody>
                            {[
                                [
                                    'Total Commission',
                                    '$' +
                                        totalCommission.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }),
                                ],
                                [
                                    'Average Commission',
                                    soldListings.length == 0
                                        ? ''
                                        : '$' +
                                          (totalCommission / soldListings.length).toLocaleString('en-US', {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                          }),
                                ],
                            ].map(([label, value]) => (
                                <tr key={label}>
                                    <td>{label}</td>
                                    <td align="right">{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Sheet>
            </Box>
        </MobileFriendlyStack>
    )
}

export default function SalesBoard() {
    const [listings, setListings] = useState<TypesenseListingSchema[]>()
    const api = useApi()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const searchProps: Partial<SaleListingSearchSchema> = {
                    limit: 250,
                    page: 1,
                    customFilters: [
                        `contract.contractDate:>=${startOfMonth(addMonths(new Date(), -1)).getTime()}`,
                    ],
                }
                const listings = await api
                    .post('listings/sale/search', searchProps)
                    .then(({ data }) => data.hits.map(prop('document')))

                setListings(listings)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [])

    if (!listings) {
        return <Loading />
    }

    const currentMonthContracts = listings.filter(listing => {
        return (
            listing.contract?.contractDate &&
            isSameMonth(new Date(listing.contract?.contractDate), new Date())
        )
    })

    return (
        <Box sx={{ padding: 4 }}>
            <Typography
                level="h2"
                sx={{ mb: 2 }}
            >
                {format(new Date(), 'MMMM yyyy')}
            </Typography>
            <SummarySection soldListings={currentMonthContracts} />
            <MainTable listings={currentMonthContracts} />

            <MobileFriendlyStack breakpoint="lg">
                <MonthSummary
                    listings={listings}
                    month={addMonths(new Date(), -1)}
                />
                <MonthSummary
                    listings={listings}
                    month={new Date()}
                />
            </MobileFriendlyStack>
        </Box>
    )
}
