export const roundTo2 = (num: number) => Math.round(num * 100) / 100

export const countDecimals = (val: number) => {
    if (Math.floor(val) === val) {
        return 0
    }
    return val.toString().split('.')[1].length || 0
}

export const toDollarAmount = (num?: number, maximumFractionDigits?: number) =>
    '$' +
    (num ?? 0).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: maximumFractionDigits ?? 2,
    })

export const toDollarAmountFunctional = (maximumFractionDigits?: number) => (num?: number) => toDollarAmount(num, maximumFractionDigits)
