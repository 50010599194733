import { AddUserSchema, UpdateUserSchema } from '@fastre/core/src/schemas/user'
import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { Checkbox, Option, Select, Tab, TabList, Tabs, Typography } from '@mui/joy'
import AspectRatio from '@mui/joy/AspectRatio'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import IconButton from '@mui/joy/IconButton'
import Stack from '@mui/joy/Stack'
import { useApi } from 'api'
import { FrontendUserSchema, useConjunctionalAgenciesApi, useRolesApi, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { SlotAutocomplete } from 'components/autocomplete'
import { SlotInput, SlotWrapper } from 'components/input'
import SectionHead from 'components/sectionHead'
import { useShowSnack } from 'components/snackbar'
import { addMonths, format } from 'date-fns'
import { useSearchParamState } from 'helperFunctions/react'
import Ledger from 'listings/ledger'
import { Maybe } from 'monet'
import { prop } from 'ramda'
import { useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import EditAvatarModal from './editAvatarModal'
import MonthlyCharges from './monthlyCharges'
import ReiConnection from './reiConnection'

export function CoreEditUser({ user: editUser }: { user?: FrontendUserSchema }) {
    const api = useApi()
    const { user: userData } = useUserData()
    const navigate = useNavigate()
    const showSnack = useShowSnack()
    const conjunctionalAgenciesApi = useConjunctionalAgenciesApi()
    const usersApi = useUsersApi()
    const rolesApi = useRolesApi()

    const inputRef = useRef<HTMLInputElement>(null)

    const [avatarImage, setAvatarImage] = useState<string | undefined>(undefined)
    const [forceRefreshProfileImage, setForceRefreshProfileImage] = useState(Math.random() * 1000)

    const { register, control, handleSubmit, formState, getValues, setValue, resetField, watch } =
        useForm<AddUserSchema>({
            defaultValues: {
                superUser: false,
                salesAgent: false,
                ...(editUser ?? {}),
            },
            resolver: zodResolver(editUser ? UpdateUserSchema : AddUserSchema),
        })

    const conjunctionalAgencyId = watch('conjunctionalAgencyId')
    const conjunctionalAgency = conjunctionalAgenciesApi.maybeData.bind(agencies =>
        Maybe.fromUndefined(agencies.find(agency => agency.conjunctionalAgencyId === conjunctionalAgencyId)),
    )
    const roleId = watch('roleId')
    const salesAgent = watch('salesAgent')

    if (Object.keys(formState.errors).length > 0) {
        console.log('form errors', formState.errors)
    }

    const superUser = watch('superUser')

    const [editAvatarImage, setEditAvatarImage] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const formDisabled = !userData.permissions.includes('users.edit')

    const onSubmit: SubmitHandler<UpdateUserSchema> = async (data: UpdateUserSchema) => {
        setLoading(true)

        /*const data = evolve(
            {
                email: trim,
            },
            _data as any,
        )*/

        const agencyCommission = conjunctionalAgencyId
            ? (await conjunctionalAgenciesApi.promiseData).find(
                  agency => agency.conjunctionalAgencyId === conjunctionalAgencyId,
              )?.agencyCommission ?? 0
            : data.agencyCommission

        try {
            if (editUser) {
                await api.post(`/user/${editUser.userId}/update`, {
                    ...data,
                    agencyCommission,
                })
            } else {
                const newUser = await api
                    .post(`/user/add`, {
                        ...data,
                        avatarImage,
                        agencyCommission,
                    })
                    .then(prop('data'))
                //navigate(`/${userData.orgId}/users/${newUser.userId}`)
                navigate(`/${userData.orgId}/users`)
            }
            usersApi.refresh()
            showSnack(`User ${editUser ? 'updated' : 'added'}`, 'success')
        } catch (e) {
            showSnack(`Error ${editUser ? 'updating' : 'adding'} user`, 'danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <Box
                    sx={{
                        flex: 1,
                        width: '100%',
                    }}
                >
                    <Stack
                        spacing={4}
                        sx={{
                            display: 'flex',
                            maxWidth: '800px',
                            mx: 'auto',
                            px: {
                                xs: 2,
                                md: 6,
                            },
                            py: {
                                xs: 2,
                                md: 3,
                            },
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={1}
                        >
                            <Box
                                sx={{
                                    width: 120,
                                    height: 120,
                                    position: 'relative',
                                }}
                            >
                                <AspectRatio
                                    ratio="1"
                                    maxHeight={200}
                                    sx={{
                                        flex: 1,
                                        minWidth: 120,
                                        borderRadius: '100%',
                                    }}
                                >
                                    <img
                                        src={
                                            editUser?.profileImage
                                                ? `${editUser.profileImage}?forceRefreshProfileImage=${forceRefreshProfileImage}`
                                                : ''
                                        }
                                        loading="lazy"
                                        alt=""
                                    />
                                </AspectRatio>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={inputRef}
                                    onChange={e => {
                                        if (e?.target?.files && e.target.files[0]) {
                                            setEditAvatarImage(URL.createObjectURL(e.target.files[0]))
                                        }
                                    }}
                                    style={{ display: 'none' }}
                                />
                                {!formDisabled || userData.userId == editUser?.userId ? (
                                    <IconButton
                                        aria-label="upload new picture"
                                        size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        sx={{
                                            bgcolor: 'background.body',
                                            position: 'absolute',
                                            zIndex: 2,
                                            borderRadius: '50%',
                                            bottom: 0,
                                            right: 0,
                                            boxShadow: 'sm',
                                        }}
                                        onClick={e => {
                                            e.preventDefault()
                                            inputRef.current!.click()
                                        }}
                                    >
                                        <EditRoundedIcon />
                                    </IconButton>
                                ) : null}
                                <IconButton
                                    aria-label="upload new picture"
                                    size="sm"
                                    variant="outlined"
                                    color="neutral"
                                    sx={{
                                        bgcolor: 'background.body',
                                        position: 'absolute',
                                        zIndex: 2,
                                        borderRadius: '50%',
                                        bottom: 0,
                                        right: 0,
                                        boxShadow: 'sm',
                                    }}
                                    onClick={e => {
                                        e.preventDefault()
                                        inputRef.current!.click()
                                    }}
                                >
                                    <EditRoundedIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                        <Stack
                            spacing={2}
                            sx={{ flexGrow: 1 }}
                        >
                            <Stack spacing={1}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="First Name"
                                            //size="sm"
                                            disabled={editUser != undefined}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Last Name"
                                            //size="sm"
                                            disabled={editUser != undefined}
                                            {...field}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={2}
                            >
                                <Controller
                                    name="email"
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Email"
                                            {...field}
                                            disabled={editUser != undefined}
                                        />
                                    )}
                                />
                            </Stack>
                            <Controller
                                name="mobileNumber"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Mobile Number"
                                        disabled={editUser != undefined}
                                        {...field}
                                    />
                                )}
                            />
                            {/*<Box>
                                <Controller
                                    name='timezone'
                                    control={control}
                                    render={({ field, formState, ...props }) => {
                                        console.log('controller props', { field, ...props })

                                        return (
                                            <SlotWrapper
                                                label='Timezone'
                                                field={field}
                                                formState={formState}
                                            >
                                                <TimezoneSelector
                                                    //value={user.timezone}
                                                    //onChange={user.setTimezone}
                                                    {...props as any}
                                                />
                                            </SlotWrapper>
                                        )
                                    }}
                                />
                            </Box>*/}

                            {/*<Controller
                                name="groupIds"
                                control={control}
                                render={field => (
                                    <SlotAutocomplete
                                        {...field}
                                        label="Groups"
                                        multiple
                                        //value={groupIds}
                                        onChange={(event, value) => field.field.onChange(value)}
                                        options={groupsApi.maybeData.map(groups => groups.map(prop('groupId'))).orSome([])}
                                        getOptionLabel={groupId => {
                                            return groupsApi.maybeData.map(groups => groups.find(group => group.groupId === groupId)?.groupName ?? 'Not Found').orSome('loading...')
                                        }}
                                    />
                                )}
                            />*/}
                            <Controller
                                name="superUser"
                                control={control}
                                render={field => (
                                    <Checkbox
                                        label="Super User"
                                        disabled={!userData.superUser}
                                        onChange={e => {
                                            field.field.onChange(e.target.checked)
                                        }}
                                        checked={field.field.value}
                                    />
                                )}
                            />
                            {!superUser && (
                                <Controller
                                    name="roleId"
                                    control={control}
                                    render={field => (
                                        <SlotWrapper
                                            label="Role"
                                            {...field}
                                        >
                                            <Select
                                                disabled={!userData.permissions.includes('users.edit')}
                                                onChange={(e, value: any) => setValue('roleId', value)}
                                            >
                                                {rolesApi.maybeData.orSome([]).map(role => (
                                                    <Option
                                                        key={role.id}
                                                        value={role.id}
                                                    >
                                                        {role.roleName}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </SlotWrapper>
                                    )}
                                />
                            )}
                            <Controller
                                name="salesAgent"
                                control={control}
                                render={field => (
                                    <Checkbox
                                        label="Sales Agent"
                                        disabled={formDisabled}
                                        onChange={e => {
                                            field.field.onChange(e.target.checked)
                                        }}
                                        checked={field.field.value}
                                    />
                                )}
                            />
                            {salesAgent && (
                                <Controller
                                    name="conjunctionalAgencyId"
                                    control={control}
                                    disabled={formDisabled}
                                    render={field => (
                                        <SlotAutocomplete
                                            {...field}
                                            label="Conjunctional Agency"
                                            onChange={(event, value) => field.field.onChange(value)}
                                            options={conjunctionalAgenciesApi.maybeData
                                                .orSome([])
                                                .map(prop('conjunctionalAgencyId'))}
                                            //onChange={(event, value) => setConjunctionalAgencyId(value as any)}
                                            getOptionLabel={agencyId => {
                                                //console.log('get option label', agencyId)

                                                return conjunctionalAgenciesApi.maybeData
                                                    .map(
                                                        agencies =>
                                                            agencies.find(
                                                                agency =>
                                                                    agency.conjunctionalAgencyId === agencyId,
                                                            )?.licenseeName ?? 'Not Found',
                                                    )
                                                    .orSome('loading...')
                                            }}
                                        />
                                    )}
                                />
                            )}
                            {salesAgent && (
                                <Controller
                                    name="agencyCommission"
                                    control={control}
                                    disabled={formDisabled}
                                    render={field => (
                                        <SlotInput
                                            {...field}
                                            label="Agent Commission"
                                            type="number"
                                            disabled={
                                                conjunctionalAgencyId != undefined ||
                                                !userData.permissions.includes('users.edit')
                                            }
                                            value={
                                                conjunctionalAgencyId
                                                    ? conjunctionalAgency
                                                          .map(agency => agency.agencyCommission ?? 0)
                                                          .orSome(0)
                                                    : field.field.value
                                            }
                                        />
                                    )}
                                />
                            )}
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                justifyContent: 'flex-end',
                                mt: 2,
                            }}
                        >
                            <Button
                                //size="sm"
                                variant="outlined"
                                color="neutral"
                                onClick={() => {
                                    navigate(`/${userData.orgId}/users`)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                //size="sm"
                                variant="solid"
                                type="submit"
                                loading={loading}
                                disabled={formDisabled}
                            >
                                Save
                            </Button>
                        </Stack>
                        {userData.userId == editUser?.userId && <ReiConnection />}
                    </Stack>
                </Box>
            </form>
            <EditAvatarModal
                image={editAvatarImage}
                onClose={() => setEditAvatarImage(null)}
                onSave={async avatarImage => {
                    if (editUser) {
                        await api.post(`/user/${editUser!.userId}/profileimage`, {
                            base64String: avatarImage,
                        })
                        await usersApi.refresh()
                        setForceRefreshProfileImage(forceRefreshProfileImage + 1)
                    } else {
                        setAvatarImage(avatarImage)
                    }
                    setEditAvatarImage(null)
                }}
            />
        </>
    )
}

const UserLedger = () => {
    const api = useApi()

    const { userId } = useParams<{ userId: string }>()
    const [_month, setMonth] = useSearchParamState<string>('month', format(new Date(), 'yyyy-MM-01'))
    const month = new Date(_month)

    const addMonth = (n: number) => () => {
        setMonth(format(addMonths(month, n), 'yyyy-MM-01'))
    }

    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                mt={1}
                mb={2}
            >
                <IconButton onClick={addMonth(-1)}>
                    <ArrowBackRounded />
                </IconButton>
                <Box
                    sx={{
                        width: '150px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography>{format(month, 'MMMM yyyy')}</Typography>
                </Box>
                <IconButton onClick={addMonth(1)}>
                    <ArrowForwardRounded />
                </IconButton>
            </Stack>
            <Ledger
                filter="user"
                userId={userId!}
                month={_month}
            />
        </>
    )
}

export default function editUser() {
    const usersApi = useUsersApi()
    const { user: userData } = useUserData()
    const { userId } = useParams<{ userId: string }>()

    const [activeTab, setActiveTab] = useSearchParamState<string>('tab', 'Details')

    if (!usersApi.data) {
        return <Loading />
    }

    const user = usersApi.data.find(user => user.userId == userId)!

    return (
        <>
            <SectionHead
                title={user ? `${user.firstName} ${user.lastName}` : 'New User'}
                breadcrumbs={['Users']}
            />
            <Tabs
                value={activeTab}
                onChange={(e, val) => setActiveTab(val as any)}
                sx={{ mx: 0 }}
            >
                {user != undefined && (
                    <TabList>
                        <Tab value="Details">Details</Tab>
                        {userData.permissions.includes('monthlyCharges.view') && (
                            <Tab value="Monthly Charges">Monthly Charges</Tab>
                        )}
                        <Tab value="Ledger">Ledger</Tab>
                    </TabList>
                )}
                <Box sx={{ py: 2 }}>
                    <Box
                        sx={{
                            display: activeTab == 'Details' ? undefined : 'none',
                        }}
                    >
                        <CoreEditUser user={user} />
                    </Box>
                    <Box
                        sx={{
                            display: activeTab == 'Monthly Charges' ? undefined : 'none',
                        }}
                    >
                        <MonthlyCharges user={user} />
                    </Box>
                    <Box
                        sx={{
                            display: activeTab == 'Ledger' ? undefined : 'none',
                        }}
                    >
                        <UserLedger />
                    </Box>
                </Box>
            </Tabs>
        </>
    )
}
