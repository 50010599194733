import { Button, DialogActions, DialogContent, DialogTitle, Modal, ModalDialog } from '@mui/joy'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useEffect } from 'react'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

const IframeModal = ({ title, url, onClose }) => {
    useEffect(() => {
        const handleLoad = event => {
            if (event.data === 'docusignClose') {
                onClose()
            }
        }
        window.addEventListener('message', handleLoad)
        return () => {
            window.removeEventListener('message', handleLoad)
        }
    }, [])

    return (
        <Modal
            open={url !== undefined}
            onClose={dontCloseOnBackgroundClick(onClose)}
        >
            <ModalDialog sx={{ width: '100%', height: '100%' }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <iframe
                        src={url}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}

export default IframeModal
