import { capitalize } from '@fastre/core/src/helperFunctions/string'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { Stack, useTheme } from '@mui/joy'
import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import Divider from '@mui/joy/Divider'
import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import List from '@mui/joy/List'
import ListDivider from '@mui/joy/ListDivider'
import ListItem from '@mui/joy/ListItem'
import ListItemContent from '@mui/joy/ListItemContent'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import Typography from '@mui/joy/Typography'
import { FrontendUserSchema, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { groupBy, prop, sortBy } from 'ramda'
import { Fragment } from 'react'
import { useNavigate } from 'react-router'

function RowMenu() {
    return (
        <Dropdown>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{
                    root: { variant: 'plain', color: 'neutral', size: 'sm' },
                }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu
                size="sm"
                sx={{ minWidth: 140 }}
            >
                <MenuItem>Edit</MenuItem>
                <MenuItem>Rename</MenuItem>
                <MenuItem>Move</MenuItem>
                <Divider />
                <MenuItem color="danger">Delete</MenuItem>
            </Menu>
        </Dropdown>
    )
}

const UserList = ({ users, sort }: { users: FrontendUserSchema[]; sort: 'first' | 'last' }) => {
    const { user: userData } = useUserData()
    const navigate = useNavigate()

    return (
        sortBy(user => user[`${sort}Name`].toLowerCase(), users)
            //.filter(user => userData.role.includes('admin') || user.userId === userData.userId)
            .map(user => (
                <List
                    key={user.userId}
                    size="sm"
                    sx={{
                        '--ListItem-paddingX': 0,
                    }}
                >
                    <ListItem
                        component="a"
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            cursor: 'pointer',
                            textDecoration: 'none',
                            ':hover': {
                                backgroundColor: 'var(--joy-palette-background-level2)',
                                borderRadius: 'sm',
                            },
                            px: 1,
                            pt: 1,
                        }}
                        //href={`users/${user.userId}`}
                        onClick={() => navigate(user.userId)}
                    >
                        <ListItemContent
                            sx={{
                                display: 'flex',
                                gap: 4,
                                alignItems: 'start',
                            }}
                        >
                            <ListItemDecorator>
                                <Avatar size="lg">
                                    {user.profileImage ? (
                                        <img
                                            src={user.profileImage}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    ) : (
                                        `${user.firstName[0]}${user.lastName[0]}`
                                    )}
                                </Avatar>
                            </ListItemDecorator>
                            <div>
                                <Typography
                                    fontWeight={600}
                                    gutterBottom
                                >
                                    {user.firstName} {user.lastName}
                                </Typography>
                                <Typography
                                    level="body-xs"
                                    gutterBottom
                                >
                                    {user.roleName}
                                </Typography>
                                {/*
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: 0.5,
                                    mb: 1,
                                }}
                            >
                                <Typography level="body-xs">{listItem.date}</Typography>
                                <Typography level="body-xs">&bull;</Typography>
                                <Typography level="body-xs">{listItem.id}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                <Link level="body-sm" component="button">
                                    Download
                                </Link>
                                <RowMenu />
                            </Box>
                            */}
                            </div>
                        </ListItemContent>
                        <Stack
                            direction="column"
                            gap={1}
                            alignItems="flex-end"
                        >
                            {/*groupsApi.maybeData
                            .map(groups =>
                                user.groupIds.map(groupId => {
                                    const group = groups.find(propEq('groupId', groupId))

                                    return (
                                        <Chip
                                            key={groupId}
                                            variant="soft"
                                            size="sm"
                                            color={(group?.color as any) ?? 'neutral'}
                                        >
                                            {group?.groupName ?? 'Not Found'}
                                        </Chip>
                                    )
                                }),
                            )
                        .orUndefined()*/}
                        </Stack>
                    </ListItem>
                    <ListDivider />
                </List>
            ))
    )
}

export default ({ sort }: { sort: 'first' | 'last' }) => {
    const theme = useTheme()
    const usersApi = useUsersApi()
    const { user: userData } = useUserData()

    if (usersApi.data === undefined) {
        return <Loading />
    }

    console.log(
        "userData.permissions.includes('users.view')",
        userData.permissions,
        userData.permissions.includes('users.view'),
    )

    return (
        <Box
            //sx={{ display: { xs: 'block', sm: 'none' } }}
            sx={{ maxWidth: theme.breakpoints.values.sm + 'px' }}
        >
            {userData.permissions.includes('users.view') ? (
                Object.entries(groupBy(prop('roleName'), usersApi.data)).map(([role, subUsers]) => (
                    <Fragment key={role}>
                        <Typography
                            level="h4"
                            sx={{ mt: 2, mb: 1 }}
                        >
                            {role.split(' ').map(capitalize).join(' ')}
                        </Typography>
                        <UserList
                            users={subUsers}
                            sort={sort}
                        />
                    </Fragment>
                ))
            ) : (
                <UserList
                    users={usersApi.data.filter(user => user.userId === userData.userId)}
                    sort={sort}
                />
            )}
        </Box>
    )
}
