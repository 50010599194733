import z from 'zod'

export const LedgerSchema = z.object({
    id: z.string(),
    userId: z.string(),
    listingId: z.string().optional(),
    listingName: z.string().optional(),
    debit: z.number().optional(),
    credit: z.number().optional(),
    description: z.string(),
    ledgerDate: z.string().date(),
    supplier: z.string(),
    invoiceNumber: z.string(),
    approved: z.boolean().default(false),
    disputeReason: z.string().optional(),
})

export type LedgerSchema = z.infer<typeof LedgerSchema>

export type InternalLedgerSchema = LedgerSchema & {
    orgId: string
    orgIdUserId: string
    monthlyCharge?: boolean
    nonEditable?: boolean
}

export const UserMonthlyLedgerEntrySchema = z.object({
    id: z.string(),
    supplier: z.string(),
    description: z.string(),
    type: z.enum(['debit', 'credit']),
    amount: z.number().optional(),
})

export const UserMonthlyLedgerSchema = z.object({
    ledger: z.array(UserMonthlyLedgerEntrySchema),
})

export type UserMonthlyLedgerEntrySchema = z.infer<typeof UserMonthlyLedgerEntrySchema>
export type UserMonthlyLedgerSchema = z.infer<typeof UserMonthlyLedgerSchema>
export type InternalUserMonthlyLedgerSchema = UserMonthlyLedgerSchema & { orgId: string; userId: string }

export interface InternalLedgerApprovalSchema {
    orgId: string
    userId: string
    orgIdUserId: string
    month: string // yyyy-MM
    approvalDate: number
    approvedBy: string
    xeroInvoice?: {
        id: string
        invoiceNumber: string
        url: string
    }
}
