import { InternalListingSchema } from '@fastre/core/src/schemas/listing'
import { Box, Link, Typography, useTheme } from '@mui/joy'
import { useUsersApi } from 'apiProviders'

export default ({ listing }: { listing: InternalListingSchema }) => {
    const usersApi = useUsersApi()
    const theme = useTheme()

    const Red = ({ children }) => (
        <Box
            component="span"
            sx={{ color: theme.palette.danger['500'] }}
        >
            <b>{children}</b>
        </Box>
    )

    const agent = listing.agentUserId
        ? usersApi.maybeData.map(users => users.find(user => user.userId == listing.agentUserId!)!).orUndefined()
        : undefined

    const timeOfDayGreeting =
        new Date().getHours() < 12 ? 'Good morning' : new Date().getHours() < 18 ? 'Good afternoon' : 'Good evening'

    return (
        <>
            <Typography>
                {timeOfDayGreeting},
                <br />
                <br />
                We would like to welcome you to RE/MAX Regency and look forward to being of service to you throughout
                the process of selling your home.
                <br />
                <br />
                Please find attached a copy of your Form 6 Listing Agreement with RE/MAX Regency., We have also attached
                our link below to '101 Tips for Presenting your Home to the Market' for your perusal.
                <br />
                <br />
                <img
                    style={{
                        width: '100%',
                    }}
                    src="https://newsroom.remax.com.au/wp-content/uploads/2020/07/Tips-for-Selling-your-Home-quickly-1024x300.png"
                />
                <Link>Top tips for selling your home</Link>
                <br />
                <br />
                Your Agent,{' '}
                <b>
                    {agent?.firstName} {agent?.lastName}
                </b>
                , is contactable on <b>{agent?.mobileNumber}</b> or email:{' '}
                <Link href={agent?.email}>
                    <b>{agent?.email}</b>
                </Link>
                .
                <br />
                <br />
                You may also call our office on (07) 5578 1111 with any questions or queries you may have.
                <br />
                <br />
                Yours Sincerly,
                <br />
                <br />
                <Red>Someone Something</Red>
                <br />
                Administrator
                <br />
                RE/MAX Regency
                <br />
                <br />
                P: 07 5555 2222
                <br />
                F: 07 5555 3333
                <br />
                <Link href="www.example.com.au">www.example.com.au</Link>
            </Typography>
        </>
    )
}
