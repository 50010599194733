import { Box, Button, CircularProgress, Divider, Typography, useColorScheme } from '@mui/joy'
import { useApi } from 'api'
import { UserData, useUserData } from 'auth'
import { useMaybeState } from 'helperFunctions/react'
import docusignLogoBlack from 'images/docusignLogo_black.svg'
import docusignLogoWhite from 'images/docusignLogo_white.svg'
import realworksLogo from 'images/realworks_logo.png'
import { Maybe } from 'monet'
import { prop } from 'ramda'
import { createContext, useContext, useEffect, useState } from 'react'
import { docusignConnectionUrl } from 'settings/docusignConnectionResponse'

interface ReiSession {
    agency: {
        id: number
        name: string
    }
    oauth_accounts: string[] //'docusign'
}

export const reiConnectionUrl = (user: UserData) => {
    const prod = import.meta.env.VITE_APP_STAGE == 'prod'

    const params = new URLSearchParams({
        response_type: 'token',
        client_id: import.meta.env.VITE_REI_CLIENT_ID,
        redirect_uri: `https://app${prod ? '' : '.uat'}.fastre.com.au/rei/auth`,
        state: user.orgId,
        only: user.state.toLowerCase(),
    }).toString()

    return `https://accounts.${prod ? '' : 'staging.'}reiformslive.com.au/oauth/authorize?${params}`
}

interface EnhancedReiSession extends ReiSession {
    docusignConnected: boolean
}

const ReiSessionContext = createContext<Maybe<EnhancedReiSession> | undefined>(undefined)
export const useReiSession = () => useContext(ReiSessionContext)

export const ReiSessionProvider = ({ children }: { children }) => {
    const api = useApi()
    const { user, refreshUser } = useUserData()

    const [maybeSession, setSession] = useMaybeState<ReiSession>()

    useEffect(() => {
        if (user.reiConnectionToken) {
            api.get('/rei/session').then(prop('data')).then(setSession)
        }
    }, [user.reiConnectionToken])

    return (
        <ReiSessionContext.Provider
            value={
                user.reiConnectionToken
                    ? maybeSession.map(session => ({
                          ...session,
                          docusignConnected: session.oauth_accounts.includes('docusign'),
                      }))
                    : undefined
            }
        >
            {children}
        </ReiSessionContext.Provider>
    )
}

const ReiConnection = () => {
    const { mode } = useColorScheme()
    const api = useApi()
    const { user, refreshUser } = useUserData()
    const reiSession = useReiSession()

    console.log('user', user)

    const [loading, setLoading] = useState(false)

    return (
        <Box>
            <Box sx={{ mb: 2, mt: 4 }}>
                <img src={realworksLogo} />
            </Box>
            {reiSession ? (
                reiSession
                    .map(session => (
                        <Box>
                            <Typography>Connected to {session.agency.name}</Typography>
                            <Typography>
                                DocuSign: {session.docusignConnected ? 'Connected' : 'Not Connected'}
                            </Typography>
                            <Button
                                loading={loading}
                                variant="outlined"
                                color="danger"
                                onClick={async () => {
                                    setLoading(true)
                                    await api.post('/rei/disconnect')
                                    await refreshUser()
                                    setLoading(false)
                                }}
                                sx={{
                                    mt: 2,
                                }}
                            >
                                Disconnect
                            </Button>
                        </Box>
                    ))
                    .orSome(<CircularProgress />)
            ) : (
                <>
                    <Typography>Not connected</Typography>
                    <Button
                        sx={{ mt: 1 }}
                        component="a"
                        href={reiConnectionUrl(user)}
                    >
                        Connect
                    </Button>
                </>
            )}
            {import.meta.env.DEV && (
                <>
                    <Divider sx={{ my: 4 }} />
                    <Box sx={{ width: '338px' }}>
                        <img src={mode == 'dark' ? docusignLogoWhite : docusignLogoBlack} />
                    </Box>
                    {user.docusignUserInfo ? (
                        <>
                            <Typography>
                                Connected to{' '}
                                {user.docusignUserInfo.accounts.find(x => x.is_default == true)!.account_name}
                            </Typography>
                            <Button
                                loading={loading}
                                variant="outlined"
                                color="danger"
                                onClick={async () => {
                                    setLoading(true)
                                    await api.post('/docusign/disconnect')
                                    await refreshUser()
                                    setLoading(false)
                                }}
                                sx={{
                                    mt: 2,
                                }}
                            >
                                Disconnect
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="outlined"
                            //href={docusignConnectionUrl(user)}
                            onClick={() => {
                                window.location.href = docusignConnectionUrl(user)
                            }}
                        >
                            Connect
                        </Button>
                    )}
                </>
            )}
        </Box>
    )
}

export default ReiConnection
