import { UserMonthlyLedgerEntrySchema } from '@fastre/core/src/schemas/ledger'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded, EditRounded } from '@mui/icons-material'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    IconButton,
    Modal,
    ModalDialog,
    Option,
    Select,
    Stack,
    Table,
} from '@mui/joy'
import { useApi } from 'api'
import { FrontendUserSchema } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { SlotInput } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useOrgId } from 'helpers'
import { assoc, prop } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

const EditRowModal = ({
    row,
    onClose,
}: {
    row: UserMonthlyLedgerEntrySchema
    onClose: (updatedRow?: UserMonthlyLedgerEntrySchema) => Promise<void>
}) => {
    const api = useApi()
    const { handleSubmit, register, setValue, formState, control } = useForm<UserMonthlyLedgerEntrySchema>({
        defaultValues: {
            ...row,
        },
        resolver: zodResolver(UserMonthlyLedgerEntrySchema),
    })

    console.log('row', row)

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    const [loading, setLoading] = useState(false)

    const onSubmit = async (data: UserMonthlyLedgerEntrySchema) => {
        setLoading(true)
        console.log(data)
        await onClose(data)
        setLoading(false)
    }

    return (
        <ModalDialog>
            <DialogTitle>Edit Ledger</DialogTitle>
            <DialogContent>
                <form noValidate>
                    <Stack spacing={2}>
                        <Controller
                            name="supplier"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Supplier"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="description"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Description"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="type"
                            control={control}
                            render={field => (
                                <FormControl>
                                    <FormLabel>Type</FormLabel>
                                    <Select
                                        {...field.field}
                                        onChange={(e, val) => setValue('type', val as any)}
                                    >
                                        <Option value="credit">Credit</Option>
                                        <Option value="debit">Debit</Option>
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="amount"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Amount"
                                    type="dollar"
                                    {...field}
                                />
                            )}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </ModalDialog>
    )
}

export default ({ user }: { user: FrontendUserSchema }) => {
    const orgId = useOrgId()!
    const api = useApi()
    const { user: userData } = useUserData()

    const [rows, setRows] = useState<UserMonthlyLedgerEntrySchema[]>()
    const [editRow, setEditRow] = useState<UserMonthlyLedgerEntrySchema>()
    const [deleteLoading, setDeleteLoading] = useState<Record<string, boolean>>({})

    const refreshRows = async () => {
        api.get(`/user/${user.userId}/monthlycharges`).then(({ data }) => {
            setRows(data.ledger)
        })
    }

    useEffect(() => {
        refreshRows()
    }, [])

    if (!rows) return <Loading />

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>Supplier</th>
                        <th>Description</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {rows.map(row => (
                        <tr key={row.id}>
                            <td>{row.supplier}</td>
                            <td>{row.description}</td>
                            <td>{row.type == 'debit' ? '$' + row.amount : ''}</td>
                            <td>{row.type == 'credit' ? '$' + row.amount : ''}</td>
                            <td>
                                <IconButton
                                    size="sm"
                                    onClick={() => setEditRow(row)}
                                >
                                    <EditRounded />
                                </IconButton>
                                <IconButton
                                    size="sm"
                                    sx={{ ml: 1 }}
                                    loading={deleteLoading[row.id]}
                                    onClick={async () => {
                                        setDeleteLoading(assoc(row.id, true))
                                        const newRows = rows.filter(r => r.id !== row.id)
                                        await api.post(`/user/${user.userId}/monthlycharges`, {
                                            ledger: newRows,
                                        })
                                        setDeleteLoading(assoc(row.id, false))
                                        setRows(newRows)
                                    }}
                                >
                                    <DeleteRounded />
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {userData.permissions.includes('monthlyCharges.edit') && (
                <Button
                    variant="soft"
                    sx={{ mt: 2 }}
                    onClick={() =>
                        setEditRow({
                            id: uuid(),
                            orgId,
                            userId: user.userId,
                        } as any)
                    }
                >
                    Add Row
                </Button>
            )}
            <Modal
                open={!!editRow}
                onClose={dontCloseOnBackgroundClick(() => setEditRow(undefined))}
            >
                <EditRowModal
                    row={editRow!}
                    onClose={async updatedRow => {
                        if (updatedRow) {
                            const isNewRow = !rows.find(row => row.id === updatedRow.id)
                            const newRows = isNewRow
                                ? [...rows, updatedRow]
                                : rows.map(row => (row.id === updatedRow.id ? updatedRow : row))
                            await api
                                .post(`/user/${user.userId}/monthlycharges`, { ledger: newRows })
                                .then(prop('data'))
                            setRows(newRows)
                        }
                        setEditRow(undefined)
                    }}
                />
            </Modal>
        </>
    )
}
