import {
    closestCorners,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useDroppable,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { SharedTodoSchema, SingleTodoSchema } from '@fastre/core/src/schemas/todo'
import { AddRounded, CloseRounded, DragHandleRounded, MoreVertRounded } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionGroup,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Divider,
    Dropdown,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    Stack,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useSharedTodosApi, useTodoApi } from 'apiProviders'
import { useWebSocket } from 'apiProviders/websocketProvider'
import { useUserData } from 'auth'
import Input from 'components/input'
import Loading from 'components/Loading'
import SectionHead from 'components/sectionHead'
import { useFormatNotificationTime } from 'notificationTime'
import { dissoc, filter, prop, sortBy } from 'ramda'
import React, { createRef, forwardRef, useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { useDebounce } from 'use-debounce'
import { v4 as uuid } from 'uuid'
import CreateSharedTodoModal from './createSharedTodoModal'
import DeleteSharedTodoModal from './deleteSharedTodoModal'

const duration = 300
const height = '48px'

const defaultStyle = {
    transition: `height ${duration}ms`,
    height: 0,
    overflow: 'hidden',
}

const transitionStyles = {
    entering: { height },
    entered: { height },
    exiting: { height: 0 },
    exited: { height: 0 },
}

const TodoInner = forwardRef(({ todo, setTodos, children, sx }: any, ref: any) => {
    const { user } = useUserData()
    const formatNotificationTime = useFormatNotificationTime()

    const complete = todo.completedAt != undefined

    return (
        <Stack
            ref={ref}
            direction="row"
            spacing={2}
            sx={sx}
        >
            {children}
            <Checkbox
                checked={todo.completedAt != undefined}
                onChange={e => {
                    setTodos(x =>
                        x!.map(y =>
                            y.id === todo.id
                                ? {
                                      ...y,
                                      completedAt: e.target.checked ? Date.now() : undefined,
                                      completedBy: `${user.firstName} ${user.lastName}`,
                                  }
                                : y,
                        ),
                    )
                }}
            />
            <Input
                value={todo.todoDescription}
                onChange={value =>
                    setTodos(x => x!.map(y => (y.id === todo.id ? { ...y, todoDescription: value } : y)))
                }
                variant="plain"
                autoFocus
                sx={
                    todo.completedAt != undefined
                        ? {
                              textDecoration: 'line-through',
                          }
                        : undefined
                }
                formControlProps={{
                    sx: { flex: 1 },
                }}
                fullWidth
            />
            <Typography
                sx={{
                    minWidth: '120px',
                    opacity: 0.7,
                }}
            >
                {formatNotificationTime(todo.createdAt)}
            </Typography>
            <Tooltip
                title="Delete"
                placement="right"
            >
                <IconButton
                    sx={{
                        opacity: 0,
                        transition: 'opacity 0.5s',
                    }}
                    onClick={() => setTodos(todos => todos.filter(x => x.id != todo.id))}
                >
                    <CloseRounded />
                </IconButton>
            </Tooltip>
        </Stack>
    )
})

const TodoItem = ({ todo, setTodos, nodeRef, isIn, children }) => {
    return (
        <Transition
            key={todo.id}
            nodeRef={nodeRef}
            in={isIn}
            timeout={duration}
            unmountOnExit
        >
            {state => (
                <TodoInner
                    ref={nodeRef}
                    todo={todo}
                    setTodos={setTodos}
                    sx={{
                        alignItems: 'center',
                        '&:hover': {
                            '& > button': {
                                opacity: 1,
                            },
                        },
                        ...defaultStyle,
                        ...transitionStyles[state],
                        flexGrow: 1,
                    }}
                >
                    {children}
                </TodoInner>
            )}
        </Transition>
    )
}

const SortableTodoItem = forwardRef(({ todo, setTodos, nodeRef, isIn }: any, ref: any) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: todo.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <Box
            ref={ref}
            sx={style}
        >
            <TodoItem
                todo={todo}
                setTodos={setTodos}
                nodeRef={nodeRef}
                isIn={isIn}
            >
                {false && (
                    <Box
                        {...listeners}
                        {...attributes}
                        ref={setNodeRef}
                        sx={{
                            cursor: 'grab',
                        }}
                    >
                        <DragHandleRounded />
                    </Box>
                )}
            </TodoItem>
        </Box>
    )
})

interface TodoItemsProps {
    todos: SingleTodoSchema[]
    setTodos: React.Dispatch<React.SetStateAction<SingleTodoSchema[]>>
    filter: (todo: SingleTodoSchema) => boolean
}

const TodoItems = forwardRef(({ todos, setTodos, filter }: TodoItemsProps, ref: any) => {
    const [nodeRefs, setNodeRefs] = useState<any>(
        todos.map(({ id }) => ({
            [id]: createRef(),
        })),
    )
    useEffect(() => {
        setNodeRefs(
            todos.map(({ id }) => ({
                [id]: nodeRefs[id] ?? createRef(),
            })),
        )
    }, [todos.map(prop('id')).join('')])

    return (
        <Stack ref={ref}>
            {sortBy(x => -(x.completedAt ?? 0), todos).map(todo => (
                <TodoItem
                    key={todo.id}
                    todo={todo}
                    setTodos={setTodos}
                    nodeRef={nodeRefs[todo.id]}
                    isIn={filter(todo)}
                    children={undefined}
                />
            ))}
        </Stack>
    )
})

const SortableTodoItems = forwardRef(({ listId, todos, setTodos, filter }: any, ref: any) => {
    const [nodeRefs, setNodeRefs] = useState<any>(
        todos.map(({ id }) => ({
            [id]: createRef(),
        })),
    )
    useEffect(() => {
        setNodeRefs(
            todos.map(({ id }) => ({
                [id]: nodeRefs[id] ?? createRef(),
            })),
        )
    }, [todos.map(prop('id')).join('')])

    const { setNodeRef, isOver, over } = useDroppable({
        id: listId,
    })

    return (
        <SortableContext
            id={listId}
            items={todos}
            strategy={verticalListSortingStrategy}
        >
            <Stack ref={ref}>
                {todos.map((todo, i) => (
                    <SortableTodoItem
                        ref={setNodeRef}
                        key={todo.id}
                        todo={todo}
                        setTodos={setTodos}
                        nodeRef={nodeRefs[todo.id]}
                        isIn={filter(todo)}
                    />
                ))}
            </Stack>
        </SortableContext>
    )
})

const Todos = (props: {
    listId: string
    todos: SingleTodoSchema[]
    setTodos: React.Dispatch<React.SetStateAction<SingleTodoSchema[]>>
}) => {
    return (
        <>
            <SortableTodoItems
                listId={props.listId}
                todos={props.todos}
                filter={todo => todo.completedAt == undefined}
                setTodos={props.setTodos}
            />
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: 'center',
                    mt: 2,
                }}
            >
                <AddRounded fontSize={'lg' as any} />
                <Input
                    value={''}
                    onChange={value => {
                        if (value) {
                            props.setTodos(x => [
                                ...x!,
                                {
                                    id: uuid(),
                                    todoDescription: value,
                                    createdAt: Date.now(),
                                },
                            ])
                        }
                    }}
                    formControlProps={{
                        sx: { flex: 1 },
                    }}
                    variant="plain"
                    fullWidth
                />
                <Box sx={{ width: '120px' }} />
                <IconButton sx={{ visibility: 'hidden' }}>
                    <CloseRounded />
                </IconButton>
            </Stack>

            <Divider sx={{ mt: 4 }} />
            <AccordionGroup size="lg">
                <Accordion>
                    <AccordionSummary>Completed</AccordionSummary>
                    <AccordionDetails>
                        <TodoItems
                            todos={props.todos}
                            filter={todo => todo.completedAt != undefined}
                            setTodos={props.setTodos}
                        />
                    </AccordionDetails>
                </Accordion>
            </AccordionGroup>
        </>
    )
}

const SharedTodo = ({
    name,
    shareTodo,
    setTodos,
    editSharedTodo,
}: {
    name?: string
    shareTodo: SharedTodoSchema
    setTodos
    editSharedTodo
}) => {
    const { user } = useUserData()

    const [showDelete, setshowDelete] = useState(false)

    return (
        shareTodo && (
            <>
                <Box sx={{ mt: 6 }}>
                    <Stack
                        direction="row"
                        gap={2}
                        sx={{
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography level="title-lg">{name}</Typography>
                        {user.permissions.includes('todos.manage') && (
                            <Dropdown>
                                <MenuButton
                                    size="sm"
                                    slots={{ root: IconButton }}
                                >
                                    <MoreVertRounded fontSize="small" />
                                </MenuButton>
                                <Menu>
                                    <MenuItem onClick={() => editSharedTodo(shareTodo)}>Edit</MenuItem>
                                    <MenuItem onClick={() => setshowDelete(true)}>Delete</MenuItem>
                                </Menu>
                            </Dropdown>
                        )}
                    </Stack>
                    <Todos
                        listId={shareTodo.id}
                        todos={shareTodo.todos}
                        setTodos={setTodos}
                    />
                </Box>
                <DeleteSharedTodoModal
                    sharedTodo={showDelete ? shareTodo : undefined}
                    onClose={() => setshowDelete(false)}
                />
            </>
        )
    )
}

export default () => {
    const api = useApi()
    const { user } = useUserData()
    const todoApi = useTodoApi()
    const [todos, setTodos] = useState<SingleTodoSchema[]>()
    const wsMessage = useWebSocket('sharedTodo') as (SharedTodoSchema & { fromUserId?: string }) | undefined
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )
    const [showType, setShowType] = useState<'mine' | 'all'>('mine')
    const sharedTodosApi = useSharedTodosApi(true)

    const [createSharedModal, setCreateSharedModal] = useState<SharedTodoSchema | null | undefined>(null)
    const [activeDrag, setActiveDrag] = useState<{
        id: string
        containerId: string
    }>()

    const [sharedTodos, setSharedTodos] = useState<{ [id: string]: SharedTodoSchema }>({})

    const genericSetTodos = (id: string) => (todos: SingleTodoSchema[] | any) => {
        console.log('setting shared tods', id, todos, typeof todos)

        if (id == 'myTodos') {
            setTodos(todos)
        } else {
            setSharedTodos(x => ({
                ...x,
                [id]: {
                    ...x[id],
                    todos: typeof todos == 'function' ? todos(x[id].todos) : todos,
                },
            }))
        }
    }

    useEffect(() => {
        if (sharedTodosApi.data && Object.keys(sharedTodos).length == 0) {
            console.log('setting shared todos from data useEffect')
            setSharedTodos(sharedTodosApi.data.reduce((acc, x) => ({ ...acc, [x.id]: x }), {}))
        }
    }, [sharedTodosApi.data != undefined])

    useEffect(() => {
        if (wsMessage) {
            console.log('wsMessage', wsMessage)

            if (sharedTodos && wsMessage.fromUserId != user.userId) {
                setSharedTodos(x => ({ ...x, [wsMessage.id]: dissoc('fromUserId', wsMessage) }))
            }
        }
    }, [wsMessage])

    const [debouncedSharedTodos] = useDebounce(JSON.stringify(sharedTodos), 2000)

    useEffect(() => {
        Promise.all(
            Object.values(sharedTodos).map(async sharedTodo => {
                const currentTodos = sharedTodosApi.data!.find(x => x.id == sharedTodo.id)!
                const newTodos = sharedTodo.todos

                if (JSON.stringify(currentTodos.todos) == JSON.stringify(newTodos)) {
                    return false
                }

                const currentTodosById = currentTodos.todos.reduce(
                    (acc, todo) => ({ ...acc, [todo.id]: todo }),
                    {},
                )

                const deleted = currentTodos.todos.map(prop('id')).filter(x => !newTodos.find(y => y.id == x))
                // TODO fix sorting shared todos
                const updated = newTodos //.filter(todo => !equals(todo, currentTodosById[todo.id]))

                console.log('SAVING SHARED', sharedTodo.name)

                await api.post(`/sharedtodo/${sharedTodo.id}/update`, { updated, deleted })
                return true
            }),
        )
            .then(filter(x => x == true))
            .then(refreshing => {
                if (refreshing.length > 0) {
                    sharedTodosApi.refresh()
                }
            })
    }, [debouncedSharedTodos, activeDrag])

    const [placeholder, setPlaceholder] = useState<{ containerId: 'myTodos' | string; index: number }>()

    const [debouncedTodos] = useDebounce(JSON.stringify(todos), 2000)

    useEffect(() => {
        if (todoApi.data) {
            setTodos(todoApi.data.todos ?? [])
        }
    }, [todoApi.data != undefined])

    useEffect(() => {
        console.log('check saving', {
            notUndef: todos != undefined,
            notdragging: !activeDrag,
            diff: JSON.stringify(todoApi.data?.todos ?? []) != debouncedTodos,
        })

        if (
            todos != undefined &&
            !activeDrag &&
            JSON.stringify(todoApi.data?.todos ?? []) != debouncedTodos
        ) {
            api.post('/todo/set', { todos }).then(todoApi.refresh)
        }
    }, [activeDrag, debouncedTodos])

    if (!todos) {
        return <Loading />
    }

    function handleDragStart(event) {
        const { active } = event
        const { id } = active

        setActiveDrag({
            id,
            containerId: active.data.current.sortable.containerId,
        })
    }

    function handleDragOver(event) {
        const { active, over, draggingRect } = event

        const activeContainer = active.data.current?.sortable?.containerId
        const overContainer = over.data.current?.sortable?.containerId

        const relevantTodos = overContainer == 'myTodos' ? todos! : sharedTodos[overContainer]!.todos
        const overIndex = relevantTodos.findIndex(x => x.id == over.id)

        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return
        }

        const activeList = activeContainer == 'myTodos' ? todos! : sharedTodos[activeContainer]!.todos

        const todo = activeList.find(x => x.id == active.id)

        if (!todo) {
            console.log('todo not found')
            return
        }

        // remove item from original list
        genericSetTodos(activeContainer)(todos!.filter(x => x.id != active.id))
        // add item to new list
        genericSetTodos(overContainer)([
            ...relevantTodos.slice(0, overIndex),
            todo,
            ...relevantTodos.slice(overIndex),
        ])
    }

    function handleDragEnd(event) {
        const { active, over } = event

        setActiveDrag(undefined)
        setPlaceholder(undefined)

        const activeContainer = active.data.current?.sortable?.containerId
        const overContainer = over.data.current?.sortable?.containerId

        if (!activeContainer || !overContainer) {
            return
        }

        const sourceTodos = activeContainer == 'myTodos' ? todos! : sharedTodos[activeContainer]!.todos
        const targetTodos = overContainer == 'myTodos' ? todos! : sharedTodos[overContainer]!.todos

        const sourceIndex = sourceTodos.findIndex(x => x.id == active.id)
        const targetIndex = targetTodos.findIndex(x => x.id == over.id)

        console.log({ sourceIndex, targetIndex })

        if (sourceIndex == -1) {
            return
        }

        if (activeContainer == overContainer) {
            // Reorder within the same list
            const newTodos = [...sourceTodos]
            const [removed] = newTodos.splice(sourceIndex, 1)
            newTodos.splice(targetIndex, 0, removed)

            console.log(
                'original',
                sourceTodos.map(x => x.todoDescription),
            )
            console.log(
                'new',
                newTodos.map(x => x.todoDescription),
            )

            genericSetTodos(activeContainer)(newTodos)
        } else {
            const todo = sourceTodos[sourceIndex]

            // insert todo into targetTodos at targetIndex
            const newTargetTodos = [
                ...targetTodos.slice(0, targetIndex),
                todo,
                ...targetTodos.slice(targetIndex),
            ]
            // remove todo from source
            const newSourceTodos = sourceTodos.filter(x => x.id != active.id)

            genericSetTodos(activeContainer)(newSourceTodos)
            genericSetTodos(overContainer)(newTargetTodos)
        }
    }

    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                },
            }}
        >
            <SectionHead
                title="To Do"
                buttons={
                    user.permissions.includes('todos.manage') && (
                        <Box>
                            <Typography>Show:</Typography>
                            <ToggleButtonGroup
                                value={showType}
                                onChange={(event, newValue) => {
                                    setShowType(newValue ?? 'mine')
                                }}
                            >
                                <Button value="mine">Mine</Button>
                                <Button value="all">All</Button>
                            </ToggleButtonGroup>
                        </Box>
                    )
                }
            />
            <Box sx={{ maxWidth: '700px' }}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCorners}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                >
                    <Todos
                        listId="myTodos"
                        todos={todos}
                        setTodos={setTodos as any}
                    />
                    {Object.values(sharedTodos)
                        .filter(todoList => showType == 'all' || todoList.userIds?.includes(user.userId))
                        .map(todosList => (
                            <Box key={todosList.id}>
                                <Divider />
                                <SharedTodo
                                    name={todosList.name}
                                    shareTodo={todosList}
                                    setTodos={genericSetTodos(todosList.id)}
                                    editSharedTodo={setCreateSharedModal}
                                />
                            </Box>
                        ))}
                    <DragOverlay>
                        {activeDrag && activeDrag.containerId && (
                            <TodoInner
                                todo={[...todos!, ...Object.values(sharedTodos).flatMap(x => x.todos)].find(
                                    x => x.id == activeDrag.id,
                                )}
                                setTodos={() => {}}
                                /*sx={{
                                    alignItems: 'center',
                                    '&:hover': {
                                        '& > button': {
                                            opacity: 1,
                                        },
                                    },
                                    ...defaultStyle,
                                    ...transitionStyles.entered,
                                    flexGrow: 1,
                                }}*/
                            />
                        )}
                    </DragOverlay>
                </DndContext>
            </Box>

            <Button
                variant="soft"
                sx={{ mt: 6 }}
                onClick={() => setCreateSharedModal(undefined)}
            >
                Create Shared Todo List
            </Button>

            <CreateSharedTodoModal
                open={createSharedModal !== null}
                sharedTodo={createSharedModal ?? undefined}
                onClose={() => setCreateSharedModal(null)}
            />
        </Box>
    )
}
