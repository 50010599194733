import { OcrComparisonResult } from '@fastre/core/src/schemas/ocr'
import { ArrowBackRounded, ArrowForwardRounded, DownloadRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Modal,
    ModalClose,
    ModalDialog,
    Sheet,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/joy'
import { saveAs } from 'file-saver'
import { createRef, useMemo, useState } from 'react'
import { Document, Page } from 'react-pdf'
import Input from './input'
import { dontCloseOnBackgroundClick } from './modal'

interface Props {
    pdf?: string
    title: string
    onClose: () => void
    fileName: string
    ocrResult?: {
        [pageIndex: number]: OcrComparisonResult[]
    }
    hideDownloadButton?: boolean
}

const PdfModal = ({ pdf, title, onClose, fileName, ocrResult, hideDownloadButton }: Props) => {
    const theme = useTheme()

    const [numPages, setNumPages] = useState<number>()
    const [pageNumber, setPageNumber] = useState(1)
    const pageRef = createRef<HTMLDivElement>()

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages)
        setPageNumber(1)
    }

    const token = localStorage.getItem('token')

    const padding = 0.03

    const options = useMemo(
        () => ({
            withCredentials: true,
            httpHeaders: {
                Authorization: `Bearer ${token}`,
            },
        }),
        [token],
    )

    return (
        <Modal
            open={pdf != undefined}
            onClose={dontCloseOnBackgroundClick(onClose)}
        >
            <ModalDialog
                sx={{
                    overflow: 'auto',
                }}
            >
                <ModalClose sx={{ zIndex: 3 }} />
                <DialogTitle>{title}</DialogTitle>
                <DialogContent
                    sx={{
                        width: '810px',
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        {pdf && (
                            <Box
                                sx={{
                                    height: '1010px',
                                    position: 'relative',
                                }}
                            >
                                <Document
                                    file={pdf}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    options={options}
                                >
                                    <Box ref={pageRef}>
                                        <Page
                                            pageNumber={pageNumber}
                                            scale={1.33}
                                            //width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                        />
                                    </Box>
                                </Document>
                                {ocrResult?.[pageNumber] &&
                                    ocrResult[pageNumber].map(
                                        ({ name, success, expectedValue, boundingBox }) => (
                                            <Tooltip
                                                key={name}
                                                title={expectedValue}
                                            >
                                                <Sheet
                                                    key={name}
                                                    color={success ? 'success' : 'danger'}
                                                    variant="soft"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: boundingBox.top - padding + 'in',
                                                        height: boundingBox.height + padding * 2 + 'in',
                                                        left: boundingBox.left - padding + 'in',
                                                        width: boundingBox.width + padding * 2 + 'in',
                                                        zIndex: 3,
                                                        opacity: 0.3,
                                                        //margin: '-2px'
                                                    }}
                                                ></Sheet>
                                            </Tooltip>
                                        ),
                                    )}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ position: 'relative' }}>
                    <Stack
                        direction="row"
                        gap={2}
                        alignItems="center"
                        sx={{ margin: 'auto' }}
                    >
                        <IconButton
                            onClick={() => setPageNumber(pageNumber - 1)}
                            disabled={pageNumber <= 1}
                        >
                            <ArrowBackRounded />
                        </IconButton>
                        <Stack
                            direction="row"
                            gap={1}
                            alignItems="center"
                        >
                            <Typography sx={{ userSelect: 'none' }}>Page</Typography>
                            <Input
                                type="number"
                                value={pageNumber}
                                onChange={x => setPageNumber(Math.min(x as any, numPages ?? 1))}
                                sx={{ width: '50px' }}
                                onFocus={e => e.target.select()}
                            />
                            <Typography sx={{ userSelect: 'none' }}>of {numPages}</Typography>
                        </Stack>
                        <IconButton
                            onClick={() => setPageNumber(pageNumber + 1)}
                            disabled={pageNumber >= (numPages ?? 1)}
                        >
                            <ArrowForwardRounded />
                        </IconButton>
                    </Stack>
                    {!hideDownloadButton && (
                        <Button
                            variant="outlined"
                            sx={{
                                position: 'absolute',
                                right: 0,
                                [theme.breakpoints.down('sm')]: {
                                    display: 'none',
                                },
                            }}
                            onClick={() => saveAs(pdf, fileName)}
                            startDecorator={<DownloadRounded />}
                        >
                            Download
                        </Button>
                    )}
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}

export default PdfModal
