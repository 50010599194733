import { capitalize } from '@fastre/core/src/helperFunctions/string'
import { MoreVert } from '@mui/icons-material'
import { Button, Dropdown, IconButton, Menu, MenuButton, MenuItem, Stack, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useListingType } from 'apiProviders'
import { useShowSnack } from 'components/snackbar'
import { assoc, prop } from 'ramda'
import { useState } from 'react'
import { useListingContext } from './listingProvider'

export const RelloMarketingPayment = ({ saveListing }: { saveListing: () => Promise<boolean> }) => {
    const api = useApi()
    const { listing, setListing } = useListingContext()
    const listingType = useListingType()
    const showSnack = useShowSnack()

    const [loading, setLoading] = useState(false)

    const { paymentStatus } = listing.marketingPaymentStatus ?? {}

    return (
        <Stack
            direction="row"
            gap={2}
            sx={{ alignItems: 'center' }}
        >
            {listing.marketingPaymentStatus && (
                <>
                    <Typography>Rello status: {capitalize(paymentStatus ?? '')}</Typography>
                    <Dropdown>
                        <MenuButton
                            slots={{ root: IconButton }}
                            slotProps={{ root: { size: 'sm' } }}
                            disabled={
                                paymentStatus == 'completed' ||
                                paymentStatus == 'submitted' ||
                                paymentStatus == 'active' ||
                                paymentStatus == 'cancelled'
                            }
                            loading={loading}
                        >
                            <MoreVert fontSize="small" />
                        </MenuButton>
                        <Menu>
                            <MenuItem
                                onClick={async () => {
                                    setLoading(true)

                                    const saveResult = await saveListing()
                                    console.log('saveResult', saveResult)
                                    if (!saveResult) {
                                        setLoading(false)
                                        return
                                    }

                                    try {
                                        const relloData = await api
                                            .post(
                                                `/listing/${listingType}/${listing.listingId}/marketingpayment/cancel`,
                                            )
                                            .then(prop('data'))
                                        setListing(assoc('marketingPaymentStatus', relloData))
                                        showSnack('Payment request cancelled', 'success')
                                    } catch (e) {
                                        console.error(e)
                                        showSnack('Error cancelling payment request', 'danger')
                                    } finally {
                                        setLoading(false)
                                    }
                                }}
                            >
                                Cancel payment request
                            </MenuItem>
                        </Menu>
                    </Dropdown>
                </>
            )}
            {(listing.marketingPaymentStatus == undefined || paymentStatus == 'cancelled') && (
                <Button
                    loading={loading}
                    variant="soft"
                    onClick={async () => {
                        setLoading(true)
                        try {
                            const saveResult = await saveListing()
                            console.log('saveResult', saveResult)
                            if (!saveResult) {
                                setLoading(false)
                                return
                            }

                            const marketingPaymentStatus = await api
                                .post(`/listing/${listingType}/${listing.listingId}/marketingpayment/send`)
                                .then(prop('data'))
                            setListing(assoc('marketingPaymentStatus', marketingPaymentStatus))
                            showSnack('Marketing payment request sent', 'success')
                        } catch (e) {
                            console.error(e)
                            showSnack('Error sending marketing payment request', 'danger')
                        } finally {
                            setLoading(false)
                        }
                    }}
                    disabled={
                        listing.vendors == undefined ||
                        listing.vendors.length == 0 ||
                        listing.marketingPackage == undefined ||
                        listing.hiddenPrice == undefined ||
                        listing.marketingPackage.packageItems.reduce(
                            (acc, item) => acc + item.itemPrice,
                            0,
                        ) <= 0 ||
                        (listing.agentAppointmentForm?.formStatus != 'completed' &&
                            listing.agentAppointmentForm?.formStatus != 'manual upload')
                    }
                >
                    Send Rello Payment request to Vendor
                </Button>
            )}
        </Stack>
    )
}

//rello prod eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2N2UxNTY1Y2IzOGZhNzkzMDM3ODg3NSIsIm1lcmNoYW50SWQiOiI2NTc3OGFiNWUzMmVkOWRkMjhhZmUwZWYiLCJpYXQiOjE3MTk1MzkwNDV9.uk69DJdnNZchF0dTLhH8jiYDrXJ3ywfetHo_SCCci14

export default RelloMarketingPayment
