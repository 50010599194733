import { useDraggable, useDroppable } from '@dnd-kit/core'
import { AddRounded, KeyboardArrowDownRounded } from '@mui/icons-material'
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemContent,
    Typography,
    useTheme,
} from '@mui/joy'
import { useListingFilesApi } from 'apiProviders'
import Input from 'components/input'
import { Toggler } from 'components/sidebar'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useUploadFiles } from './context'
import GenericItemRender from './genericItemRender'
import RenderIndexLines from './renderIndexLines'

const FolderRender = ({ file, setFile, index, parentDragging, formDisabled }) => {
    const theme = useTheme()
    const filesApi = useListingFilesApi()
    const uploadFiles = useUploadFiles()

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragActive } = useDropzone({
        noClick: true,
        noDragEventsBubbling: true,
        disabled: formDisabled,
    })

    const { setNodeRef: droppableRef, isOver } = useDroppable({
        id: file.Key,
        disabled: parentDragging || formDisabled,
    })

    const {
        attributes,
        listeners,
        setNodeRef: draggableRef,
        transform,
        isDragging,
    } = useDraggable({
        id: file.Key,
        disabled: file.loading || file.name == '',
    })

    const [addingFolder, setAddingFolder] = useState<string>()

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            uploadFiles(acceptedFiles, file.name.split('/'))
        }
    }, [acceptedFiles])

    const addFolder = async () => {
        if (addingFolder?.trim()) {
            const newFile = new File([], addingFolder.trim() + '/', {
                type: 'application/x-directory',
            })
            setAddingFolder(undefined)
            uploadFiles(
                [newFile],
                file.name.split('/').filter(x => x != ''),
            )
        }
    }

    return (
        <Box
            {...getRootProps({ className: 'dropzone' })}
            sx={
                isDragging
                    ? {
                          opacity: 0.7,
                      }
                    : {}
            }
        >
            <Box ref={droppableRef}>
                <ListItem
                    ref={draggableRef}
                    {...attributes}
                    {...listeners}
                    nested
                    sx={{
                        position: 'relative',
                        backgroundColor: isOver || isDragActive ? theme.palette.background.level2 : undefined,
                    }}
                >
                    <Toggler
                        defaultExpanded
                        renderToggle={({ open, setOpen }) => (
                            <ListItemButton
                                sx={{
                                    ':hover': {
                                        '& #deleteButton': {
                                            opacity: 1,
                                        },
                                        '& #newFolderButton': {
                                            opacity: 1,
                                        },
                                    },
                                }}
                                onClick={() => !addingFolder && setOpen(!open)}
                            >
                                <RenderIndexLines index={index} />
                                <KeyboardArrowDownRounded
                                    sx={{
                                        transform: open ? 'rotate(180deg)' : 'none',
                                    }}
                                />
                                <ListItemContent sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography level="title-sm">
                                        {file.name == '' ? 'Files' : file.name.split('/').pop()}
                                    </Typography>
                                    {!formDisabled && (
                                        <IconButton
                                            id="newFolderButton"
                                            size="sm"
                                            sx={{
                                                opacity: 0,
                                                transition: 'opacity 0.2s',
                                            }}
                                            onClick={e => {
                                                e.stopPropagation()
                                                setAddingFolder('')
                                                setOpen(true)
                                            }}
                                        >
                                            <AddRounded />
                                        </IconButton>
                                    )}
                                </ListItemContent>
                            </ListItemButton>
                        )}
                    >
                        <List sx={{ gap: 0.5 }}>
                            {addingFolder !== undefined && (
                                <ListItem>
                                    <ListItemButton>
                                        <ListItemContent>
                                            <Input
                                                value={addingFolder}
                                                onChange={setAddingFolder}
                                                placeholder="Folder Name"
                                                autoFocus
                                                onKeyDown={async e => {
                                                    if (e.key === 'Escape') {
                                                        setAddingFolder(undefined)
                                                    }
                                                    if (e.key === 'Enter' && addingFolder.trim()) {
                                                        addFolder()
                                                    }
                                                }}
                                                onBlur={() => {
                                                    addFolder()
                                                }}
                                            />
                                        </ListItemContent>
                                    </ListItemButton>
                                </ListItem>
                            )}
                            {filesApi
                                .data!.filter(folderFile => {
                                    const lastIndex = folderFile.name.lastIndexOf('/')
                                    const compareKey = folderFile.name.substring(0, lastIndex)

                                    /*console.log({
                                        folderFile,
                                        compareKey,
                                        file: file.name,
                                    })*/

                                    return compareKey == file.name
                                })
                                .map(file => (
                                    <GenericItemRender
                                        key={file.Key}
                                        file={file}
                                        setFile={setFile}
                                        index={index + 1}
                                        parentDragging={isDragging || parentDragging}
                                        formDisabled={formDisabled}
                                    />
                                ))}
                        </List>
                    </Toggler>
                </ListItem>
                <input {...getInputProps()} />
            </Box>
        </Box>
    )
}

export default FolderRender
