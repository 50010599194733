import { Maybe } from 'monet'
import { difference, groupBy } from 'ramda'

const anyArray = Array as any
/*
anyArray.prototype.removeAt = function <T extends {}>(index: number) {
    var newArr = this as Array<T>
    const cloned = newArr.slice()
    cloned.splice(index, 1)
    return cloned
}

anyArray.prototype.unique = function <T extends {}>() {
    const list = this as Array<T>
    return uniq(list)
}

anyArray.prototype.findMaybe = function <T extends {}>(findFunc: (val: T) => boolean) {
    const list = this as Array<T>
    const val = list.find(findFunc)
    return Maybe.fromNull(val)
}

anyArray.prototype.findDefinately = function <T extends {}>(findFunc: (val: T) => boolean) {
    const list = this as Array<T>
    const val = list.find(findFunc)
    return val
}

anyArray.prototype.except = function <T extends {}>(item: T) {
    const list = this as Array<T>
    return difference(list, [item])
}

anyArray.prototype.isEmpty = function () {
    return this.length == 0
}
anyArray.prototype.any = function () {
    return this.length > 0
}
anyArray.prototype.last = function () {
    return this.isEmpty() ? Maybe.none() : Maybe.some(this[this.length - 1])
}
anyArray.prototype.first = function () {
    return this.isEmpty() ? Maybe.none() : Maybe.some(this[0])
}
*/
export const arrayOfLength = (length: number) => Array.apply(null, Array(length)).map(() => ({}))

export const except = <T>(item: T, list: T[]): T[] => {
    return difference(list, [item])
}

export const remove = function <T>(elem: any, list: T[]) {
    //this = list.filter(e => e !== elem);
    var index = list.indexOf(elem)
    list.splice(index, 1)
}

export const isEmpty = function <T>(list: T[]) {
    return list.length === 0
}

export const any = (list: any[]) => {
    return list.length > 0
}

export const findMaybe = <T>(expression, list: T[]) => {
    const val = list.find(expression)
    return Maybe.fromNull(val)
}

export const findDefinately =
    <T>(expression) =>
    (list: T[]) => {
        const val = list.find(expression)
        return val as T
    }

export function insertIf<T>(condition: boolean, element: T): T[] {
    return condition ? [element] : ([] as T[])
}

export function insertArrayIf<T>(condition: boolean, elements: T[]): T[] {
    return condition ? elements : []
}

export function insertMaybe<T extends {}>(maybeValue: Maybe<T>): T[] {
    return maybeValue.map(x => [x]).orSome([])
}

export function groupBySingleObjects<T>(groupFunc: (item: T) => string, arr: T[]) {
    const groupedArr = groupBy(groupFunc, arr)
    return Object.fromEntries(Object.entries(groupedArr).map(([key, val]: any) => [key, val[0]]))
}

export function hasValue<T>(item: T | undefined | null): item is T {
    return !!item
}

export function reduceToObject<T>(prev: { [k: string]: T }, curr: { [k: string]: T }) {
    return { ...prev, ...curr }
}

export const chunkArray = <T>(array: T[], chunkSize: number): T[][] =>
    arrayOfLength(Math.ceil(array.length / chunkSize)).map((_, i) => array.slice(chunkSize * i, chunkSize * (i + 1)))
