import FileRender from './fileRender'
import FolderRender from './folderRender'

export default ({ file, setFile, index, parentDragging, formDisabled }) => {
    const Render = file.isFolder ? FolderRender : FileRender

    return (
        <Render
            file={file}
            setFile={setFile}
            index={index}
            parentDragging={parentDragging}
            formDisabled={formDisabled}
        />
    )
}
