import { formatAddress } from '@fastre/core/src/schemas/generic'
import { TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { Box, useTheme } from '@mui/joy'
import { AdvancedMarker, Map, Pin, useMap } from '@vis.gl/react-google-maps'
import { useListingType, useListingsApi } from 'apiProviders'
import SectionHead from 'components/sectionHead'
import { useOrgId } from 'helpers'
import { map } from 'ramda'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { listingStatusToColor } from './helpers'

export const CoreMapView = () => {
    const theme = useTheme()
    const listingsApi = useListingsApi()
    const type = useListingType()
    const navigate = useNavigate()
    const orgId = useOrgId()
    const mapInstance = useMap()

    const withLoc = listingsApi.maybeData.map(listings => listings.filter(listing => listing.geoLocation[0] != 0))

    useEffect(() => {
        if (mapInstance && withLoc.map(x => x.length > 0).orSome(false)) {
            var bounds = new google.maps.LatLngBounds()
            withLoc
                .some()
                .forEach(({ geoLocation }) => bounds.extend(new google.maps.LatLng(geoLocation[0], geoLocation[1])))
            mapInstance.fitBounds(bounds)

            if (withLoc.some().length == 1) {
                mapInstance.setZoom(17)
            }
        }
    }, [
        mapInstance,
        withLoc
            .map(listings => listings.map(({ geoLocation }) => `${geoLocation[0]},${geoLocation[1]}`).join(''))
            .orSome(''),
    ])

    //console.log('withLoc', withLoc)

    return (
        <Box sx={{ width: '100%', height: '100%', borderRadius: 'sm', overflow: 'hidden' }}>
            <Map
                mapId={'1'}
                defaultCenter={{ lat: -28.0484317, lng: 153.4214467 }}
                defaultZoom={11}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
            >
                {withLoc.map(
                    map((listing: TypesenseListingSchema) => (
                        <AdvancedMarker
                            key={listing.listingId}
                            //position={{lat: 10, lng: 10}}
                            position={{
                                lat: listing.geoLocation[0],
                                lng: listing.geoLocation[1],
                            }}
                            clickable={true}
                            onClick={() => navigate(`/${orgId}/listings/${type}/${listing.listingId}`)}
                            title={`${formatAddress(listing.listingAddress)}\n${listing.userNames}`}
                        >
                            <Pin
                                background={theme.palette[listingStatusToColor(listing.listingStatus)][400]}
                                borderColor={theme.palette[listingStatusToColor(listing.listingStatus)][400]}
                                glyphColor={theme.palette[listingStatusToColor(listing.listingStatus)][500]}
                            />
                        </AdvancedMarker>
                    )),
                )}
            </Map>
        </Box>
    )
}

export default () => {
    const type = useListingType()

    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                },
            }}
        >
            <SectionHead title={`${type == 'bdm' ? 'BDM ' : ''}Listings Map`} />
            <Box
                sx={{
                    width: '100%',
                    flex: '1 1 auto',
                }}
            >
                <CoreMapView />
            </Box>
        </Box>
    )
}
