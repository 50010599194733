import colors from '@fastre/core/src/colors'
import { capitalize } from '@fastre/core/src/helperFunctions/string'
import {
    InternalListingValueConfigSchema,
    ListingValueConfigSchema,
    showInTabOptions,
} from '@fastre/core/src/schemas/listingConfig'
import { zodResolver } from '@hookform/resolvers/zod'
import { Add, Delete, Done, Save } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Radio,
    RadioGroup,
    Select,
    Sheet,
    Stack,
    Typography,
    radioClasses,
    useTheme,
} from '@mui/joy'
import { useApi } from 'api'
import { useAllListingConfigApi, useListingConfigApi, useRolesApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { SortableList } from 'components/sortableList'
import { useMaybeState } from 'helperFunctions/react'
import { useState } from 'react'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'

interface ValueConfigModalProps {
    valueConfig: ListingValueConfigSchema | InternalListingValueConfigSchema
    close: () => void
}

const ValueConfigModal = ({ valueConfig, close }: ValueConfigModalProps) => {
    const showSnack = useShowSnack()
    const api = useApi()
    const listingConfigApi = useListingConfigApi()
    const rolesApi = useRolesApi()
    const { user } = useUserData()

    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    const { register, control, handleSubmit, formState, getValues, setValue, watch } =
        useForm<InternalListingValueConfigSchema>({
            defaultValues: valueConfig,
            resolver: zodResolver(ListingValueConfigSchema),
        })

    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const isNew = (valueConfig as InternalListingValueConfigSchema).id == undefined

    const {
        fields: configValues,
        append: addConfigValue,
        remove: removeConfigValues,
    } = useFieldArray({
        control,
        name: 'configValues',
    })

    const onSubmit: SubmitHandler<InternalListingValueConfigSchema> = async data => {
        setLoading(true)
        try {
            if (isNew) {
                await api.post('listingvalueconfig/create', data)
            } else {
                await api.post(
                    `listingvalueconfig/${(valueConfig as InternalListingValueConfigSchema).id}/update`,
                    data,
                )
            }
            await listingConfigApi.refresh()
            close()
        } catch (e) {
            console.error(e)
            showSnack('Error saving listing config', 'danger')
        } finally {
            setLoading(false)
        }
    }

    const visibleTo = watch('visibleTo')

    console.log('visibleTo', visibleTo)

    return (
        <>
            <ModalDialog
                sx={{
                    width: '800px',
                }}
            >
                <ModalClose />
                <DialogTitle>{isNew ? 'Create' : 'Edit'} Listing Value</DialogTitle>
                <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <DialogContent>
                        <Stack
                            direction="row"
                            gap={2}
                        >
                            <Controller
                                control={control}
                                name="configName"
                                render={field => (
                                    <SlotInput
                                        label="Config Name"
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="showInTab"
                                render={field => (
                                    <SlotWrapper
                                        {...field}
                                        label="Show In Tab"
                                    >
                                        <Select
                                            {...field.field}
                                            onChange={(e, value) => field.field.onChange(value)}
                                        >
                                            {showInTabOptions.map(option => (
                                                <Option
                                                    key={option}
                                                    value={option}
                                                >
                                                    {option.split(' ').map(capitalize).join(' ')}
                                                </Option>
                                            ))}
                                        </Select>
                                    </SlotWrapper>
                                )}
                            />
                        </Stack>
                        <Box sx={{ my: 2 }}>
                            <Controller
                                control={control}
                                name="visibleTo"
                                render={field => (
                                    <SlotWrapper
                                        {...field}
                                        label="Available to Roles"
                                    >
                                        <Select
                                            multiple
                                            onChange={(e, value) => setValue('visibleTo', value as any)}
                                            disabled={false}
                                            value={visibleTo ?? []}
                                            placeholder="All"
                                            onListboxOpenChange={setOpen}
                                            listboxOpen={open}
                                        >
                                            <Option
                                                value=""
                                                onClick={e => {
                                                    e.preventDefault()
                                                    setValue('visibleTo', undefined)
                                                    setOpen(false)
                                                }}
                                            >
                                                <em>All</em>
                                            </Option>
                                            {rolesApi.maybeData.orSome([]).map(role => (
                                                <Option
                                                    key={role.id}
                                                    value={role.id}
                                                >
                                                    {role.roleName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </SlotWrapper>
                                )}
                            />
                        </Box>
                        <Typography sx={{ mb: 1 }}>Value Options</Typography>
                        {configValues.map((configValue, i) => (
                            <Stack
                                key={configValue.id}
                                direction="row"
                                gap={2}
                            >
                                <Controller
                                    control={control}
                                    name={`configValues.${i}.value`}
                                    render={field => (
                                        <SlotInput
                                            label="Value"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name={`configValues.${i}.color`}
                                    render={field => (
                                        <SlotWrapper
                                            {...field}
                                            label="Colour"
                                        >
                                            <RadioGroup
                                                aria-labelledby="product-color-attribute"
                                                defaultValue="warning"
                                                sx={{
                                                    gap: 2,
                                                    flexWrap: 'wrap',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                {colors.map(color => (
                                                    <Sheet
                                                        key={color}
                                                        sx={{
                                                            position: 'relative',
                                                            width: 20,
                                                            height: 20,
                                                            flexShrink: 0,
                                                            bgcolor: `${color}.solidBg`,
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            //backgroundColor: color,
                                                            color: color,
                                                        }}
                                                    >
                                                        <Radio
                                                            overlay
                                                            variant="solid"
                                                            color={color}
                                                            checkedIcon={
                                                                <Done
                                                                    sx={{
                                                                        height: '14px',
                                                                        width: '14px',
                                                                    }}
                                                                />
                                                            }
                                                            value={color}
                                                            slotProps={{
                                                                input: {
                                                                    'aria-label': color,
                                                                },
                                                                radio: {
                                                                    sx: {
                                                                        display: 'contents',
                                                                        '--variant-borderWidth': '2px',
                                                                    },
                                                                },
                                                            }}
                                                            sx={{
                                                                '--joy-focus-outlineOffset': '4px',
                                                                /*'--joy-palette-focusVisible': (theme) =>
                                                                    theme.vars.palette[color][500],*/
                                                                [`& .${radioClasses.action}.${radioClasses.focusVisible}`]:
                                                                    {
                                                                        outlineWidth: '2px',
                                                                    },
                                                                //backgroundColor: color,
                                                            }}
                                                        />
                                                    </Sheet>
                                                ))}
                                            </RadioGroup>
                                        </SlotWrapper>
                                    )}
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box>
                                        <IconButton onClick={() => removeConfigValues(i)}>
                                            <Delete />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Stack>
                        ))}
                        <Box
                            sx={{
                                mt: 2,
                            }}
                        >
                            <Button
                                startDecorator={<Add fontSize="small" />}
                                onClick={() =>
                                    addConfigValue({
                                        value: '',
                                        color: 'primary',
                                    })
                                }
                                variant="soft"
                                sx={{ mb: 2 }}
                            >
                                Add Option
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            startDecorator={<Save />}
                            type="submit"
                            loading={loading}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={close}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        {!isNew && user.permissions.includes('listings.delete') && (
                            <Button
                                variant="outlined"
                                color="danger"
                                startDecorator={<Delete />}
                                sx={{ mr: 'auto' }}
                                onClick={() => setDeleting(true)}
                            >
                                Delete
                            </Button>
                        )}
                    </DialogActions>
                </form>
            </ModalDialog>
            <Modal
                open={deleting}
                onClose={dontCloseOnBackgroundClick(() => setDeleting(false))}
            >
                <ModalDialog>
                    <DialogContent>Are you sure you want to delete this listing setting?</DialogContent>
                    <DialogActions>
                        <Button
                            color="danger"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true)
                                try {
                                    await api.post(
                                        `/listingvalueconfig/${(valueConfig as InternalListingValueConfigSchema).id}/delete`,
                                    )
                                    await listingConfigApi.refresh()
                                    close()
                                } catch (error) {
                                    console.error(error)
                                }
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setDeleting(false)}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    )
}

export default () => {
    const api = useApi()
    const listingConfigApi = useAllListingConfigApi()
    const theme = useTheme()
    const [editConfig, setEditConfig] = useMaybeState<
        ListingValueConfigSchema | InternalListingValueConfigSchema
    >()
    const [loading, setLoading] = useState(false)

    if (listingConfigApi.loading || loading) {
        return <Loading />
    }

    const configs = listingConfigApi.maybeData.some()

    return (
        <>
            <SortableList
                items={configs}
                key="id"
                onChange={async newOrder => {
                    setLoading(true)

                    await Promise.all(
                        newOrder.map((newConfig, i) => {
                            if (newConfig.configOrder !== i) {
                                return api.post(`/listingvalueconfig/${newConfig.id}/update`, {
                                    ...newConfig,
                                    configOrder: i,
                                })
                            }
                        }),
                    )

                    await listingConfigApi.refresh()
                    setLoading(false)
                }}
                renderItem={config => (
                    <SortableList.Item
                        key={config.id}
                        id={config.id}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                my: 2,
                            }}
                        >
                            <Box>
                                <SortableList.DragHandle />
                            </Box>
                            <Sheet
                                sx={{
                                    p: 2,
                                    background: theme.palette.background.level1,
                                    ':hover': {
                                        cursor: 'pointer',
                                        background: theme.palette.background.level2,
                                    },
                                    borderRadius: 'sm',
                                }}
                                onClick={() => setEditConfig(config)}
                            >
                                <Typography>{config.configName}</Typography>
                            </Sheet>
                        </Box>
                    </SortableList.Item>
                )}
            />
            <Button
                startDecorator={<Add fontSize="small" />}
                onClick={() =>
                    setEditConfig({
                        configName: '',
                        configValues: [
                            {
                                value: 'Option 1',
                                color: colors[0],
                            },
                            {
                                value: 'Option 2',
                                color: colors[1],
                            },
                        ],
                        configOrder: configs.length,
                        showInTab: 'listing',
                    })
                }
                variant="soft"
                sx={{ mt: 2 }}
            >
                Add Listing Value
            </Button>
            <Modal
                open={editConfig.isSome()}
                onClose={dontCloseOnBackgroundClick(() => setEditConfig(undefined))}
            >
                {editConfig
                    .map(valueConfig => (
                        <ValueConfigModal
                            valueConfig={valueConfig}
                            close={() => setEditConfig(undefined)}
                        />
                    ))
                    .orSome(<></>)}
            </Modal>
        </>
    )
}
