import { formatAddress } from '@fastre/core/src/schemas/generic'
import {
    InternalLedgerApprovalSchema,
    InternalLedgerSchema,
    LedgerSchema,
} from '@fastre/core/src/schemas/ledger'
import { TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded, DoneRounded, EditRounded, ErrorRounded } from '@mui/icons-material'
import {
    Autocomplete,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Modal,
    ModalDialog,
    Stack,
    Table,
    Tooltip,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useConjunctionalAgenciesApi, useFindUserFromId, useListingsApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import Input, { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { format } from 'date-fns'
import { prop, sum } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'
import { v4 as uuid } from 'uuid'
import { useListingContext } from './listingProvider'

interface ListingAutocompleteProps {
    value?: string
    onChange: (value?: TypesenseListingSchema) => void
    displayValue?: string
    disabled?: boolean
}

const ListingAutocomplete = ({ value, onChange, displayValue, disabled }: ListingAutocompleteProps) => {
    const api = useApi()

    const [loadingListings, setLoadingListings] = useState(false)
    const [listingOptions, setListingOptions] = useState<TypesenseListingSchema[]>([])
    const [listingSearch, setListingSearch] = useState<string>('')
    const [debouncedListingSearch] = useDebounce(listingSearch, 200)

    useEffect(() => {
        setLoadingListings(true)
        const controller = new AbortController()
        api.post(
            '/listings/sale/search',
            {
                search: debouncedListingSearch,
                limit: 10,
            },
            {
                signal: controller.signal,
            },
        ).then(({ data }) => {
            setListingOptions(data.hits.map(prop('document')))
            setLoadingListings(false)
        })

        return () => controller.abort()
    }, [debouncedListingSearch])

    return (
        <Autocomplete
            value={displayValue ?? null}
            options={(listingOptions ?? []) as Array<TypesenseListingSchema | string>}
            loading={loadingListings}
            getOptionLabel={option =>
                typeof option === 'string' ? option : formatAddress(option.listingAddress)
            }
            onChange={(e, value) => {
                /*setValue('listingId', value)
                setValue(
                    'listingName',
                    value ? formatAddress(value.listingAddress) : undefined,
                )*/
                onChange((value as any) ?? undefined)
            }}
            onChangeCapture={async (e: any) => setListingSearch(e.target.value)}
            onBlur={() => setListingSearch('')}
            disabled={disabled}
        />
    )
}

const EditRowModal = ({
    row,
    onClose,
}: {
    row: InternalLedgerSchema
    onClose: (updated: boolean) => void
}) => {
    const api = useApi()
    const listingContext = useListingContext()
    const listingsApi = useListingsApi()
    const { handleSubmit, watch, setValue, formState, control } = useForm<LedgerSchema>({
        defaultValues: {
            listingId: listingContext?.listing.listingId,
            listingName: listingContext ? formatAddress(listingContext.listing.listingAddress) : undefined,
            ...row,
        },
        resolver: zodResolver(LedgerSchema),
    })

    const listingName = watch('listingName')

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    const [loading, setLoading] = useState(false)

    const onSubmit = async (data: LedgerSchema) => {
        setLoading(true)
        await api.post('/ledger/create', [data])
        setLoading(false)
        onClose(true)
    }

    return (
        <ModalDialog>
            <DialogTitle>Edit Ledger</DialogTitle>
            <DialogContent sx={{ minWidth: '350px' }}>
                <form noValidate>
                    <Stack spacing={2}>
                        <Controller
                            name="ledgerDate"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Date"
                                    type="date"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="supplier"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Supplier"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="invoiceNumber"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Invoice Number"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="description"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Description"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="listingId"
                            control={control}
                            render={field => (
                                <SlotWrapper
                                    label="Listing"
                                    {...field}
                                >
                                    <ListingAutocomplete
                                        value={field.field.value}
                                        displayValue={listingName}
                                        onChange={value => {
                                            setValue('listingId', value?.listingId)
                                            setValue(
                                                'listingName',
                                                value ? formatAddress(value.listingAddress) : undefined,
                                            )
                                        }}
                                    />
                                </SlotWrapper>
                            )}
                        />
                        <Controller
                            name="debit"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Debit"
                                    type="dollar"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="credit"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Credit"
                                    type="dollar"
                                    {...field}
                                />
                            )}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => onClose(false)}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    color="danger"
                    loading={loading}
                    startDecorator={<DeleteRounded />}
                    onClick={async () => {
                        setLoading(true)
                        await api.delete(`/ledger/${row.id}`)
                        setLoading(false)
                        onClose(true)
                    }}
                    sx={{ mr: 'auto' }}
                >
                    Delete
                </Button>
            </DialogActions>
        </ModalDialog>
    )
}

interface LedgerProps {
    filter: 'listing' | 'user'
    listingId?: string
    userId: string
    month?: string
}

export default ({ filter, listingId, userId, month }: LedgerProps) => {
    const api = useApi()
    const { user: userData } = useUserData()
    const conjunctionalAgenciesApi = useConjunctionalAgenciesApi()
    const findUserFromId = useFindUserFromId()
    const user = findUserFromId(userId).orUndefined()

    const conjunctional = conjunctionalAgenciesApi.data?.find(
        x => x.conjunctionalAgencyId == user?.conjunctionalAgencyId,
    )

    const [rows, setRows] = useState<InternalLedgerSchema[]>()
    const [approved, setApproved] = useState<InternalLedgerApprovalSchema>()
    const [editRow, setEditRow] = useState<InternalLedgerSchema>()
    const [approvalLoading, setApprovalLoading] = useState(false)

    const [showDisputeDialog, setShowDisputeDialog] = useState<string>()
    const [disputeReason, setDisputeReason] = useState<string>('')
    const [disputLoading, setDisputeLoading] = useState(false)
    const [createInvoiceLoading, setCreateInvoiceLoading] = useState(false)

    const refreshRows = async () => {
        api.post('/ledger/list', { filter, listingId, userId, month }).then(({ data }) => {
            setRows(data.items)
            setApproved(data.ledgerApproval)
        })
    }

    useEffect(() => {
        refreshRows()
    }, [month, listingId, userId])

    if (!rows) return <Loading />

    const balance = sum(rows.map(row => (row.credit ?? 0) - (row.debit ?? 0)))

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Supplier</th>
                        <th>Invoice Number</th>
                        <th>Description</th>
                        <th>Listing</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th style={{ width: '48px' }} />
                        <th style={{ width: '54px' }} />
                    </tr>
                </thead>
                <tbody>
                    {rows.map(row => (
                        <tr key={row.id}>
                            <td>{!row.monthlyCharge && row.ledgerDate}</td>
                            <td>{row.supplier}</td>
                            <td>{row.invoiceNumber}</td>
                            <td>{row.description}</td>
                            <td>{row.listingName}</td>
                            <td>
                                {row.debit && '$'}
                                {row.debit}
                            </td>
                            <td>
                                {row.credit && '$'}
                                {row.credit}
                            </td>
                            <td>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    {userData.userId == userId && !approved && !row.disputeReason && (
                                        <IconButton
                                            size="sm"
                                            onClick={() => {
                                                setDisputeReason('')
                                                setShowDisputeDialog(row.id)
                                            }}
                                        >
                                            <ErrorRounded />
                                        </IconButton>
                                    )}
                                    {row.disputeReason && (
                                        <Tooltip title={row.disputeReason}>
                                            <ErrorRounded
                                                sx={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                                color={'danger' as any}
                                            />
                                        </Tooltip>
                                    )}
                                </Box>
                            </td>
                            <td>
                                {userData.permissions.includes('ledger.edit') &&
                                    (!row.approved || userData.superUser) && (
                                        <IconButton
                                            size="sm"
                                            onClick={() => setEditRow(row)}
                                        >
                                            <EditRounded />
                                        </IconButton>
                                    )}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td
                            colSpan={5}
                            align="right"
                        >
                            <b>Balance</b>
                        </td>
                        <td>
                            <b>{balance <= 0 && `$${-balance}`}</b>
                        </td>
                        <td>{balance > 0 && `$${balance}`}</td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <Stack
                direction="row"
                sx={{ mt: 2 }}
            >
                {userData.permissions.includes('ledger.edit') && (!approved || userData.superUser) && (
                    <Button
                        variant="soft"
                        onClick={() =>
                            setEditRow({
                                id: uuid(),
                                listingId,
                                userId,
                            } as any)
                        }
                    >
                        Add Row
                    </Button>
                )}
                {userId == userData.userId && !approved && (
                    <Button
                        sx={{ marginLeft: 'auto' }}
                        loading={approvalLoading}
                        onClick={async () => {
                            setApprovalLoading(true)
                            await api.post('/ledger/approve', { userId, month: month! })
                            setApprovalLoading(false)
                        }}
                    >
                        Approve
                    </Button>
                )}
                {approved && (
                    <Box
                        sx={{
                            display: 'flex',
                            ml: 'auto',
                            alignItems: 'center',
                        }}
                    >
                        <DoneRounded
                            fontSize="large"
                            color="success"
                            sx={{
                                mr: 1,
                                width: '20px',
                                height: '20px',
                            }}
                        />
                        <Typography>Approved</Typography>
                    </Box>
                )}
            </Stack>
            {userData.xeroConnected && !approvalLoading && user?.conjunctionalAgencyId != undefined && (
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'right',
                    }}
                >
                    {approved?.xeroInvoice ? (
                        <Typography>
                            Invoiced:{' '}
                            <Link
                                target="_blank"
                                href={approved.xeroInvoice.url}
                            >
                                {approved.xeroInvoice.invoiceNumber}
                            </Link>
                        </Typography>
                    ) : (
                        <Button
                            loading={createInvoiceLoading}
                            onClick={async () => {
                                setCreateInvoiceLoading(true)
                                await api.post('/xero/createledgerinvoice', {
                                    userId,
                                    month: format(month!, 'yyyy-MM-01'),
                                })
                                await refreshRows()
                                setCreateInvoiceLoading(false)
                            }}
                            disabled={approved == undefined || conjunctional?.xero != undefined}
                        >
                            Create Invoice
                        </Button>
                    )}
                </Box>
            )}
            <Modal
                open={!!editRow}
                onClose={dontCloseOnBackgroundClick(() => setEditRow(undefined))}
            >
                <EditRowModal
                    row={editRow!}
                    onClose={updated => {
                        setEditRow(undefined)
                        if (updated) {
                            refreshRows()
                        }
                    }}
                />
            </Modal>
            <Modal
                open={!!showDisputeDialog}
                onClose={dontCloseOnBackgroundClick(() => setShowDisputeDialog(undefined))}
            >
                <ModalDialog>
                    <DialogTitle>Dispute</DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <Typography>Please provide a reason for disputing this ledger entry.</Typography>
                            <Input
                                label="Reason"
                                value={disputeReason}
                                onChange={setDisputeReason}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="danger"
                            startDecorator={<ErrorRounded />}
                            loading={disputLoading}
                            onClick={async () => {
                                setDisputeLoading(true)
                                await api.post(`/ledger/${showDisputeDialog}/dispute`, {
                                    disputeReason,
                                })
                                setDisputeLoading(false)
                                setShowDisputeDialog(undefined)
                            }}
                        >
                            Dispute
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setShowDisputeDialog(undefined)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    )
}
