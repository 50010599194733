import { DocusignForm } from '@fastre/core/src/schemas/docusign'
import { formatAddress } from '@fastre/core/src/schemas/generic'
import { UpdateSaleListingSchema } from '@fastre/core/src/schemas/saleListing'
import { Add, Check, MoveToInbox, Send, Visibility } from '@mui/icons-material'
import { Box, Button, Link, Stack, Step, StepIndicator, Stepper, Tooltip, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useListingType, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import PdfModal from 'components/pdfModal'
import { useShowSnack } from 'components/snackbar'
import { useIsMobile } from 'helperFunctions/pageSize'
import { prop } from 'ramda'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { docusignConnectionUrl } from 'settings/docusignConnectionResponse'
import { useReiSession } from 'users/reiConnection'
import { useListingContext } from '../listingProvider'
import IframeModal from './iframeModal'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString()

const OptionalTooltip = ({ title, show, children }) =>
    show ? <Tooltip title={title}>{children}</Tooltip> : children

enum FormStage {
    'none' = 0,
    'created',
    'sent',
    'completed',
}

const getFormStage = (form: DocusignForm | undefined): FormStage => {
    if (form == undefined) {
        return FormStage.none
    }

    switch (form.status) {
        case 'completed':
            return FormStage.completed
        case 'sent':
            return FormStage.sent
        case 'created':
            return FormStage.created
        default:
            return FormStage.none
    }
}

interface Props {
    title: string
    form?: DocusignForm
    createDisabled: boolean
    validate: () => Promise<boolean>
    preCreate: () => Promise<boolean>
    roleNames: string[]
}

const DocusignFormStepper = ({ title, form, createDisabled, validate, preCreate, roleNames }: Props) => {
    const { setValue } = useFormContext<UpdateSaleListingSchema>()
    const { listing, setListing, refreshListing } = useListingContext()
    const api = useApi()
    const { user } = useUserData()
    const listingType = useListingType()
    const usersApi = useUsersApi()
    const reiSession = useReiSession()
    const showSnack = useShowSnack()
    const isMobile = useIsMobile()

    const [viewLoading, setViewLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [previewPdf, setPreviewPdf] = useState<string>()
    const [editUrl, setEditUrl] = useState<string>()

    const formStage = getFormStage(form)

    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Typography level="h4">{title}</Typography>
                {user.docusignUserInfo != undefined && (
                    <>
                        {/*<Dropdown>
                            <MenuButton
                                slots={{ root: IconButton }}
                                slotProps={{ root: { size: 'sm' } }}
                            >
                                <MoreVert fontSize="small" />
                            </MenuButton>
                            <Menu>
                                {formStage != FormStage.none && (
                                    <MenuItem onClick={() => setShowAbandonFormDialog(formType)}>
                                        <ListItemDecorator>
                                            <DeleteRounded />
                                        </ListItemDecorator>
                                        Abandon Form
                                    </MenuItem>
                                )}
                            </Menu>
                        </Dropdown>*/}
                        <Box sx={{ flexGrow: 1 }} />
                        {formStage == FormStage.none && (
                            <Box>
                                <Button
                                    loading={loading}
                                    onClick={async () => {
                                        /*setLoading(true)
                                        const pass = await preCreate()
                                        if (pass) {
                                            const { listing: updatedListing, url } = await api
                                                .post(
                                                    `listing/${listingType}/${listing.listingId}/rei/${formType.toLocaleLowerCase()}/create`,
                                                )
                                                .then(prop('data'))
                                            setListing(updatedListing)
                                            window.open(url, '_blank')
                                        }
                                        //console.log('PASS', pass)
                                        setLoading(false)*/

                                        if (await validate()) {
                                            setLoading(true)
                                            const pass = await preCreate()
                                            if (pass) {
                                                await api
                                                    .post(
                                                        `/listing/sale/${listing.listingId}/funddisbursement/create`,
                                                    )
                                                    .then(prop('data'))
                                                await refreshListing()
                                            }
                                            setLoading(false)
                                        }
                                    }}
                                    startDecorator={<Add />}
                                    disabled={createDisabled}
                                >
                                    Create Form
                                </Button>
                            </Box>
                        )}
                        {formStage !== FormStage.none && (
                            <Box>
                                <Button
                                    variant="outlined"
                                    loading={viewLoading}
                                    onClick={async () => {
                                        if (form?.status == 'created') {
                                            setViewLoading(true)
                                            const { url } = await api
                                                .post(
                                                    `/listing/sale/${listing.listingId}/funddisbursement/edit`,
                                                    {
                                                        returnUrl: `${window.location.origin}/docusign/close`,
                                                    },
                                                )
                                                .then(prop('data'))

                                            setEditUrl(url)
                                            setViewLoading(false)

                                            /*const { url } = await api
                                                .get(
                                                    `/docusign/${listing.fundDisbursementForm?.envelopeId}/recipientPreview?returnUrl=${window.location.href}`,
                                                )
                                                .then(prop('data'))

                                            setEditUrl(url)*/
                                        } else {
                                            setPreviewPdf(
                                                `${import.meta.env.VITE_APP_API_URL}/${'1'}/docusign/${listing.fundDisbursementForm?.envelopeId}/view`,
                                            )
                                        }
                                    }}
                                    startDecorator={<Visibility />}
                                    disabled={reiSession == undefined}
                                >
                                    View Form
                                </Button>
                            </Box>
                        )}
                        {formStage == FormStage.created && (
                            <OptionalTooltip
                                show={
                                    reiSession != undefined &&
                                    reiSession.map(x => !x.docusignConnected).orSome(false)
                                }
                                title="Connect your DocuSign account to Realworks in order to send forms"
                            >
                                <Box>
                                    <Button
                                        loading={loading || (reiSession != undefined && reiSession.isNone())}
                                        onClick={async () => {
                                            setLoading(true)
                                            try {
                                                await api.post(
                                                    `/listing/sale/${listing.listingId}/funddisbursement/send`,
                                                )
                                                await refreshListing()
                                                showSnack('Fund disbursement form sent', 'success')
                                            } catch (e) {
                                                console.error(e)
                                                showSnack('Error sending fund disbursement form', 'danger')
                                            } finally {
                                                setLoading(false)
                                            }
                                        }}
                                        startDecorator={<Send />}
                                    >
                                        Send Form
                                    </Button>
                                </Box>
                            </OptionalTooltip>
                        )}
                    </>
                )}
            </Box>
            {user.docusignUserInfo == undefined && (
                <Typography sx={{ mt: 2 }}>
                    <Link href={docusignConnectionUrl(user)}>Connect</Link> your account to DocuSign to
                    create, send and view documents
                </Typography>
            )}
            {user.reiConnectionToken != undefined && (
                <>
                    <Stepper
                        orientation={isMobile ? 'vertical' : 'horizontal'}
                        sx={{ width: '100%', mt: 2 }}
                    >
                        {/* Create */}
                        <Step
                            indicator={
                                <StepIndicator
                                    variant={FormStage.created <= formStage ? 'solid' : 'soft'}
                                    color={FormStage.created <= formStage + 1 ? 'primary' : 'neutral'}
                                >
                                    {formStage != FormStage.none ? <Check /> : undefined}
                                </StepIndicator>
                            }
                            sx={{
                                '&::after': {
                                    ...(FormStage.created <= formStage && {
                                        //stage !== FormStage.complete &&
                                        bgcolor: 'primary.solidBg',
                                    }),
                                },
                            }}
                        >
                            <Typography>Create</Typography>
                        </Step>

                        {/* Send */}
                        <Step
                            indicator={
                                <StepIndicator
                                    variant={FormStage.sent <= formStage ? 'solid' : 'soft'}
                                    color={FormStage.sent <= formStage + 1 ? 'primary' : 'neutral'}
                                >
                                    {formStage >= FormStage.sent ? <Check /> : undefined}
                                </StepIndicator>
                            }
                            sx={{
                                '&::after': {
                                    ...(formStage >= FormStage.sent && {
                                        bgcolor: 'primary.solidBg',
                                    }),
                                },
                            }}
                        >
                            <Typography>Send</Typography>
                        </Step>

                        {form?.recipients?.signers.map(signer => (
                            <Step
                                key={signer.recipientId}
                                indicator={
                                    <StepIndicator
                                        variant={signer.status == 'completed' ? 'solid' : 'soft'}
                                        color={signer.status == 'completed' ? 'primary' : 'neutral'}
                                    >
                                        {signer.status == 'completed' ? (
                                            <Check />
                                        ) : signer.status == 'delivered' ? (
                                            <MoveToInbox />
                                        ) : undefined}
                                    </StepIndicator>
                                }
                                sx={{
                                    '&::after': {
                                        ...(signer.status == 'completed' && {
                                            bgcolor: 'primary.solidBg',
                                        }),
                                    },
                                }}
                            >
                                <Stack>
                                    <Typography>{signer.roleName} Signature</Typography>
                                </Stack>
                            </Step>
                        )) ??
                            roleNames.map(roleName => (
                                <Step
                                    key={roleName}
                                    indicator={
                                        <StepIndicator
                                            variant="soft"
                                            color="neutral"
                                        ></StepIndicator>
                                    }
                                >
                                    <Stack>
                                        <Typography>{roleName} Signature</Typography>
                                    </Stack>
                                </Step>
                            ))}
                    </Stepper>
                    {/*formStage == FormStage.sent &&
                        <Box>
                            {form?.signatureRecipients?.map(recipient =>
                                <Box key={recipient.name} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography>
                                        {recipient.name}: {recipient.status}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    */}
                </>
            )}
            <PdfModal
                title={title}
                pdf={previewPdf}
                onClose={() => setPreviewPdf(undefined)}
                fileName={`${formatAddress(listing.listingAddress)}_FundDisbursement.pdf`}
                hideDownloadButton
            />
            <IframeModal
                title="Fund Disbursement Form"
                url={editUrl}
                onClose={() => setEditUrl(undefined)}
            />
        </>
    )
}

export default DocusignFormStepper
